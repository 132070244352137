.color-filter {
  width: 4rem;
  height: 4rem;
  border: 1px solid transparent;
  margin-right: 1.7rem;
  border-radius: 0.5rem;
  transition: all 0.4s ease-in-out;
  &.inactive {
    padding: 0;
  }
  &.white {
    border: 1px solid #858b8b;
  }
  &.active {
    border: 1px solid #858b8b;
    padding: 0.5rem;
    > div {
      padding: 0rem;
      border: 1px solid #858b8b;
    }
  }
  > div {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;

    &.colorful {
      background: #fc354c; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to top,
        #fc354c,
        #0abfbc
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to top,
        #fc354c,
        #0abfbc
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    &.wood {
      background: url("/images/wood-swatch.jpg"); /* fallback for old browsers */
    }
  }
}
