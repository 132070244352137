@import "./elements/elements";
.what-looking-block {
  .container {
    max-width: 100% !important;
  }
  .title-decription-block {
    max-width: $screen__ml;
    margin: 12rem auto 0;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 3rem;
    .col {
      flex-wrap: nowrap !important;
    }
    h2 {
      color: $thirdy-color;
    }
    p {
      font-size: $f21;
      color: $secondary-color;
    }
  }
}

@media screen and (max-width: $screen__ml) {
  .what-looking-block {
    .title-decription-block {
      margin-top: 6rem;
      display: inline-block;
      font-size: $f21;
      height: 100%;
      width: 100%;
      padding: 0;
      p {
        font-size: $f14;
        line-height: 2.1rem;
        font-family: "GothamMedium";
      }
    }
  }
}
