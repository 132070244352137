.lifestyle-content {
  .lifestyle-item {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      height: 50rem;
      object-fit: cover;
    }
    p {
      margin-top: 0.5rem;
      font-size: $f12;
      line-height: 1.6rem;
      color: $secondary-color;
      &.photo-by {
        text-align: right;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .lifestyle-content {
    .lifestyle-item {
      img {
        height: 23rem;
      }
      p {
        &.photo-by {
          text-align: left;
        }
      }
    }
  }
}
