.custom-gallery {
  background-color: $color-gray-block;
  margin-top: 9rem;
  padding-bottom: 7rem;
  .custom-gallery-inner {
    @extend %block-width-1450;

    .title-decription-block {
      text-align: center;
      > div {
        width: 100%;
        padding: 7rem 0;
        h2 {
          color: $thirdy-color;
        }
        p {
          color: $secondary-color;
          text-transform: none;
          font-size: $f21;
        }
      }
    }
    .custom-gallery-images {
      justify-content: space-between;
      .slick-list {
        overflow: visible;
        width: 100%;
        .slick-track {
          overflow: visible;
          display: flex;
          justify-content: space-between;
        }
        .slick-slide {
          padding: 0 1rem;
        }
      }
      .gallery-block {
        margin-bottom: 3rem;
        .modal.show {
          overflow: visible;
        }
        a {
          height: 100%;
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}
/*Media Queries*/

@media (max-width: $screen__s) {
  .custom-gallery {
    .custom-gallery-inner {
      .custom-gallery-images {
        .slick-list {
          overflow: hidden;
        }
      }
      .slick-arrow {
        top: auto;
        bottom: -8rem;
        width: 6rem;
        height: 6rem;
        &:before {
          content: "";
          display: block;
          width: 6rem;
          height: 6rem;
        }
        &.slick-next {
          &:before {
            background: url("../../../../assets/icon/rightArrowCircle.svg")
              no-repeat;
          }
          right: 35%;
        }
        &.slick-prev {
          &:before {
            background: url("../../../../assets/icon/leftArrowCircle.svg")
              no-repeat;
          }
          left: 35%;
        }
      }
    }
  }
}
@media (max-width: $screen__xs) {
  .custom-gallery {
    .custom-gallery-inner {
      .title-decription-block {
        h2 {
          font-size: $f34;
          line-height: 3.6rem;
        }
      }
      .slick-arrow {
        &.slick-next {
          right: 25%;
        }
        &.slick-prev {
          left: 25%;
        }
      }
    }
  }
}
