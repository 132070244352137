.shipping-methods {
  padding-top: 1rem;
  padding-bottom: 3rem;
  > .row {
    h5 {
      &:hover {
        cursor: pointer;
      }
    }
    &.active {
      h5 {
        @extend %gotham-medium;
        &:not(.method-price) {
          color: #ff6d2d;
          .silver {
            color: #858b8b;
          }
        }
      }
    }
  }
  .method-price {
    h5 {
      color: $thirdy-color;
      text-align: right;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .check-button {
    &.stairs-label {
      padding: 0 0 0 2rem;
      .form-check-label {
        padding-right: 0;
        .stairs-label {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .check-button.form-check {
    &.shipping-method-check {
      label.form-check-label {
        justify-content: space-between;
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .shipping-method-container {
    margin-bottom: 7rem;
    padding: 0;
  }
}
