@import "./sidebar/sidebar";
.plp-landing-title {
  padding-bottom: 3.5rem;
  font-size: $f34;
  line-height: 3.6rem;
}
.plp-landing-content {
  a {
    padding: 0;
    text-decoration: none;
    margin-bottom: 4.5rem;
    h4 {
      font-size: 2rem;
      line-height: 3.2rem;
      color: $primary-color;
      @extend %gotham-medium;
      margin-bottom: 1.5rem;
    }
    img {
      width: 100%;
      min-height: 33rem;
      max-height: 33rem;
      object-fit: cover;
    }
  }
}
/*Media Queries*/
@media (max-width: $screen__s) {
  .plp-landing-title {
    padding-bottom: 2rem;
  }
  .plp-landing-content {
    a {
      margin-bottom: 2.5rem;
    }
  }
}
