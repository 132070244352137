.compound-slider {
  position: relative;
  min-height: 7rem;
  max-width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  .rail,
  .tracks .track {
    position: absolute;
    width: 100%;
    height: 1rem;
    margin-top: 3.5rem;
    border-radius: 0.5rem;
    background-color: $white;
    border: 0.1rem solid $color-gray-light;
  }

  .handles {
    .handle {
      position: absolute;
      margin-left: -0.5rem;
      margin-top: 3rem;
      z-index: 2;
      width: 0.5rem;
      height: 2rem;
      border: 0;
      text-align: center;
      cursor: pointer;
      border-radius: 0.7rem;
      background-color: $secondary-color;
      color: $secondary-color;
      &:first-of-type {
        margin-left: 0;
      }
      .text {
        display: none;
      }
    }
  }
  .tracks {
    .track {
      z-index: 1;
      background-color: $secondary-color;
    }
  }
}
