.accordion-item {
  .accordion-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    .accordion-title {
      background-color: $color-gray-block;
      color: #444;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      transition: background-color 0.6s ease;
      h3 {
        @extend %gotham-book;
        padding: 3.2rem 2rem;
        text-align: left;
      }
    }

    .accordion-title:hover {
      &.active {
        background-color: #ccc;
      }
    }

    .rotate {
      transform: rotate(90deg);
    }
  }

  .accordion-icon {
    margin-left: auto;
    transition: transform 0.6s ease;
    margin-right: 3rem;
  }
  .accordion-content {
    background-color: $white;
    overflow: hidden;
    transition: max-height 0.4s ease;
    &.closed {
      max-height: 0;
    }
    &.open {
      max-height: 100rem;
    }
    .accordion-text {
      font-weight: 400;
      font-size: 1.4rem;
      padding: 2rem;
      h5 {
        font-size: $f21;
        color: $thirdy-color;
        line-height: 3.6rem;
        a {
          padding-left: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .accordion-item {
    margin: 0 -3rem;
  }
}
