#trade-register-step-two {
  margin-bottom: 7rem;
}
/*Media Queries*/
@media (max-width: 767px) {
  #trade-register-step-two {
    .step-two-buttons {
      > div {
        > div {
          text-align: left !important;
        }
      }
    }
  }
}
@media (max-width: $screen__xs) {
  #trade-register-step-two {
    .step-two-buttons {
      > div {
        > div {
          button {
            text-align: left;
            max-width: 100%;
          }
        }
      }
    }
  }
}
