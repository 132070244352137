@import "./toolbar/toolbar";
@import "./listing/listing";
@import "./share/share";
@import "./shareFavoritesBtn/shareFavoritesBtn";

#favorites {
  h3 {
    font-size: $f34;
    line-height: 3.6rem;
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  #favorites {
    .top-toolbar {
      .pagination {
        display: none;
      }
    }
  }
  .create-project-block {
    .col {
      &:last-child {
        display: none;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .create-project-block {
    .col {
      flex-basis: 100%;
    }
  }
}
