.forgot-password {
  h3 {
    padding-bottom: 4rem;
  }
  h4 {
    text-transform: none;
    padding-bottom: 1rem;
    color: $secondary-color;
    @extend %gotham-medium;
  }
  .input-btn-content {
    margin-bottom: 5rem !important;
    border-bottom: 0.2rem solid $color-gray-bottom-border;
    .input-field-content {
      flex: 0 1 30%;
      input {
        padding: 1.75rem !important;
        &.validation-error {
            border-color: red !important;
        }
      }
    }
    .clickable-button{
      flex: 0 1 70%;
      button {
        margin-top: 1.5rem;
      }
    }
    button, input {
      max-width: 33rem;
      margin-bottom: 3rem;
    }
  }
  &.second {
    .password-text {
      max-width: 90%;
      color: $secondary-color;
      padding-bottom: 2rem;
    }
    .input-btn-content {
      flex-direction: column;
      .input-field-content,
      .clickable-button {
        flex: 0 1 100%;
      }
    }
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__s) {
  .forgot-password {
    h3 {
      font-size: $f34;
      line-height: 3.6rem;
    }
    h4 {
      font-size: $f16;
    }
  }
}
@media screen and (max-width: 660px) {
  .forgot-password {
    .input-btn-content {
      .input-field-content {
        flex: 0 1 100%;
      }
      .clickable-button{
        flex: 0 1 100%;
        button {
          margin-top: 0;
        }
      }
    }
  }
}