#search-container {
  .search-icon {
    display: none;
    right: 0;
    position: absolute;
    @media screen and (max-width: 999px) {
      display: block;
      top: 0.8rem;
      right: 3.4rem;
    }
  }
  .md-form {
    width: 100%;
    padding: 0 3.4rem;
    margin: 5rem 0 3rem;
    @media screen and (max-width: $screen__m) {
      padding: 0;
      margin: 1.5rem 0;
    }
    #search-input {
      width: 100%;
      color: $secondary-color;
      border: none !important;
      border-bottom: none !important;
      box-shadow: none !important;
      padding-bottom: 0rem !important;
      @media screen and (max-width: $screen__m) {
        padding-top: 0 !important;
        font-size: 1.4rem;
        margin-bottom: 0;
      }
    }

    label {
      transform: none !important;
      top: 1.4rem;
      left: 4rem;
      font-size: 2.6rem;
      color: $color-gray-light;
      @media screen and(max-width: $screen__m) {
        font-size: 1.4rem;
        top: 0.4rem;
        left: 0;
      }
    }
    input[type="text"] {
      &:not(.browser-default) {
        &:focus {
          &:not([readonly]) {
            + label {
              &:focus {
                color: $color-gray-light;
              }
              &.active {
                transform: none !important;
                top: 2rem;
                left: 4rem;
                font-size: 2.6rem;
                color: $color-gray-light;
                border: none;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
