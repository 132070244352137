.assembly_video {
  padding-left: 0;
  padding-right: 0;
  .play-overlay {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    font-size: 10rem;
    cursor: pointer;
    &.hidden {
      display: none;
    }
  }
}
.modal.show {
  .pdp-video-modal.modal-dialog {
    min-width: 110rem;
    .modal-content {
      .modal-body {
        padding: 1rem;
        width: 100% !important;
        max-width: 100%;
        .assembly_video {
          margin: 0 auto;
        }
      }
    }
  }
}
