#what-looking {
  max-width: $screen__ml;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .links-wrapper {
    flex: 0 0 12%;
    -webkit-box-flex: 0 0 12%;
    -moz-box-flex: 0 0 12%;
    -webkit-flex: 0 0 12%;
    -ms-flex: 0 0 12%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    span {
      text-transform: none;
      cursor: pointer;
    }
    .list-item {
      font-size: $f26;
      @extend %gotham-bold;
      color: $color-orange-home-links;
      max-height: 6rem;
      margin: 1rem 0;
      line-height: 6rem;
      -webkit-transition: font-size 0.3s;
      -moz-transition: font-size 0.3s;
      -o-transition: font-size 0.3s;
      transition: font-size 0.3s;
      &-active {
        font-size: $f34;
        @extend %gotham-bold;
        color: $primary-color;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -30%;
          top: 20%;
          background-color: $primary-color;
          width: 5px;
          height: 50%;
          border-radius: 20%;
          vertical-align: middle;
          display: flex;
          align-items: center;
        }
      }
      &:hover {
        font-size: $f34;
        @extend %gotham-bold;
        color: $primary-color;
      }
    }
  }
  .content-wrapper {
    flex: 0 0 88%;
    .whats-content {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      .img {
        flex: 0 0 67%;
        padding-right: 3rem;
      }
      .content {
        flex: 0 0 32%;
        p {
          @extend %gotham-book;
          font-size: $f21;
          text-transform: none;
          line-height: 2;
          color: $thirdy-color;
        }
        a {
          padding: 0;
          button {
            max-width: 33rem;
          }
        }
      }
    }
    &.item-enter {
      opacity: 0;
    }
    &.item-enter-active {
      opacity: 1;
      transition: opacity 250ms ease-in;
    }
    &.item-exit {
      opacity: 1;
    }
    &.item-exit-active {
      opacity: 0;
      transition: opacity 0 ease-in;
    }
  }
}
@media screen and (min-width: $screen__m) {
  #what-looking {
    .content-wrapper {
      min-height: 70rem;
      justify-content: center;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: $screen__m) {
  #what-looking {
    flex-direction: column-reverse;
    .links-wrapper {
      padding: 3rem 1rem !important;
      .list-item {
        display: flex;
        text-decoration: none;
        font-size: $f20;
        margin: 0;
        text-transform: none;
        position: relative;
        &:after {
          content: "";
          background-image: url("../../../../assets/icon/rightArrowGray.svg");
          display: inline-flex;
          position: absolute;
          width: 4rem;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 2.5rem;
          background-repeat: no-repeat;
          right: 0;
        }
        &:hover {
          font-size: $f20;
        }
        // &.list-item-active {
        //   max-height: 6rem;
        //   margin: 1rem 0;
        //   font-size: $f20;
        //   line-height: 6rem;
        //   &:after {
        //     background-image: url("../../../../assets/icon/rightArrowOrange.svg");
        //   }
        //   &:before {
        //     left: -10%;
        //   }
        // }
      }
    }
    .content-wrapper {
      .whats-content {
        .img {
          flex: 0 0 60%;
        }
        .content {
          flex: 0 0 40%;
          p {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  #what-looking {
    .links-wrapper {
      padding-top: 3rem;
      padding-bottom: 3rem;
      span.list-item,
      span.list-item-active {
        font-size: $f20;
      }
    }
    .content-wrapper {
      .whats-content {
        padding-bottom: 0;
        .img {
          flex: 1 1 100%;
          padding-right: 0;
        }
        .content {
          display: none;
        }
      }
    }
  }
}
// @media screen and (max-width: $screen__xs) {
//   #what-looking {
//     .links-wrapper {
//       padding-left: 20%;
//       padding-right: 20%;
//       padding-top: 6rem;
//       padding-bottom: 6rem;
//       span {
//         display: flex;
//         align-items: center;
//         position: relative;
//         &:after {
//           content: "";
//           background-image: url("../../../../assets/icon/rightArrowGray.svg");
//           display: inline-flex;
//           position: absolute;
//           width: 3rem;
//           height: 2.5rem;
//           background-repeat: no-repeat;
//           right: 0;
//         }
//       }
//     }
//   }
// }
