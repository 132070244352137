@import "./accordion/accordion";
@import "./tabs/tabs";
.faq-page {
  margin-bottom: 15rem;
  .faq-top {
    @extend %block-width-1450;
  }
  .faq-content {
    @extend %block-width-1450;
  }
}
