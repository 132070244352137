.custom-cards {
  padding: 10rem 0 9rem 0;
  .custom-card-inner {
    @extend %block-width-1450;
    .slick-list {
      width: 100%;
      .slick-track {
        overflow: visible;
        display: flex;
        justify-content: space-between;
      }
      .slick-slide {
        width: 33.33%;
        padding: 0 1rem;
      }
    }
    .slick-active {
      .slider-custom-dot {
        background: $color-gray-light;
      }
    }
    .slider-custom-dot {
      border: 0.2rem solid $color-gray-light;
    }
    .card-image {
      padding: 0;
      text-align: center;
      .card-image-content {
        position: relative;
        img {
          border: 0.4rem dashed $primary-color;
          border-radius: 50%;
        }
        &:after {
          display: block;
          width: 7.5rem;
          height: 7.5rem;
          left: 0;
          right: 0;
          font-size: $f34;
          bottom: -3.75rem;
          @extend %gotham-bold;
          margin: 0 auto;
          position: absolute;
          border-radius: 50%;
          border: 0.3rem solid $color-gray-light;
          color: $color-gray-light;
          justify-content: center;
          display: flex;
          background-color: $white;
          align-items: center;
        }
        &.card-1 {
          &:after {
            content: "1";
          }
        }
        &.card-2 {
          &:after {
            content: "2";
          }
        }
        &.card-3 {
          &:after {
            content: "3";
          }
        }
        &.card-4 {
          &:after {
            content: "4";
          }
        }
      }

      h3 {
        line-height: 4.2rem;
        margin: 7rem 0 4rem 0;
        padding: 0;
      }
      p.expandable-text {
        line-height: 2rem;

        // height: 8rem;
        // overflow: hidden;
        // transition: height 0.4s linear;
        // &.expandable-text-true {
        //   height: 26rem;
        // }
      }
      p.click-to-show {
        text-decoration: underline;
        margin-top: 3rem;
        text-transform: none;
        color: $primary-color;
        cursor: pointer;
        @extend %bigger-a;
        display: block;
        padding: 0.5rem 1rem;
      }
    }
  }
}
@media (max-width: $screen__s) {
  .custom-cards {
    padding: 6rem 0;
    .custom-card-inner {
      .card-image {
        h3 {
          font-size: 3.4rem;
          line-height: 4.2rem;
        }
      }
    }
  }
}
