.assembly-tab-title {
  max-width: 145rem;
  margin: 0 auto;
  text-align: center;
  h1 {
    color: $primary-color;
    padding: 0;
    margin-top: 6rem;
  }
  .assembly-title-content {
    max-width: 70rem;
    margin: 0 auto;
    margin-bottom: 6rem;
    h2 {
      color: $color-gray-light;
      font-size: $f42;
      @extend %gotham-book;
      margin: 3.4rem 0;
      padding: 0;
    }
  }
}
