.account-addressform-container {
  .validation-error {
    border-color: red !important;
  }
  .validation-error {
    .react-select__control {
      border-color: red !important;
    }
  }
  .switch-container  {
    label {
      @extend %gotham-book;
      color: $thirdy-color;
    }
  }
  .req-block {
    margin: 4rem 0 ;
    text-align: right;
    .required-text-block{ 
      display: inline-flex;
    }
  }
    .select2  {
      &.main-select.dark {
        .react-select__control {
          .react-select__single-value{
            @extend %gotham-medium;
          }
        }
      }
    }
    input.default-input-field  {
      height: auto;
    }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .account-addressform-container {
    .address-contact-info{ 
      margin:0 0 3rem 0; 
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .account-addressform-container {
    .req-block {
      text-align: left !important;
    }
  }
}