.summary-container {
  top: 8rem;
  border-radius: 0.5rem;
  // position: sticky;
  background: $color-gray-block;
  padding: 1.5rem;
  .md-form {
    width: 100%;
  }
  .select2 {
    width: 100%;
    .react-select__control {
      background-color: transparent;
    }
  }
  .summary {
    span.summary-title {
      @extend %gotham-book;
      font-size: 3rem;
      color: $secondary-color;
    }
  }
  .summary-items {
    .summary-subtotal {
      @extend %gotham-book;
      font-size: $f16;
      color: $secondary-color;
    }
  }
  .summary-total {
    @extend %gotham-medium;
    font-size: $f16;
    color: $secondary-color;
  }
  .summary-note {
    .note-text {
      color: $secondary-color;
      font-size: $f14;
    }
  }
  .discount-code {
    .discount-title {
      @extend %gotham-book;
      font-size: $f16;
      color: $secondary-color;
      padding-left: 2rem;
    }
    .discount-caret {
      i {
        font-size: 1.9rem;
      }
    }
  }
}
