@import "./bannerStudio/bannerStudio";
@import "./juicer/juicer";
@import "./shopLook/shopLook";
@import "./shopLookSlider/shopLookSlider";
@import "./slider/slider";
@import "./uspBlocks/uspBlocks";
@import "./whatLookingBlock/whatLookingBlock";
@import "./mainModal/mainModal";

.container-fluid {
  &.home-container {
    overflow: hidden;
    position: relative;
  }
}
.shop-look-background-div {
  margin: 0 -9999rem;
  padding: 0.25rem 9999rem;
  background: #f1f4f6;
  height: 100%;
  max-height: 117rem;
  overflow: hidden;
  position: absolute;
}
