#wishlist-toolbar-listing {
  margin-top: 3.5rem;
  margin-bottom: 1rem;
  padding-bottom: 3rem;
  overflow-x: scroll;
  // border-bottom: 0.2rem solid $color-gray-bottom-border;
  &::-webkit-scrollbar {
    height: 1rem !important;
    display: none;
  }
  &:hover {
    margin-bottom: 0;
    &::-webkit-scrollbar {
      display: initial;
    }
  }
  > .row {
    overflow-x: visible;
    .item {
      border: 0.1rem solid transparent;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
      flex: 0 0 22rem;
      padding: 1.5rem 0;
      font-size: 2rem;
      box-shadow: inset 0px 0px 0px 1px $thirdy-color;
      transition: all 0.2s ease-in-out;
      font-weight: 700;
      color: $thirdy-color;
      margin-right: 3rem;
      &::after {
        content: "";
        border-radius: 0.5rem;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 -5px 0px 1px $primary-color;
        opacity: 0;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &:first-child {
        flex: 0 0 15rem;
      }
      &:hover {
        color: $primary-color;
        border-top: 0.1rem solid $primary-color;
        box-shadow: inset 0 -5px 0px 1px $primary-color;
      }

      &:hover::after {
        opacity: 1;
      }

      &.active {
        border-top: 0.1rem solid $primary-color;
        box-shadow: inset 0 -5px 0px 1px $primary-color;
        color: $primary-color;
      }
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}
