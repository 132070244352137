.breadcrumbs {
  padding: 1.5rem 0;
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      a {
        @extend %breadcrumbs;
        text-decoration: none;
        border-right: 0.1rem solid $secondary-color;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        span {
          border-bottom: 0.1rem solid $secondary-color;
        }
        &:last-child {
          border-right: none;
          span {
            border-bottom: none;
          }
        }
        &:hover {
          color: $primary-color;
          span {
            border-bottom: 0.1rem solid $primary-color;
          }
          &:last-child {
            span {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
