#trade-register-B2B {
  .span-small {
    text-align: left;
    letter-spacing: 0;
    color: $thirdy-color;
    opacity: 1;
    @extend %gotham-book;
  }
  .download-form-detail {
    padding-top: 1rem;
    @media (max-width: $screen__xs) {
      padding-top: 2rem;
      text-align: center;
    }
    span {
      width: 50%;
    }
    .download-form {
      text-align: right;
    }
  }
  .browse-btn {
    text-align: right;
  }
  .req-block {
    padding-bottom: 2rem;
    margin-bottom: 4rem;
    border-bottom: 0.2rem solid $color-gray-bottom-border;
    text-align: right;
    .required-text-block {
      display: inline-flex;
    }
  }
  .b2b-next {
    > div {
      padding: 0 0 10rem 0;
      button {
        max-width: 33rem;
      }
    }
    @media (max-width: $screen__xs) {
      > div {
        button {
          max-width: 100%;
        }
      }
    }
  }
  .span-big {
    text-align: left;
    font-size: 2rem;
    letter-spacing: 0;
    color: $secondary-color;
    opacity: 1;
    @extend %gotham-medium;
  }

  .select-div-container {
    padding-bottom: 4.2rem;
    margin-bottom: 0;
  }
  .how-did-u {
    padding-bottom: 2.5rem;
  }
  .multiselect-how-did-u {
    padding-bottom: 1.7rem;
  }
  .checkbox-container {
    margin-bottom: 0;
  }
  .radio-yes-no {
    margin-right: 8rem;
  }

  .specify-store-container {
    margin-left: 6rem !important;
    span {
      font-size: $f14;
    }
    .quantity-field {
      margin-top: 2rem;
      margin-bottom: 1rem;
      input {
        padding: 0 !important;
      }
    }
    .specify-stores {
      color: $thirdy-color;
      @extend %gotham-book;
    }
  }
  .check-button {
    .form-check-label {
      padding-left: 1rem;
    }
  }
  .radio-container {
    label {
      font-size: $f20;
    }
  }
  input {
    &.upload-field {
      padding: 1.5rem 2.2rem !important;
    }
  }
  .specify-input-container {
    margin-top: 0rem !important;
    .specify-input-field {
      border-bottom: 0.2rem solid $color-gray-bottom-border;
    }
    label {
      font-size: $f14;
      @extend %gotham-book;
      top: -1.2rem;
      color: $thirdy-color;
      &.active {
        transform: translate(-1.5rem, -1.5rem);
        color: transparent !important;
      }
    }
  }
  .number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .number-input input[type="number"]::-webkit-inner-spin-button,
  .number-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .number-input {
    margin-bottom: 3rem;
  }
  .number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
  }
  .number-input button:before,
  .number-input button:after {
    display: inline-block;
    position: absolute;
    content: "";
    height: 0.2rem;
    transform: translate(-50%, -50%);
  }
  .number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .number-input input[type="number"] {
    text-align: center;
  }
  .number-input {
    &.number-input {
      border: 0.1rem solid #ced4da;
      width: 10rem;
      border-radius: 0.25rem;
      &.button {
        width: 2.6rem;
        height: 0.7rem;
        &.minus {
          padding-left: 1rem;
        }
      }
    }
  }
  .number-input.number-input
    .number-input.number-input
    button
    .number-input.number-input
    button:before,
  .number-input.number-input button:after {
    width: 0.7rem;
    background-color: #495057;
  }
  .number-input.number-input input[type="number"] {
    max-width: 4rem;
    padding: 0.5rem;
    border: 0.1rem solid #ced4da;
    border-width: 0 0.1rem;
    font-size: 1rem;
    height: 2rem;
    color: #495057;
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  #trade-register-B2B {
    .step-one-radio-btns {
      margin-bottom: 3.5rem;
    }
  }
}
@include media-breakpoint-down(sm) {
  #trade-register-B2B {
    .specify-input-container {
      padding-left: 5.5rem;
      label {
        padding-left: 5.5rem;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #trade-register-B2B {
    overflow: visible;
    .specify-input-container {
      margin: 1rem 0 3rem 0 !important;
      label {
        &.active {
          white-space: nowrap;
          overflow: visible;
          font-size: $f14 !important;
          transform: translate(-1rem, -1.5rem) !important;
        }
      }
    }
    .req-block {
      text-align: left;
    }
    .step-one-bottom-radio-btns {
      margin: 2rem 0;
      flex-flow: row;
    }
    .b2b-next {
      > div {
        padding: 0 0 8rem 0;
      }
    }
  }
}
