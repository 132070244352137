.about-us-top {
  @extend %block-width-1450;
  h1 {
    color: $primary-color;
    padding-bottom: 4rem;
    max-width: 65%;
  }
  h2 {
    color: $color-gray-light;
    @extend %gotham-book;
  }
  .static-page-top-block {
    padding: 5rem 0 9rem 0;
    .block-with-left-img {
      justify-content: space-between;
      .block-part {
        width: calc(50% - 2rem);
        &.block-text {
          p {
            font-size: $f21;
            line-height: 3.6rem;
          }
          a {
            button {
              margin-top: 3rem;
              max-width: 33rem;
            }
          }
        }
      }
    }
  }
  .about-us-cards {
    margin-bottom: 7.5rem;
    .slick-list {
      width: 100%;
      .slick-track {
        overflow: visible;
        display: flex;
        justify-content: space-between;
      }
      .slick-slide {
        width: 33.33%;
        padding: 0 1.5rem;
      }
    }
    .slick-active {
      .slider-custom-dot {
        background: $color-gray-light;
      }
    }
    .slider-custom-dot {
      border: 0.2rem solid $color-gray-light;
    }
    .card-image {
      text-align: center;
      p.expandable-text {
        height: 7rem;
        margin-bottom: 2rem;
        overflow: hidden;
        transition: height 0.4s linear;
        &.expandable-text-true {
          height: 22rem;
        }
      }
      h3 {
        padding: 3rem 0;
        color: #252525;
      }

      p.click-to-show {
        font-size: $f20;
        text-decoration: underline;
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .about-us-top {
    padding-bottom: 5rem;
    h2 {
      font-size: 3.4rem;
      line-height: 3.8rem;
      padding-bottom: 3rem;
    }
    .static-page-top-block {
      padding-top: 0;
      .block-with-left-img {
        .block-part {
          width: 100%;
          &.block-img {
            padding-bottom: 3rem;
            h5 {
              font-size: $f34;
              line-height: 3.6rem;
            }
          }
        }
      }
    }
    .about-us-cards {
      .card-image {
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .about-us-top {
    h1 {
      max-width: 80%;
    }
    h3 {
      font-size: 3.4rem;
      line-height: 3.8rem;
    }
    .static-page-top-block {
      .block-with-left-img {
        .block-part {
          &.block-text {
            p {
              font-size: $f16;
            }
          }
        }
      }
    }
    .about-us-cards {
      .card-image {
        p.click-to-show {
          font-size: 1.6rem;
          line-height: 3.6rem;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .about-us-top {
    h1 {
      max-width: 100%;
    }
    h2 {
      font-size: 2.8rem;
      line-height: 4.6rem;
    }
    .about-us-cards {
      .slick-list {
        .slick-slide {
          padding: 0;
        }
      }
    }
  }
}
