@import "./contactInfo/contactInfo";
@import "./uspContact/uspContact";
.contact-us-container {
  margin-bottom: 10rem;
  .contact-page {
    @extend %block-width-1450;
    margin: 0 auto;
    .contact-upper-text {
      h3 {
        font-size: $f34;
        line-height: 3.6rem;
      }
      p {
        padding: 2.5rem 4rem;
        background-color: $color-gray-block;
        font-size: $f21;
        @extend %gotham-book;
        color: $thirdy-color;
        text-transform: none;
        line-height: 1.5;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .contact-us-container {
    .contact-page {
      .contact-upper-text {
        p {
          padding: 2.5rem 3rem;
          font-size: $f16;
        }
      }
    }
  }
}
