.checkout-review-info {
  padding: 2.5rem 0;
  border-top: 1px solid;
  display: flex;
  justify-content: space-between;
  .imageloader {
    max-width: 10rem;
    max-height: 10rem;
    flex: 0 0 30%;
  }
  .checkout-review-info-inner {
    flex: 0 0 55%;
    font-size: $f16;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item-qty {
      color:$thirdy-color;
      padding-left: 2rem;
    }
  }
  .checkout-review-price {
    flex: 0 0 15%;
    color:$thirdy-color;
    font-size: $f16;
  }
}
