.find-a-retailer {
  max-width: 145rem;
  margin: 0 auto;
  h3 {
    font-size: $f34;
    line-height: 3.6rem;
  }
  .find-a-retailer-wrapper {
    flex-direction: column;
    position: relative;
    margin-bottom: 10rem;
    .map-inputs {
      position: absolute;
      left: 3%;
      top: 4%;
      max-width: 40rem;
      border: 0.2rem solid $primary-color;
      background: white;
      padding: 3rem;
      z-index: 2;
      color: $secondary-color;
      .md-form {
        svg {
          position: absolute;
          right: 0;
          top: 50%;
          bottom: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid $color-gray-dark;
          border-radius: 50%;
        }
        input {
          color: $thirdy-color;
          @extend %gotham-medium;
          padding-right: 5rem !important;
        }
      }
      button {
        margin-top: 1.5rem;
        width: 100%;
        min-width: 100%;
      }
      .locations-wrapper {
        max-height: 8rem;
        overflow: auto;
      }
    }
    .search-container {
      padding: 2rem 0;
      span.search-res {
        font-size: $f16;
        @extend %gotham-medium;
      }
      h5 {
        color: $color-orange-dark-nav;
        @extend %gotham-medium;
        margin-bottom: 1rem;
      }
      p {
        color: $secondary-color;
      }
      ol {
        list-style: none;
        counter-reset: item;
        max-height: 30rem;
        padding-right: 2rem;
        padding-left: 0;
        &.clicked {
          overflow: auto;
          height: 100%;
          max-height: 25rem;
          padding-right: 2rem;
        }
        &.not-clicked {
          max-height: 25rem;
          overflow-y: auto;
          box-sizing: content-box;
          padding-right: 2rem;
          &::-webkit-scrollbar {
            width: 0px; /* Remove scrollbar space */
            background: transparent;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        li {
          counter-increment: item;
          margin-bottom: 5px;
          border-bottom: 0.2rem solid $color-gray-filter;
          padding-bottom: 2rem;
          &:last-child {
            border-bottom: none;
          }
        }
      }

      .store-content {
        margin: 2rem 0;
        h5 {
          color: $color-orange-dark-nav;
          padding-left: 5rem;
          position: relative;
          &:before {
            content: counter(item);
            background: white;
            position: absolute;
            border-radius: 50%;
            color: $primary-color;
            width: 3rem;
            height: 3rem;
            text-align: center;
            display: inline-block;
            border: 0.2rem solid $primary-color;
            left: 0;
            line-height: 2;
            top: -7%;
          }
        }
      }
      .website-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }
      .view-all {
        color: $color-orange-dark-nav;
        text-decoration: underline;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .find-a-retailer {
    .find-top {
      .col {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
    .find-a-retailer-wrapper {
      .map {
        order: 1;
        padding:0;
      }
      .map-inputs {
        left: 0;
        position: relative;
        order: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
