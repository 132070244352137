@import "../../../../style/app.scss";

.subscribe {
  padding: 0 !important;
  .subscribe-text {
    text-align: left;
    background-color: $color-gray-footer-block;
    > div {
      padding: 4rem 3rem;
      box-sizing: border-box;
      h5 {
        color: $thirdy-color;
        margin-bottom: 3rem;
        @extend %gotham-medium;
      }
      p {
        margin-bottom: 3rem;
        color: $color-gray-dark;
      }
      .subscribe-btn {
        max-width: 22rem;
      }
    }
  }
  .subscribe-icons {
    ul {
      text-align: right;
      margin: 3rem 0 2rem 0;
      li {
        a {
          color: $color-gray-light;
          padding: 0.5rem 1rem;
          display: block;
          i {
            font-size: 1.7rem;
          }
        }
        &:last-child {
          a {
            padding-right: 0 !important;
          }
        }
        a {
          color: $color-gray-light;
        }
      }
    }
  }
}
// MODAL
.modal.show {
  .modal-dialog {
    &.footer-newsletter {
      min-width: 93rem;
      position: relative;
      display: flex;
      margin: 0 auto;
      .modal-content {
        .modal-header {
          padding: 0;
        }
        .modal-body {
          max-width: 52rem;
          span {
            font-size: 2.6rem;
            margin-top: 3rem;
          }
          p {
            text-align: center;
          }
          p,
          a {
            color: $primary-color;
            font-size: 2rem;
            margin-bottom: 1rem;
          }
          span.slider {
            margin: 0;
          }
          .custom-modal-btn {
            .switch-container {
              label {
                padding: 0;
                color: $secondary-color !important;
                @extend %gotham-book;
                text-transform: none;
              }
            }
          }
          .req-block {
            .required-text-block {
              span {
                color: $secondary-color !important;
                &.required-sign {
                  font-size: 3rem !important;
                }
              }
            }
          }
          .subscribe-btn-modal {
            a {
              text-align: right;
              button {
                max-width: 33rem;
              }
            }
          }
        }
        .join-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 5rem 0;
          align-items: center;
          .md-form {
            width: 100%;
            flex: 0 1 66%;
            margin: 0;
          }
          button {
            margin: 0;
            flex: 0 1 27%;
          }
        }
      }
    }
  }
}
.sign-in-modal-btn {
  background: transparent;
  border: none;
  color: $primary-color;
  text-decoration: underline;
}
// SECOND MODAL INSIDE MODAL NEWSLETTER
.modal.show {
  .modal-dialog {
    &.second-modal {
      .modal-content {
        .modal-body {
          max-width: 69rem;
          p {
            text-align: left;
            font-size: $f14;
          }
          span {
            margin-top: 0;
            display: inline-block;
          }
          .subscribe-btn-modal {
            text-align: right;
            .btn-modal-content {
              max-width: 22rem;
            }
          }
          .validation-error {
            .react-select__control {
              border-color: red !important;
            }
          }
          .input-fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: row;
            .input-column {
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 3%);
            }
            .md-form {
              width: 100%;
              margin: 2rem 0;
            }
            .modal-select {
              width: 100%;
              margin: 2rem 0;
              .select2 {
                width: 100%;
                margin-bottom: 2rem !important;
              }
              .react-select__control {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen__m) {
  .subscribe {
    .subscribe-text {
      > div {
        .container {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .subscribe {
    .subscribe-icons {
      ul {
        text-align: center;
      }
    }
  }
}
@media (max-width: $screen__s) {
  .modal.show {
    .modal-dialog {
      h2 {
        font-size: $f34;
        line-height: 3.6rem;
      }
      &.second-modal {
        .modal-content {
          .modal-body {
            .input-fields {
              flex-direction: column;
              .input-column {
                width: 100%;
                &.one {
                  .default-input-field-container {
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .subscribe {
    .subscribe-text {
      text-align: center;
    }
  }
}

@include media-breakpoint-down(sm) {
  .subscribe {
    .subscribe-text {
      text-align: left;
    }
  }
}
@media (max-width: $screen__xs) {
  .modal.show {
    .modal-dialog {
      &.footer-newsletter {
        .modal-content {
          .modal-body {
            padding: 4rem 1rem;
            span {
              font-size: $f14;
            }
          }
          .join-wrapper {
            margin: 2rem 0;
            flex-direction: column;
            .md-form {
              flex: 1 1 100%;
              margin-bottom: 2rem;
              text-align: center;
              input {
                padding: 0;
              }
              label {
                padding: 0 1rem 0 1rem;
                margin: 0;
              }
            }
            button {
              flex: 1 1 100%;
              min-width: 100%;
            }
          }
        }
      }
      &.second-modal {
        .modal-content {
          .custom-modal-btn {
            flex-direction: column !important;
            align-items: flex-start !important;
            .req-block {
              margin-top: 2rem;
            }
          }
          .modal-body {
            .subscribe-btn-modal {
              text-align: center;
              .btn-modal-content {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
