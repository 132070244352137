#wishlist-toolbar {
  margin: 4rem 0;
    font-size: $f20;
    color: $secondary-color;
    @extend %gotham-medium;
    .select-show-products {
      &.select2 .react-select__control {
        border-color: $color-gray-light;
        .react-select__single-value {
          color: $color-gray-light;
        }
      }
    }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  #wishlist-toolbar {
    flex-direction: column;
    .show {
      text-align: right;
    }
    .number-of-items {
      margin-bottom: 3rem;
      div {
        width: 100%;
        text-align: center;
      }
    }
    .pagination {
      justify-content: center; 
        li {
          &:last-child {
            margin-bottom: 3rem;
          }
        }
    }
    > div {
      padding: 0;
      
    }
  }
}