#wishlist-controls {
  height: 7rem;
  > .row {
    height: 100%;
    .wishlist-click-edit {
      color: $secondary;
      font-size: $f20;
      margin-bottom: 2rem;
      @extend %gotham-medium;
    }
    .name-container {
      position: relative;
      margin-right: 3rem;
      transition: all 0.5s ease-in-out;
    }

    .rename-input {
      width: 100%;
      position: absolute;
      z-index: 1;
      background-color: $white;
    }
    i {
      font-size: 4.5rem;
      &::before {
        cursor: pointer;
      }
    }
    svg {
      cursor: pointer;
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  #delete-project-modal {
    &.modal.show {
      .modal-dialog {
        min-width: 90rem;
        .modal-body {
          text-align: center;
          width: 60rem;
          max-width: 100%;
          .del-project-subtitle {
            font-size: 2.6rem;
            line-height: 3.2rem;
            margin-bottom: 5rem;
          }
          .del-project-buttons {
            justify-content: space-between;
            button {
              font-size: $f20;
              i {
                font-size: $f20;
              }
            }
          }
        }
      }
    }
  }
}
