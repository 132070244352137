#navigation-dropdown {
  transition: all 0.3s ease-in-out;
  position: absolute;
  background-color: $white;
  padding-top: 1rem;
  left: 0;
  height: 50rem;
  max-width: 0;
  border-radius: 0px 0px 0.5rem 0.5rem;
  z-index: 5;
  top: 7rem;
  overflow: hidden;
  &.hidden {
    transform: translateY(-50rem);
  }
  .row {
    margin: 0;
    .col {
      .item {
        width: 17rem;
        a {
          font-size: 1.3rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: $primary-color;
            text-shadow: 0 0 1px rgba($primary-color, 0.6);
          }
        }
      }
    }
  }
}
