/**
  * Global application styles, loads global.scss that has core dependencies
  */
/*Font Family*/
@font-face {
  font-family: "GothamBook";
  src: url(../assets/fonts/gotham/GothamBook.otf);
  font-display: swap;
}
@font-face {
  font-family: "GothamMedium";
  src: url(../assets/fonts/gotham/GothamMedium.otf);
  font-display: swap;
}
@font-face {
  font-family: "GothamBold";
  src: url(../assets/fonts/gotham/GothamBold.otf);
  font-display: swap;
}
/*Font family extensions*/
//Gotham
%gotham-medium {
  font-family: "GothamMedium";
}
%gotham-book {
  font-family: "GothamBook";
}
%gotham-bold {
  font-family: "GothamBold";
}
/*Color variables*/
$primary-color: #ff6d2d; // Buttons and Tittles color
$secondary-color: #858b8b; // Pragraph color
$thirdy-color: #3a3a3a; // Titles, paragraph and input fields
$white: $white-base;
$danger: #e21a1a;
$default-color: $primary-color;
$success: #d2e8c9;
$warning: #fff5af;
$danger_session: #ffc1b2;
// Orange Color Variables
$color-orange-dark: $primary-color; // Buttons and titles color
$color-orange-dark-nav: #ff5100; // Navigation menu links color, Radio button
$color-orange-modal-border: #ff521d; // Modal border color
$color-orange-footer: #f57811; // Background footer color, Logo in mogal color
$color-orange-brick: #ff9b2e; // Secondary orange color, text
$color-orange-home-links: #ff9015; // Tertiary orange color, link

// Gray Color Variables
$color-gray: $secondary-color;
$color-gray-light: #b3b9b9; // Paragraph color, Button color
$color-gray-dark: #646b6b; // Links above footer
$color-gray-filter: #ced4d2; // Filter title background color, Account active element in sidebar
$color-gray-bottom-border: #ebebeb; // Bottom line color
$color-gray-block: #f1f4f6; // Block with text, background color
$color-gray-footer-block: #e4edf3; // Footer subscribe background color
$color-gray-background: #f8f8f8; // Background on PDP Selections

// Black Color Variables
$color-black: $thirdy-color;
$color-black-shadow: #00000029; // Shadow color for check button

// Yellow Color Variables
$sand: #ede6d3;

/*Font variables*/
$f64: 6.4rem;
$f48: 4.8rem;
$f42: 4.2rem;
$f34: 3.4rem; // Titles for CMS pages
$f30: 3rem;
$f26: 2.6rem;
$f21: 2.1rem;
$f20: 2rem;
$f16: 1.6rem;
$f14: 1.4rem;
$f12: 1.2rem;
$f11: 1.1rem; // Breadcrumbs

/*Media querie variables*/
$screen__xxs: 375px;
$screen__xs: 480px;
$screen__s: 768px;
$screen__m: 1024px;
$screen__ml: 1200px;
$screen__l: 1440px;
$screen__xl: 1920px;
$screen__2k: 2560px;

/*CMS Layout*/
$page-max-width: 1450px;

/*Border radius*/
$border-radius: 0.5rem;
/*Required text */
.required-text-block {
  display: flex;
  color: $secondary-color;
  span.required-sign {
    font-size: $f30;
    line-height: normal;
  }
  span {
    display: inline;
  }
}
html {
  font-size: 10px;
}

body {
  @extend %gotham-book;
  margin: 0;
  font-size: $f14;
  color: $primary-color !important;
  cursor: default;
  text-transform: lowercase;
  &.modal-open {
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
  &.ReactModal__Body--open {
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
}
.modal.show {
  .modal-title {
    width: 100%;
    position: relative;
    .close-btn {
      right: -4rem;
      top: -5rem;
      @media screen and (max-width: $screen__xs) {
        right: -3rem;
        top: -4.5rem;
      }
    }
  }
}
h1 {
  font-size: $f64;
  @extend %gotham-medium;
  line-height: 6.2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $thirdy-color;
  &.light {
    @extend %gotham-book;
  }
  @media screen and (max-width: $screen__m) {
    font-size: $f48;
    line-height: 5rem;
  }
  @media screen and (max-width: $screen__xs) {
    font-size: $f34;
    line-height: 3.6rem;
  }
}

h2 {
  font-size: $f48;
  @extend %gotham-medium;
  line-height: 5.2rem;
  padding-bottom: 2rem;
  color: $primary-color;
  &.gray {
    color: $secondary-color;
  }
  &.light {
    @extend %gotham-book;
  }
  @media screen and (max-width: $screen__s) {
    font-size: $f34;
    line-height: 3.6rem;
  }
  @media screen and (max-width: $screen__xs) {
    font-size: $f21;
    line-height: 3.6rem;
  }
}

h3 {
  font-size: $f34;
  @extend %gotham-medium;
  line-height: 3.6rem;
  padding-bottom: 1.5rem;
  color: $primary-color;
  &.gray {
    color: $secondary-color;
  }
  &.light {
    @extend %gotham-book;
  }
  @media screen and (max-width: $screen__s) {
    font-size: $f20;
    line-height: 3.2rem;
    &.large {
      font-size: $f34;
    }
    &.medium {
      font-size: 2.4rem;
    }
  }
}

h4 {
  font-size: $f20;
  color: $black;
  &.xl {
    font-size: $f30;
  }
  &.gray {
    color: $secondary-color;
  }
  &.heavy {
    @extend %gotham-medium;
  }
  @extend %gotham-book;
  line-height: 3.2rem;
  @media screen and (max-width: $screen__s) {
    font-size: 1.3rem;
    &.large {
      font-size: $f20;
    }
    &.xl {
      font-size: $f30;
    }
  }
}
h5 {
  font-size: $f16;
  color: $secondary-color;
  @extend %gotham-book;
  line-height: 2.2rem;
  &.heavy {
    @extend %gotham-medium;
  }
  &.orange {
    color: $primary-color;
  }
  &.orange-nav {
    color: $color-orange-dark-nav;
  }
}

p {
  font-size: $f14;
  margin: 0;
  line-height: 2.4rem;
  color: $thirdy-color;
  &.light-gray {
    color: $secondary-color;
  }
  @extend %gotham-book;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

a {
  color: $primary-color;
  text-decoration: underline;
  @extend %gotham-book;
  &:hover {
    color: $primary-color;
  }
}
%bigger-a {
  font-size: $f20;
  line-height: 3.6rem;
}
%smaller-a {
  font-size: $f16;
  line-height: 2.2rem;
}

ul {
  list-style: none;
}
@media (-webkit-min-device-pixel-ratio: 2) {
  html {
    font-size: 9px;
  }
  body {
    zoom: 90%;
  }

  header {
    zoom: 107%;
  }
}

.page-main-content {
  @extend %page-content-width;
}
/*Page width 1450*/
%page-content-width {
  margin-left: auto;
  margin-right: auto;
  max-width: $page-max-width;
  padding-top: 5rem;
  @media screen and (max-width: $screen__l) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media screen and (max-width: $screen__l) {
    padding-top: 3rem;
  }
}
/*Header width 1800*/
%block-width-1800 {
  margin-left: auto;
  margin-right: auto;
  max-width: 180rem !important;
  @media screen and (max-width: $screen__l) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
/*Footer middle and bottom width 1450*/
%block-width-1450 {
  margin-left: auto;
  margin-right: auto;
  max-width: 145rem;
  @media screen and (max-width: $screen__l) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
/*Footer top width 950*/
%block-width-950 {
  margin-left: auto;
  margin-right: auto;
  max-width: 95rem;
  @media screen and (max-width: $screen__l) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
/*Blocks with gray background*/
%gray-background-block-style {
  background-color: $color-gray-block;
  color: $black;
}
/*Filter title block*/
%filter-title-style {
  background-color: $color-gray-filter;
  color: $black;
  border-radius: $border-radius;
}
/*Gray line*/
%bottom-border {
  border-bottom: 0.1rem solid $color-gray-bottom-border;
}
.border-gray {
  border-color: $secondary-color;
}
/*Breadcrumbs*/
%breadcrumbs {
  font-size: $f11;
  line-height: 1.5rem;
  color: $secondary-color;
}

.select2.main-select {
  .react-select__control {
    height: 6.1rem;
    margin-bottom: 2.5rem;
    border-radius: 5px;
    border: 1px solid #b3b9b9;
    box-shadow: none !important;

    .react-select__placeholder,
    .react-select__single-value {
      color: $color-gray-light;
      font-size: $f20;
      line-height: 2.8rem;
      @extend %gotham-book;
    }
    .react-select__indicator svg {
      width: 2rem;
      height: 2rem;
      path {
        fill: $primary-color;
      }
    }
  }
  &.dark {
    .react-select__control {
      border-color: $color-black;
      .react-select__placeholder {
        @extend %gotham-medium;
        color: $color-black;
      }
      .react-select__single-value {
        color: $color-black;
      }
    }
  }
  &.indented {
    .react-select__control {
      .react-select__placeholder,
      .react-select__single-value {
        margin-left: -0.2rem;
      }
    }
  }
}
.svg-inline--fa {
  width: 2rem !important;
  height: 2rem;
  path {
    fill: $primary-color;
  }
}
/*Button style*/
.btn-link {
  width: 100%;
}
button.btn {
  font-size: $f20;
  text-transform: lowercase;
  padding: 2rem !important;
  border-radius: $border-radius;
  margin: 0;
  line-height: normal;
  @extend %gotham-medium;
  max-width: 15rem;
  width: 100%;
  transition: all 0.5s ease-out;
  &.w-100 {
    width: 100%;
    max-width: none;
  }
  &.right-arrow {
    display: inline-flex;
    align-items: center;
    text-align: left;
    i,
    svg {
      position: absolute;
      right: 2rem;
      top: 2.2rem;
    }
  }
  /*Orange button*/
  &.btn-default,
  &.btn-primary {
    background-color: $primary-color !important;
    a {
      color: $white !important;
    }
    &:not([disabled]):not(.disabled):active {
      background-color: $primary-color !important;
      a {
        color: $primary-color;
      }
    }
  }
  &.orange-button {
    background-color: $primary-color !important;
    a {
      color: $white !important;
    }
    &.btn-default:active,
    &.btn-primary:active {
      background-color: $primary-color !important;
    }
  }
  /*Gray button*/
  &.gray-button {
    background: $color-gray-light !important;
    a {
      color: $white !important;
    }
    &.btn-default:active {
      background-color: $color-gray-light !important;
    }
  }
  /*White button with orange border*/
  &.white-button {
    background-color: $white !important;
    color: $primary-color !important;
    border: 0.15rem solid $primary-color !important;
    &.btn-default:active {
      background-color: transparent !important;
      border: 0.15rem solid $primary-color !important;
      color: $white !important;
    }
  }
  /*White button with orange black*/
  &.black-button {
    border: 0.15rem solid $thirdy-color !important;
    a {
      color: $thirdy-color !important;
    }
    &.btn-default:active {
      background-color: transparent !important;
      border: 0.15rem solid $thirdy-color !important;
    }
  }
  /*Button with left square icon*/
  &.basic-btn {
    font-size: $f14;
    display: inline-flex;
    align-items: center;
    padding: 0.8rem !important;
    border: 0.15rem solid $color-gray-light !important;
    background: transparent;
    a {
      color: $thirdy-color !important;
      display: flex;
      align-items: center;
      @extend %gotham-book;
    }
    i {
      &:before {
        font-size: $f30;
        border: 1px solid $secondary-color;
        background: $sand;
        color: $sand;
        border-radius: $border-radius;
      }
    }
    &.btn-primary:active {
      background-color: transparent !important;
      border: 0.15rem solid $color-gray-light !important;
    }
  }
  &.without-icon {
    background-color: $primary-color !important;
    a {
      color: $white !important;
    }
    &.btn-default:active {
      background-color: $primary-color !important;
    }
  }
  /*Instagram button*/
  &.instagram-btn {
    text-transform: none;
    display: inline-flex;
    width: auto;
    align-items: center;
    background-color: rgba($primary-color, 0.85);
    font-size: $f16;
    border-radius: 0;
    padding: 1.5rem 8rem;
    i {
      &:before {
        font-size: $f20;
      }
    }
    a {
      color: $white !important;
    }
    &.btn-default:active {
      background-color: rgba($primary-color, 0.85) !important;
    }
  }
}
a.btn {
  text-decoration: none;
}
/*White button with double bottom border*/
%btn-bottom-border {
  border-width: 0.15rem 0.15rem 0.5rem 0.15rem;
}
//Checkbox button
.check-button.form-check {
  padding-left: 4.5rem;
  label.form-check-label {
    display: flex;
    align-items: center;
  }
}
.checkbox-container {
  margin-bottom: 1.5rem;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .checkmark {
    background-color: $primary-color;
    border: 0.1rem solid $primary-color;
  }
  input:checked ~ .checkmark.gray {
    background-color: $secondary-color;
    border: 0.1rem solid $secondary-color;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  label {
    height: 2.5rem;
    color: $thirdy-color;
    font-size: $f14;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.5rem;
    width: 2.5rem;
    border: 0.1rem solid $secondary-color;
    border-radius: $border-radius;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 0.9rem;
      top: 0.4rem;
      width: 0.5rem;
      height: 1.2rem;
      border: solid $white;
      border-width: 0 0.2rem 0.2rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
//Switch button
.switch-container.check-button {
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 6.5rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  label {
    height: 3rem;
    color: $secondary-color;
    font-size: $f16;
    @extend %gotham-medium;
  }
  input:checked ~ .slider {
    background-color: $white;
    border: 0.15rem solid $primary-color;
    &:before {
      background-color: $primary-color;
    }
  }
  input:checked ~ .slider:before {
    -webkit-transform: translateX(2rem);
    -ms-transform: translateX(2rem);
    transform: translateX(2rem);
    bottom: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 5rem;
    height: 3rem;
    background-color: $color-gray-light;
    border: 0.15rem solid $secondary-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 2.3rem;
    &:before {
      position: absolute;
      content: "";
      height: 2.75rem;
      width: 3rem;
      left: -0.1rem;
      bottom: 0;
      background-color: $secondary-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: -0.1rem 0 0.3rem $color-black-shadow;
    }
  }
}
//Radio button
.radio-container {
  position: relative;
  margin-bottom: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  label {
    height: 3rem;
    @extend %gotham-medium;
    color: $color-gray-light;
    font-size: $f16;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ label {
    color: $color-orange-dark-nav;
  }
  input:checked ~ .radio {
    border: 0.15rem solid $color-orange-dark-nav;
  }
  input:checked ~ .radio:after {
    display: block;
  }
  .radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    width: 3rem;
    background-color: $white;
    border: 0.15rem solid $color-gray-light;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 0.55rem;
      left: 0.63rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: $color-orange-dark-nav;
    }
    &.checked-yes {
      border: 0.15rem solid $color-orange-dark-nav;
    }
  }
}
.modal {
  display: block;
}
img {
  width: 100%;
  height: auto;
}

.order-grid {
  > thead {
    > tr {
      > th {
        @extend %gotham-medium;
        font-size: $f20;
        color: $secondary-color;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        @extend %gotham-book;
        color: $thirdy-color;
        font-size: $f16;
        a {
          color: $primary-color;
        }
      }
    }
  }
}

.mobile-order-grid {
  .order-attribute-name {
    @extend %gotham-medium;
    font-size: $f16;
    color: $secondary-color;
  }

  .order-attribute-value {
    @extend %gotham-book;
    color: $thirdy-color;
    font-size: $f16;
    a {
      color: $primary-color;
    }
  }
}

// SLIDER DOTS
.list-custom-dots {
  display: inline-flex;
  padding: 0;
  align-items: center;
  cursor: pointer;
}
.slick-active {
  .slider-custom-dot {
    width: 1.6rem;
    color: transparent;
    border-radius: 50%;
    border: none;
    height: 1.6rem;
    background: white;
  }
  > div {
    > div {
      outline: none;
    }
  }
}
.slider-custom-dot {
  margin: 0 1rem;
  width: 1rem;
  color: transparent;
  border-radius: 50%;
  height: 1rem;
  background: transparent;
  border: 0.2rem solid white;
}
/*Input field*/
input.default-input-field {
  border: 0.1rem solid $thirdy-color !important;
  border-radius: $border-radius !important;
  padding: 1.5rem !important;
  font-size: $f20;
  box-sizing: border-box;
  &::placeholder {
    color: $thirdy-color !important;
  }
}
input {
  &::placeholder {
    color: $thirdy-color !important;
    font-size: 2rem;
    @extend %gotham-medium;
  }
  /*Edge*/
  &::-ms-input-placeholder {
    color: $thirdy-color !important;
  }
}
/*Edge*/
input[type="text"]::-ms-clear {
  display: none;
}
.md-form {
  &.default-input-field-container {
    input.specify-input-field {
      padding: 0.2rem !important;
      &::placeholder {
        color: $thirdy-color !important;
      }
    }
    label {
      top: 0.7rem;
      white-space: nowrap;
      padding-left: 2rem;
      color: $thirdy-color !important;
      font-size: $f20 !important;
      @extend %gotham-medium;
      line-height: normal;
    }
  }

  label.active {
    line-height: normal !important;
    font-size: $f20 !important;
    transform: translateY(-3rem) translateX(-1.6rem) scale(0.8) !important;
  }
  label.please-specify {
    padding-left: 1rem;
    text-align: left;
    font-size: $f14;
    letter-spacing: 0;
    color: $thirdy-color;
    opacity: 1;
    @extend %gotham-medium;
  }
  input {
    box-sizing: border-box !important;
    font-size: 2rem;
    padding: 1.5rem 2.2rem !important;
    &:focus {
      border-bottom: 1px solid $primary-color !important;
      box-shadow: 0 1px 0 0 $primary-color !important;
    }
    &.specify-input-field {
      padding: 0rem 2.2rem !important;
    }
  }

  .md-form {
    &.specify-input-container {
      .specify-input-field {
        border-bottom: 2px solid #ebebeb;
      }
      label {
        font-size: 1.4rem;
        top: -1rem;
        transition: all 0.2s ease-in-out;
        &.active {
          padding-left: 0;
          color: #000;
        }
      }
    }
  }
  textarea {
    &.md-textarea {
      border-radius: $border-radius !important;
      border: 1px solid $thirdy-color;
      padding: 2rem;
      height: 20rem;
      box-sizing: border-box;
      font-size: $f20;
      &:focus {
        border-bottom: 1px solid $primary-color !important;
        box-shadow: 0 1px 0 0 $primary-color !important;
      }
    }
  }
}
.md-form.default-input-field-container
  > input[type]:-webkit-autofill:not(.browser-default):not([type="search"])
  + label,
.md-form.default-input-field-container
  > input[type="time"]:not(.browser-default)
  + label {
  line-height: normal !important;
  transform: translateY(-3rem) translateX(-1.6rem) scale(0.8) !important;
}
/*Input field mask*/
.input-mask {
  input {
    // &:focus {
    &::placeholder {
      // transform: translateY(-4rem) translateX(-1.6rem) scale(0.8) !important;
      @extend %gotham-medium;
    }
    // }
  }
}
/*Quantity field*/
.quantity-field {
  display: flex;
  align-items: center;
  i {
    &::before {
      font-size: 2.5rem;
    }
  }
  .input-field {
    border: 0.1rem solid $color-gray-light;
    text-align: center;
    height: 4.5rem;
    width: 4.5rem;
    margin: 0 1.5rem;
    padding: 0;
    border-radius: $border-radius;
    -webkit-appearance: none;
    box-shadow: none;
    appearance: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

/*Wishlist btn*/
button.btn.wishlist-btn,
button.compare-btn {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: $primary-color;
  width: auto;
  background-color: transparent !important;
  border: 0.15rem solid $color-gray-light;
  padding: 1.5rem 1.2rem !important;
  i {
    &.fa-heart,
    &.fa-clone {
      padding-right: 0.8rem;
    }
    &.fa-plus {
      &:before {
        font-size: 1.9rem;
      }
    }
    &:before {
      font-size: $f26;
    }
  }
  @media screen and (max-width: $screen__m) {
    margin-top: 2.2rem;
  }
}
/* Filter button */
.dropdown {
  button.filter-btn {
    background-color: $color-gray-filter !important;
    color: $thirdy-color;
    width: 30rem;
    padding: 1rem 1rem 1rem 5rem !important;
    @extend %gotham-book;
    text-align: left;
    display: flex;
    align-items: center;
    i {
      position: absolute;
      right: 1rem;
    }
  }
  div.dropdown-menu {
    padding: 2rem 0;
    border: none;
    width: 30rem;
  }
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
// list
ul {
  &.custom-list {
    margin: 2.5rem 0;
    li {
      color: $secondary-color;
      font-size: $f21;
      @extend %gotham-book;
      &:before {
        content: "•";
        color: $primary-color;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
// SCROLL
/* Firefox and Safari*/
html {
  --scrollbarBG: #ebebeb;
  --thumbBG: #b3b9b9;
}
body {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

/*Chrome*/
::-webkit-scrollbar {
  width: 1rem;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color-gray-light;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: $color-gray-light;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-gray-light;
}
.select-div-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .custom-select {
    border: 0.1rem solid $thirdy-color !important;
    border-radius: $border-radius !important;
    height: 5.7rem;
    padding-left: 2rem !important;
    box-sizing: border-box;

    @extend %gotham-medium;
    font-size: $f20;
    &::placeholder {
      padding-left: 2rem !important;
      color: $thirdy-color !important;
    }
    option {
      padding-left: 2rem;
      padding-top: 2rem;
      color: $thirdy-color !important;
      font-size: $f20;
      @extend %gotham-medium;
    }
  }
}

// MDB LIST
.list-group.account-group {
  a {
    &.list-group-item.account-link {
      border: none;
      margin-top: 0;
      border-radius: 0.5rem;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      color: $thirdy-color;
      font-size: $f20;
      &.active {
        background-color: $color-gray-filter;
      }
      &:hover {
        background-color: $primary-color;
        color: white;
      }
      // &.disabled {
      //   background-color: $color-gray;
      //   color: white;
      // }
    }
  }
  &.main-account-group {
    border-bottom: 1px solid $color-gray-light;
  }
  // &.seconday-account-group {
  // }
}

// MODAL TRANSITIONS
.modal {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
  &.show {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@media screen and (min-width: 576px) {
  .modal {
    &.fade {
      .modal-dialog {
        display: none;
      }
    }
  }
}
// SELECT
.custom-select {
  border: 0.1rem solid $thirdy-color !important;
  border-radius: $border-radius !important;
  height: 5.7rem;
  padding-left: 2rem !important;
  box-sizing: border-box;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @extend %gotham-medium;
  font-size: $f20;
  &::placeholder {
    padding-left: 2rem !important;
    color: $thirdy-color !important;
  }
  option {
    padding-left: 2rem;
    padding-top: 2rem;
    color: $thirdy-color !important;
    font-size: $f20;
    @extend %gotham-medium;
  }
}
.select2 {
  .react-select__control {
    border: 1px solid $thirdy-color;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      border-color: $thirdy-color;
    }
    .react-select__value-container {
      padding-left: 2.1rem;
      @extend %gotham-medium;
      .react-select__single-value {
        font-size: $f20;
      }
      .react-select__placeholder {
        font-size: $f20;
        color: $thirdy-color;
      }
      .react-select__input {
        font-size: $f20;

        input {
          padding: 0.9rem 0 !important;
        }
      }
    }
  }
  .react-select-container {
    .react-select__control--menu-is-open {
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 2px 3px 3px #00000052;

      &:hover {
        border-color: $thirdy-color;
        box-shadow: none;
        box-shadow: 2px 3px 3px #00000052;
      }
      &.react-select__control--is-focused {
        box-shadow: 2px 3px 3px #00000052;
      }
    }
    .react-select__menu {
      border: 1px solid $thirdy-color;
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-color: $thirdy-color;
      margin: 0;
      box-shadow: 2px 3px 3px #00000052;
      &-list {
        padding: 0;
        .react-select__option {
          font-size: $f20;
          padding-left: 2.1rem;
          padding-right: 2.1rem;
          @extend %gotham-medium;
          cursor: pointer;
          background-color: $color-gray-block;
          color: $thirdy-color;
          &.react-select__option--is-focused {
            background-color: $color-gray-light;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.btn-link {
  &:hover {
    text-decoration: none;
  }
}
.row.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 7.7rem;
}

// Session Messages //
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  25% {
    opacity: 1;
    transform: translateX(0%);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.session-container {
  position: fixed;
  top: 12rem;
  left: 1rem;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
  @media screen and (max-width: $screen__m) {
    top: 7rem;
    text-align: center;
  }
}
.session-messages-inner {
  left: 1rem;
  z-index: 9999;
  margin-bottom: 1rem;
  animation-name: fadeInOut;
  animation-duration: 4s;
  .session {
    padding: 1rem 2.5rem;
    background: #fff;
    span {
      padding-left: 1rem;
    }
    svg {
      position: relative;
      top: 0.4rem;
      &.svg-inline--fa path {
        fill: $white;
      }
    }
    &.danger {
      span {
        color: $white;
      }
      background: $danger_session;
    }
    &.success {
      color: $color-black;
      background: $success;
      svg {
        &.svg-inline--fa path {
          fill: $color-black;
        }
      }
    }
    &.info,
    &.warning {
      color: $white;
      background: $warning;
    }
  }
}

.session-messages-inner {
  padding: 1rem 0rem;

  .session {
    &.danger {
      color: $danger_session;
    }
  }
}

.lazy-load-image-background {
  min-width: 100%;
  width: 100%;
  // display: block !important;
}

.lazy-load-image-backround {
  img {
    max-width: 100%;
    height: auto;
    min-width: 100%;
    transition: none !important;
  }
}

#chat-widget-container {
  z-index: 1002 !important;
  @media screen and (min-width: $screen__m) {
    width: 35rem !important;
  }
}

.live-chat-opened {
  @media screen and (max-width: $screen__s) {
    width: 100% !important;
  }
}

@media screen and (min-width: $screen__l) {
  .live-chat-opened {
    height: 50% !important;
  }
}

.qty-control-wrapper > div {
  display: flex;
  align-items: center;
  .qty-click {
    cursor: pointer;
  }
  .fa {
    color: $primary-color;
  }
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
