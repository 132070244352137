@import "./search/search";
@import "./MiniCartLabel/MiniCartLabel.scss";
@import "./MiniCart/MiniCart.scss";
@import "./MiniCart/MiniCartItem/MiniCartItem.scss";
@mixin fontTransition() {
  transition: font-weight 0.4s ease-in-out;
}
@mixin transformTransition() {
  transition: transform 0.4s ease-in-out;
}
header {
  nav.navbar {
    ul.navbar-nav.right {
      z-index: 20;

      background-color: $white;
      .dropdown {
        // padding-top: 0.5rem;
        // z-index: -1;
        .btn {
          background-color: $white;
          padding: 0 !important;
          @extend %gotham-book;
          color: $primary-color;
          margin: 0 !important;
          &.my-account-btn {
            font-size: 1.3rem;
            padding: 1rem 0 !important;
            box-sizing: border-box;
            box-shadow: none;
            z-index: 1;
            background-color: $white;
            display: inline-block;
            border-bottom: 0.1rem solid transparent;
            white-space: nowrap;
            width: auto;
            padding-top: 3rem !important;
            padding-bottom: 2.8rem !important;
          }
        }
        .dropdown-menu {
          box-sizing: border-box;
          width: 13rem !important;
          left: -2rem !important;
          &.my-account-dropdown {
            @include transformTransition();
            transform: translateY(-24rem) !important;
            border: none;
            display: block;
            padding-left: 2rem;
            z-index: 0;
            border-radius: 0 0 $border-radius $border-radius;
            .dropdown-item {
              width: auto;
              @include fontTransition();
              font-size: $f12;
              color: $primary-color;
              padding: 0.5rem 0;
              font-weight: 400;
              outline: none;
              background: $white;
              button,
              a.nav-link {
                font-size: 1.2rem;
                padding: 0;
                text-align: left;
                color: $primary-color;
                background-color: $white !important;
                box-shadow: none;
                width: 100%;
                &:hover,
                &:active,
                &:focus {
                  @extend %gotham-medium;
                }
              }
            }
            &.show {
              transform: translateY(4.2rem) !important;
            }
          }
        }
        &.show {
          .btn {
            &.my-account-btn {
              border-radius: 0;
              &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 0.1rem;
                background-color: #ff6d2d;
                z-index: 999;
                left: 0;
                bottom: 1.3rem;
              }
            }
          }
        }
      }
      li {
        margin-left: 3.2rem;
        z-index: 2;
        p {
          font-size: 1.3rem;
        }
        &.search {
          svg.header-search-icon {
            overflow: visible;
            &#removeFilterButtonGray {
              .circle-close-b {
                stroke-width: 0.15rem;
              }
            }
          }
        }
        &.sing-in,
        &.wishlist,
        &.cart,
        &.my-account {
          a {
            p {
              color: $color-orange-dark-nav;
            }
          }
        }
        &.wishlist,
        &.cart {
          &.show-minicart-true {
            border-bottom: 0.1rem solid $color-orange-dark-nav;
          }
          &.show-minicart-false {
            border-bottom: 0.1rem solid transparent;
          }
          p {
            padding-left: 1.5rem;
          }
        }
        &.sing-in,
        &.my-account,
        &.customer-name {
          p {
            display: inline-block;
            white-space: nowrap;
          }
        }
        &.customer-name {
          p {
            color: $secondary-color;
          }
        }
        a {
          display: flex;
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  header {
    nav.navbar {
      ul.navbar-nav.right {
        li.customer-name {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  header {
    nav.navbar {
      ul.navbar-nav.right {
        li {
          margin-left: 1rem;
          &.customer-name {
            margin-right: 1rem !important;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  header {
    nav.navbar {
      ul.navbar-nav.right {
        position: absolute;
        flex-direction: row;
        right: 0;
        top: 0.5rem;
      }
    }
  }
}
