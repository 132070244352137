.share-favorites-btn-wrapper {
  width: 100%;
  margin: 3rem 0;
  button {
    &.share-favorites-btn {
      max-width: 15rem !important;
      width: 100% !important;
    }
  }
}
