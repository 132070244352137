.minicart-wrapper {
  background-color: $white;
  position: absolute;
  width: 33.5rem;
  right: 0;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;

  .minicart-head {
    text-align: center;
    padding: 0.6rem 2.2rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .minicart-body {
    padding: 1rem 1.5rem 0;
    max-height: 54rem;
    overflow-y: auto;
  }

  .minicart-foot {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem 2.5rem;

    .minicart-subtotal-label {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    .minicart-subtotal-value {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }

  .minicart-link {
    display: block;
    text-align: center;
    margin: 0;
    padding: 1.3rem 0;
    border-radius: 0 0 0.4rem 0.4rem;
    border: 0;
    line-height: 1.9rem;
  }
}
