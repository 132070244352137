.minicart-icon {
  padding-top: 5rem;
  #minicart {
    transform: translateY(-300%);
  }
  &.active {
    #minicart {
      transform: translateY(0);
    }
  }
}
