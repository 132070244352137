@import "./cartItem/cartItem";
@import "./mobileCartItem/mobileCartItem";
@import "./summary/summary";
.cart-page {
  max-height: 100%;
  min-height: calc(100vh - 580px);
  @extend %page-content-width;

  .content-container {
    @media (max-width: 992px) {
      margin-right: -30px;
      margin-left: -30px;
      flex-direction: column-reverse;
    }
  }
  .discount-caret {
    padding: 0;
  }
}
// @media screen and (max-width: $screen__l) {
//   .cart-page {
//     max-height: 100%;
//     min-height: calc(100vh - 420px);
//   }
// }
// @media screen and (max-width: $screen__ml) {
//   .cart-page {
//     max-height: 100%;
//     min-height: calc(100vh - 380px);
//   }
// }
// @media screen and (max-width: $screen__s) {
//   .cart-page {
//     max-height: 100%;
//     min-height: 100%;
//   }
// }
