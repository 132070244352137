.custom-shop-the-look {
  padding-top: 1rem;
  background-color: $color-gray-block;
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .custom-shop-the-look {
    .shop-look-slider-content {
      padding-bottom: 2rem;
      .slick-list {
        .slick-slide {
          padding: 0;
          p {
            text-align: center;
            padding: 2rem 0 1rem 0;
          }
        }
      }
    }
  }
}
@media (max-width: $screen__xs) {
  .custom-shop-the-look {
    .shop-look-container {
      margin-top: 4rem;
      .shop-look-text {
        h1 {
          max-width: 100%;
          font-size: $f34;
          padding: 0;
        }
      }
    }
  }
}
