#order-summary-popup {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: 0.2rem solid #ff5100;
  font-size: 16px;
  .header {
    background-color: #ff5100;
    color: $white;
    text-align: center;
    padding: 1.5rem 0;
    h4 {
      font-size: 2rem;
      color: $white;
    }
    .close-icon {
      right: 0;
    }
    #closeBtnGrey {
      circle {
        stroke: #fff;
      }
      g {
        path {
          stroke: #fff;
        }
      }
    }
  }
  .order-summary-top-content {
    margin: 2.5rem 0;
    h3 {
      display: none;
    }
  }
  .order-summary-container {
    background-color: #fff;
  }
  .order-summary-qty {
    padding: 0 3rem;
  }
  .checkout-review-info {
    border-color: #858b8b;
    color: #858b8b;
    justify-content: start;
    &:last-of-type {
      border-bottom: 1px solid;
    }
    .checkout-review-info-inner {
      width: 100%;
      margin-left: 1.2rem;
      @media screen and (max-width: 400px) {
        flex: 0 0 75%;
      }
    }
  }
  .order-summary-container {
    .order-items-review {
      padding: 0 4rem;
    }
  }
  .order-summary-subtotal {
    padding: 0 3rem;
    width: 100%;
    color: #858b8b;
    div {
      &:first-of-type {
        margin-top: 2.5rem;
      }
    }
  }
  .order-summary-total {
    width: 100%;
    color: #858b8b;
    padding: 0 3rem;
  }
}
