.shop-the-look-item {
  position: relative;
  padding: 1.5rem 1.5rem;
  transition: opacity 0.4s ease-in-out;
  transition: all 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
  transition: min-height 0.2s ease-in-out;
  // &:nth-child(3n) {
  //   padding-right: 0;
  // }
  // &:nth-child(3n + 1) {
  //   padding-left: 0;
  // }
  &.inactive {
    width: 0;
    max-width: 0;
    height: 0;
    padding: 0;
    opacity: 0;
    &:first-of-type {
      height: 20rem;
    }
  }
  &.active {
    opacity: 1;
  }
  .imageloader {
    height: 100%;
    img {
      height: 100%;
    }
  }
  &:hover {
    padding: 0;
    .shop-the-look-link {
      h3 {
        opacity: 1;
      }
      position: relative;
      .imageloader {
        position: relative;
        height: 100%;
        img {
          min-height: 48.3rem !important;
          max-height: 48.3rem !important;
          @media screen and (max-width: 1150px) {
            min-height: 35rem !important;
            max-height: 35rem !important;
          }
        }
      }
    }
  }
  .shop-the-look-link {
    padding: 0;
    height: 100%;
    img {
      height: auto;
    }
    h3 {
      background-color: rgba(255, 109, 45, 0.85);
      position: absolute;
      bottom: 0;
      width: 100%;
      color: $white;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      font-size: 1.6rem;
      text-align: center;
      padding: 0;
      min-height: 5rem;
      line-height: 5rem;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .shop-the-look-item {
    padding: 1.5rem !important;
    &.active {
      opacity: 1;
      padding: 1.5rem !important;
    }
    .shop-the-look-link {
      h3 {
        bottom: 0;
      }
    }
  }
}
