.container {
  &-fluid {
    .tabs-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      border: none;
      margin-bottom: 5rem;
      .tab {
        width: calc(1 / 4 * 100% - (1 - 1 / 4) * 3%);
        border: none;
        cursor: pointer;
        margin: 0;
        color: $thirdy-color;
        font-size: 2rem;
        height: 6rem;
        color: $primary-color;
        text-transform: none;
        max-height: 6rem;
        text-decoration: none;
        padding: 0 2rem;
        line-height: 6rem;
        border-radius: 0.5rem;
        border: 0.1rem solid $thirdy-color;
        color: $thirdy-color;
        @extend %gotham-medium;
        &.active {
          border: 0.1rem solid $primary-color;
          color: $primary-color;
          color: #ff6d2d;
          border-width: 0.2rem 0.2rem 0.5rem 0.2rem !important;
          display: block;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .container {
    &-fluid {
      .faq-content {
        .tabs-container {
          white-space: nowrap;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          .tab {
            width: 100%;
            margin-right: 2%;
            min-width: 24rem;
          }
          .nav-tabs {
            li.nav-item {
              width: 100%;
            }
          }
          .tab-content {
            &.active {
              .title-tab {
                color: $secondary-color;
                margin-bottom: 5rem;
              }
            }
          }
        }
      }
    }
  }
}
.title-gray {
  color: $secondary-color;
}
.not-show {
  display: none;
}
