/*Pagination*/
.page-pagination {
  user-select: none;
  .pagination-dots {
    @extend %gotham-book;
    font-size: $f20;
    color: $color-gray-light;
    line-height: 2rem;
    display: flex;
    height: 4rem;
    align-items: flex-end;
  }
  li.page-item {
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.no-click {
      cursor: auto;
    }
    &.pagination-icon {
      i {
        color: $primary-color;
      }
      width: 3rem;
    }
    &.active-page-item {
      border: 0.1rem solid $primary-color;
      border-radius: $border-radius;
      .pagination-button {
        color: $primary-color;
      }
    }
    .pagination-button {
      font-size: $f20;
      color: $color-gray-light;
      transition: color 0.3s ease-in-out;
      text-decoration: none;
    }
    &:hover {
      .pagination-button {
        color: $primary-color;
      }
    }
    svg {
      color: $primary-color;
    }
    &.view-all {
      text-decoration: underline;
      width: auto;
      .pagination-button {
        color: $primary-color;
      }
    }
  }
}
