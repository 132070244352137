@import "./item/copy/copy";
.empty-wishlist {
  font-size: 2.4rem;
  @extend %gotham-medium;
}
.wishlist-item {
  font-size: 1.6rem;
  text-align: left;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 0.2rem solid $color-gray-bottom-border;
  &:last-child {
    border-bottom: none;
  }
  .wishlist-item-name {
    margin-bottom: 1.2rem;
    a {
      text-decoration: none;
      margin-bottom: 0;
      padding: 0;
    }
    span {
      font-size: 1.6rem;
      color: $color-orange-dark-nav;
    }
  }
  .wishlist-copy-item {
    margin-top: 7.5rem;
    text-align: left;
    button.compare-btn {
      padding: 1.4rem 1.2rem !important;
    }
  }
  .wishlist-item-price {
    @media screen and (max-width: $screen__m) {
      > div {
        flex: 0 0 12%;
        margin-right: 1rem;
      }
    }
    span {
      font-size: 1.6rem;
      &.wishlist-item-starting-label {
        color: $color-orange-brick;
        margin-right: 1.5rem;
      }
      &.wishlist-item-starting-price {
        color: $thirdy-color;
      }
    }
  }
  .wishlist-button {
    padding: 1.75rem 1.5rem !important;
  }
  svg {
    cursor: pointer;
    &.active {
      filter: grayscale(0.4);
      cursor: default;
    }
  }
  #delete-product-modal {
    &.modal.show {
      .modal-dialog {
        min-width: 90rem;
        .modal-body {
          text-align: center;
          width: 60rem;
          max-width: 100%;
          .del-project-subtitle {
            font-size: 2.6rem;
            line-height: 3.2rem;
            margin-bottom: 5rem;
          }
          .del-project-buttons {
            justify-content: space-between;
            button {
              font-size: $f20;
            }
          }
        }
      }
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .wishlist-item {
    .item {
      align-self: flex-start !important;
      flex-basis: 50%;
      &.wishlist-item-cart-remove {
        align-self: flex-end !important;
      }
      .wishlist-item-info {
        align-self: flex-start !important;
      }
    }
  }
  .wishlist-copy-item {
    margin-top: 4.5rem;
  }
  .wishlist-button {
    &.icon-btn {
      padding: 0 !important;
      max-width: 8rem;
      height: 6.6rem;
      svg {
        .a {
          stroke: none;
          fill: transparent;
        }
        width: 7rem;
        height: 7rem;
      }
    }
    .compare-btn {
      height: 6.6rem;
    }
  }
}
@media (max-width: $screen__xs) {
  .wishlist-item {
    #delete-project-modal {
      &.modal.show {
        .modal-dialog {
          .modal-body {
            .del-project-buttons {
              flex-direction: column;
              button {
                max-width: 100%;
                &:first-child {
                  margin-bottom: 3rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
