@import "./fullscreen/fullscreen";
.mediaGallery {
  .carousel-thumbnails {
    // cursor: pointer;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 6rem;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.6s;
    transform: scale(0.97);
    will-change: transform;
    user-select: none;

    &.active {
      background: rgba(255, 255, 255, 0.3);
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grab;
      transform: scale(1);
      transition: all 0.6s;
      scroll-behavior: smooth;
    }
    .thumbnail-item {
      max-height: 9rem;
      max-width: 9rem;
      cursor: pointer;
      margin: 0.8rem;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border-top: 0.4rem solid transparent;
      &:after {
        content: "";
        display: block;
        height: 0.4rem;
        width: 0;
        background-color: $primary-color;
        position: absolute;
        top: -0.4rem;
        transition: width 1s;
      }
      :before {
        content: "";
        transition: left 1s;
        left: 0;
      }

      &.thumbnail-item-active {
        transition: all 0.6s ease-in-out;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 100%;
          background-color: $primary-color;
          position: absolute;
          top: -0.2rem;
          height: 0.5rem;
          transition: width 1s;
        }
        :before {
          content: "";
          position: absolute;
          transform: rotate(180deg);
          transition: left 1s;
          left: 50%;
          bottom: 100%;
          margin-left: -1rem;
          width: 0;
          height: 0;
          border-top: solid 1rem $primary-color;
          border-left: solid 1rem transparent;
          border-right: solid 1rem transparent;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
        height: 9rem;
        object-fit: cover;
        justify-content: center;
        width: 9rem;
      }
    }
  }
  .carousel-relative {
    position: relative;
    .icon-fullscreen {
      z-index: 2;
      position: absolute;
      bottom: 5%;
      right: 5%;
      cursor: pointer;
    }
  }
  .carousel-actions {
    display: none;
  }
}
.alice-carousel__wrapper {
  width: 100%;
  .alice-carousel__stage-item {
    vertical-align: middle;
  }
}
#carousel-undefined {
  .alice-carousel__stage-item {
    img {
      max-width: 100%;
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1369px) {
  .breadcrumbs-wrapper {
    padding: 0 3rem;
  }
  #productinfocontainer {
    width: 100%;
    #productinfoleft {
      .mediaGallery {
        width: 45rem !important;
        margin: 0 auto;
      }
      #productinfoleftlinks {
        width: auto;
      }
    }
  }
}
@media screen and (min-width: 1181px) and (max-width: 1280px) {
  #productinfocontainer {
    #productinforight {
      .productinforight-inner.with-border {
        left: 0 !important;
        > div {
          width: 55rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1180px) {
  #productinfocontainer {
    #productinforight {
      .productinforight-inner {
        > div {
          width: 50rem;
        }
      }
      .productinforight-inner.with-border {
        left: 0rem !important;
        > div {
          width: 50rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1130px) {
  #productinfocontainer {
    #productinfoleft {
      text-align: left;
      .mediaGallery {
        margin: 0 5rem;
      }
      #productinfoleftlinks {
        margin-left: 5rem;
        margin-right: 5rem;
      }
    }
  }
}
