.about-us-custom-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;
  .about-us-custom {
    margin-top: 0;
    position: relative;
    background-color: $color-gray-block;
    max-width: 1420px;
    margin: 0 auto;
    overflow: hidden;
    .block-with-right-slider {
      @extend %block-width-1450;
      justify-content: space-between;
      .block-part {
        &.block-text {
          width: 100%;

          h1 {
            padding-bottom: 0;
          }
          p {
            color: $secondary-color;
            font-size: $f21;
            text-transform: none;
            line-height: 3.6rem;
            @extend %gotham-book;
            margin: 3rem 0;
          }
          button {
            max-width: 33rem;
          }
        }
      }
    }
  }
  .custom-banner-and-shop {
    max-width: 142rem;
    margin: 0 auto;
  }
  .about-us-content {
    flex: 0 0 40%;
  }
  .about-us-slider {
    position: relative;
    width: auto;
    overflow: hidden;
    padding: 7rem 0;
    flex: 0 0 60%;
  }
}

/*Media Queries*/
@include media-breakpoint-down(md) {
  .about-us-custom-container {
    .about-us-content {
      flex-basis: 100%;
      // .about-us-custom {
      //   // padding: 5rem 0;
      //   // margin-top: 4rem;
      //   .block-with-right-slider {
      //     padding: 0;
      //     .block-part {
      //       &.block-text,
      //       &.block-slider {
      //         .slick-slider {
      //           > div {
      //             &:nth-child(2) {
      //               padding-top: 2rem !important;
      //               padding-bottom: 0 !important;
      //               text-align: center;
      //             }
      //           }
      //           .slick-track {
      //             .slick-slide {
      //               padding: 0;
      //               p {
      //                 padding-top: 2rem;
      //                 text-align: center;
      //               }
      //             }
      //           }
      //         }
      //       }
      //       &.block-text {
      //         padding: 0 3rem 5.5rem 3rem;
      //         h1 {
      //           padding-top: 0;
      //         }
      //       }
      //     }
      //   }
      // }
    }
    .about-us-slider {
      flex-basis: 100%;
    }
  }
}
@media screen and (max-width: $screen__s) {
  .about-us-custom {
    padding: 4rem 0 5rem 0;
    margin-top: 0;
    .block-with-right-slider {
      .block-part {
        &.block-text {
          h1 {
            font-size: $f34;
          }
          p {
            font-size: $f16;
            line-height: 2.4rem;
          }
        }
      }
    }
  }
  .about-us-slider {
    position: relative;
    width: auto;
    overflow: visible;
    padding-top: 0;
    .slick-slider {
      div:nth-child(2) {
        text-align: center !important;
      }
    }
  }
}
