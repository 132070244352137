.contact-info {
  max-width: 110rem;
  margin: 0 auto;
  h4 {
    color: $secondary-color;
    font-size: $f20;
    line-height: 3.2rem;
    @extend %gotham-medium;
  }
  .custom-input {
    &.validation-error {
      border-color: red !important;
    }
  }
  .validation-error {
    .react-select__control {
      border-color: red !important;
    }
  }

  .specify-input-container {
    margin-top: 0rem !important;
    .specify-input-field {
      border-bottom: 0.2rem solid $color-gray-bottom-border !important;
      font-size: $f14 !important;
      &:focus {
        box-shadow: none !important;
        border-bottom: 0.2rem solid $primary-color !important;
      }
    }
    label {
      font-size: $f14 !important;
      @extend %gotham-book;
      top: -1rem;
      color: $thirdy-color !important;
      &.active {
        transform: translate(-1rem, -1rem) !important;
        color: transparent !important;
      }
    }
  }
  .contact-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .contact-inputs-inner {
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 3%);
      .other {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
          width: calc(1 / 2 * 100% - (1 - 1 / 2) * 1%);
          &.md-form {
            margin: 0;
            label {
              font-size: 1rem;
            }
          }
        }
      }
      .how-did-title {
        margin: 1rem 0 2.5rem 0;
      }
    }
    .textarea {
      margin-top: 2rem;
    }
    .contact-required-block {
      text-align: right;
      .required-text-block {
        margin: 3rem 0;
        display: inline-flex;
      }
    }
  }
  .contact-submit-btn {
    padding-top: 3.8rem;
    border-top: 2px solid $color-gray-bottom-border;
    display: flex;
    flex-direction: row-reverse;
    max-width: 110rem;

    button {
      &.btn {
        max-width: 33rem;
      }
    }
  }
  .select-div-container {
    margin: 1.5rem 0;
    .select2 {
      margin-bottom: 2.5rem;
      height: 6rem;
    }
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__s) {
  .contact-info {
    max-width: 110rem;
    margin: 0 auto;
    h4 {
      color: $secondary-color;
    }
    .contact-inputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .contact-inputs-inner {
        width: 100%;
        .other {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          > div {
            width: 100%;
            &.md-form {
              margin: 2rem 0;
              label {
                font-size: 1rem;
              }
            }
          }
        }
        .how-did-title {
          margin: 1rem 0 2.5rem 0;
        }
        &.two {
          .specify-input-container {
            padding-left: 6.6rem;
            label {
              padding-left: 7.6rem;
            }
          }
        }
      }
      .textarea {
        margin-top: 2rem;
      }
    }
    .contact-submit-btn {
      padding-top: 3.8rem;
      border-top: 2px solid $color-gray-bottom-border;
      display: flex;
      flex-direction: row-reverse;
      max-width: 110rem;

      button {
        &.btn {
          max-width: 33rem;
        }
      }
    }
    .select-div-container {
      margin: 1.5rem 0;
      .select2 {
        margin-bottom: 2.5rem;
        height: 6rem;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .contact-info {
    .contact-inputs {
      .contact-required-block {
        text-align: left;
      }
    }
    .contact-submit-btn {
      button.btn {
        max-width: 100%;
      }
    }
  }
}
