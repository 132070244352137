@import "./activeFilterItem/activeFilterItem";

#active-filter {
  column-count: 2;
  &.selected-filter {
    margin-bottom: 2rem;
  }
  .filt-shop {
    display: none;
  }
  p {
    margin-bottom: 1rem;
    position: relative;
    color: $secondary-color;
    .close-btn {
      padding-right: 1rem;
      position: relative;
      left: 0;
      svg {
        cursor: pointer;
        overflow: visible;
        width: 2.5rem;
        height: 3rem;
      }
    }
  }
}
#active-filter.active-filter-mobile {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
  &.selected-filter {
    margin-bottom: 0;
  }
}
.filters {
  .clear-all-wrapper {
    top: 0.5rem;
    right: 1.5rem;
    color: $secondary-color;
    .clear-selected {
      cursor: pointer;
      font-size: $f20;
      text-decoration: underline;
    }
  }
}
