.finish-item-content {
  .product-image {
    &:hover {
      cursor: pointer;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 12rem;
        height: 12rem;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
        margin: auto;
        background-image: url("../../../../../assets/icon/addToCart.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  .product-cart {
    padding-right: 2rem;
    cursor: pointer;
  }
}
