.custom-top {
  @extend %block-width-1450;
  h1 {
    color: $primary-color;
    margin-bottom: 4rem;
    max-width: 75%;
  }
  .static-page-top-block {
    .block-with-right-img {
      justify-content: space-between;
      .block-part {
        padding: 0;
      }
      .block-text {
        width: calc(37% - 2rem);
        h5 {
          font-size: $f42;
          color: $color-gray-light;
          line-height: 4.6rem;
          padding-bottom: 5rem;
        }
        p {
          text-transform: none;
          font-size: $f21;
          line-height: 3.6rem;
        }
        a {
          text-decoration: none;
        }
        button {
          display: none;
          margin-top: 3rem;
          max-width: 33rem;
        }
      }
      .block-img {
        width: calc(63% - 2rem);
      }
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .custom-top {
    h5 {
      font-size: $f34;
      line-height: 3.6rem;
      padding-bottom: 3rem;
    }
    .static-page-top-block {
      .block-with-right-img {
        flex-direction: column-reverse;
        .block-part {
          width: 100%;
          &.block-text {
            padding-right: 0;
            h5 {
              display: none;
            }
          }
          &.block-img {
            padding-bottom: 3rem;
          }
        }
      }
    }
  }
}
@media (max-width: $screen__s) {
  .custom-top {
    h1 {
      max-width: 100%;
    }
  }
}
@media (max-width: $screen__xs) {
  .custom-top {
    h1 {
      max-width: 79%;
    }
    h5 {
      font-size: 2.8rem;
      line-height: 3.8rem;
    }
    .static-page-top-block {
      .block-with-right-img {
        .block-part {
          &.block-text {
            p {
              font-size: $f16;
              line-height: 2.4rem;
            }
            // button {
            //   max-width: 100%;
            // }
          }
        }
      }
    }
  }
}
@media (max-width: $screen__xxs) {
  .custom-top {
    h1 {
      max-width: 100%;
    }
  }
}
