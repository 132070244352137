@import "./orderSummaryInformation/orderSummaryInformation";
#mobile-order-summary-container {
  background-color: #f1f4f6;
  padding: 2.5rem 3rem;
  margin-top: 5.4rem;
  .mobile-order-summary-order-total {
    color: #858b8b;
    font-size: 1.6rem;
    @extend %gotham-medium;
  }

  #cartOrange {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.5rem;
  }
  .mobile-order-summary-cart-items {
    .span {
      font-size: 1.6rem;
      border: 1.5px solid #ff521d;
      border-radius: 5rem;
      width: 3rem;
      height: 3rem;
      line-height: 2rem;
      padding: 0.3rem;
      text-align: center;
    }
  }
}
