.banner-and-shop {
  background: $color-gray-block;
  max-width: 1800px;
  margin: 0 auto;
  overflow: hidden;
}
.banner-wrapper {
  position: relative;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background: rgba(0, 0, 0, 0.35);
  // }
  .banner-duc-content {
    position: absolute;
    width: 50rem;
    top: 50%;
    left: 79%;
    transform: translate(-50%, -50%);
    z-index: 1;
    h1 {
      color: #252525;
    }
    p {
      font-size: $f42;
      line-height: 1.5;
      color: #252525;
    }
    a {
      text-decoration: none;
    }
    // Black button
    button {
      margin-top: 4rem;
      // background-color: #252525 !important;
      // color: $white !important;
      // i {
      //   color: $white;
      // }
    }
  }
}

@media screen and (max-width: $screen__m) {
  .banner-wrapper {
    .banner-duc-content {
      transform: none;
      margin: 0 auto;
      width: 100%;
      left: 0;
      top: 20%;
      text-align: center;
      p {
        padding: 0 20%;
        font-size: $f20;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .banner-wrapper {
    .banner-duc-content {
      top: 8%;
    }
  }
}
@media screen and (max-width: 570px) {
  .banner-wrapper {
    min-height: 50rem;
    img {
      min-height: 50rem;
      object-fit: cover;
      object-position: left;
    }
    .banner-duc-content {
      top: 15%;
      h1 {
        font-size: $f48;
      }
    }
  }
}
