@keyframes infiniteJump {
  0%,
  100% {
    bottom: -2rem;
    transform: scale(0.8);
  }
  50% {
    bottom: 2rem;
    transform: scale(1);
  }
}
#search-results-container {
  border-top: 0.2rem solid $color-gray-bottom-border;
  padding-top: 3.3rem;
  overflow-y: auto;
  max-height: 66rem;
  min-height: 40rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5.7rem;

  @media screen and (max-width: $screen__m) {
    > .row > div {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.no-results {
    height: 20rem;
    text-align: center;
    font-size: 1.6rem;
    svg {
      width: 6rem;
      height: 6rem;
      animation: infiniteJump 1s ease-in-out infinite;
      position: relative;
    }
  }
  span,
  a {
    font-size: 2rem;
    margin-bottom: 3rem;
    color: $secondary-color;
    text-decoration: none;
    padding: 0;
  }
  .suggestions-container {
    .view-brands {
      margin-top: 1.5rem;
      margin-bottom: 0;
      padding: 0;
      color: #3a3a3a;
    }
    p {
      margin-top: 1.5rem;
      font-size: $f20;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      @media screen and (max-width: $screen__m) {
        font-size: 1.3rem;
      }
    }
  }
  #search-results-categories {
    .view-all {
      color: $primary-color;
      text-decoration: underline;
    }
    a {
      margin-top: 1.5rem;
      margin-bottom: 0;
      padding: 0;
      color: $thirdy-color;
      @media screen and (max-width: $screen__m) {
        margin-bottom: 1.5rem;
        margin-top: 0;
        &:first-of-type {
          margin-top: 1.5rem;
          margin-bottom: 0;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .search-results-columns {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .search-item {
    font-size: 1.6rem;
    margin-bottom: 3rem;
    @media screen and (max-width: $screen__m) {
      font-size: 1.3rem;
    }
    .search-item-name {
      margin-bottom: 1rem;
      a {
        margin-bottom: 0;
      }
      span {
        font-size: 1.6rem;
        @media screen and (max-width: $screen__m) {
          font-size: 1.3rem;
        }
        color: $color-orange-dark-nav;
      }
    }
    .search-item-price {
      @media screen and (max-width: $screen__m) {
        > div {
          flex: 0 0 12%;
          margin-right: 1rem;
        }
      }
      span {
        font-size: 1.6rem;
        @media screen and (max-width: $screen__m) {
          font-size: 1.3rem;
        }
        &.search-item-starting-label {
          color: $color-orange-brick;
        }
        &.search-item-starting-price {
          color: $thirdy-color;
        }
      }
    }
  }
  .view-all {
    color: $primary-color;
    text-decoration: underline;
  }
  @media screen and (max-width: $screen__m) {
    padding-left: 1.5rem;
    span,
    a {
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
    }
  }
}
