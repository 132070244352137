.second-column {
  padding: 4rem 0;
  margin-top: 4rem;
  border-top: 0.2rem solid $color-gray-bottom-border;
  &.checkout-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      i {
        top: 2.5rem;
      }
    }
  }
}
.sc-fznJRM {
  width: 100%;
}
#checkoutpaymentmethod {
  .credit-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-bottom: 3rem;
    span {
      border: 1px solid $primary-color;
      min-width: 9rem;
      text-align: center;
      padding: 1.8rem 0;
      border-radius: 0.5rem;
      @extend %gotham-medium;
      color: $primary-color !important;
      margin-bottom: 1.5rem;
      &.active {
        background: $primary-color;
        color: $white !important;
      }
    }
  }
  p {
    font-size: $f16;
    color: $secondary-color;
  }
  .form-check {
    input[type="radio"] {
      width: 2rem;
      // margin-left: 0.5rem;
    }
    input[type="radio"]:checked {
      color: red;
    }
    // label {
    //   padding: 0;
    // }
    &.use-later {
      margin-top: 0;
      label {
        padding: 0;
      }
    }
  }
  input {
    text-transform: lowercase;
  }
  // .box-inner {
  //   margin-top: 0;
  // }
}
.checkout {
  color: $secondary-color !important;
}
#checkoutpaymentmethod {
  h3 {
    padding-bottom: 3rem;
  }
}
.box-inner {
  padding-left: 3rem;
  margin-top: 4rem;
  &.authorize-net {
    margin-top: 2rem;
  }
  .switch-container {
    &.check-button {
      margin: 3rem 0;
    }
  }
  .md-form {
    margin-bottom: 3rem;
  }
  .check-button.form-check {
    margin-top: 3rem;
  }
}
@media screen and (max-width: $screen__xs) {
  .payment-method-container {
    padding: 0;
  }
  .box-inner {
    padding-left: 0;
    margin-top: 4rem;
  }
}
