@import "./mobile/mobile";
@import "./subscribe/subscribe";
.footer-middle-content {
  padding-top: 9rem;
  @extend %block-width-1450;
  .footer-middle-content-inner {
    display: flex;
    flex-wrap: wrap;
    .footer-middle-part {
      .links-false {
        display: none;
      }
      .links-true {
        display: block;
      }
      padding: 0 !important;
      #cmsblock-footer-middle {
        .block-static-block {
          width: 25%;
          .footer-column {
            padding-right: 1rem;
            p.title {
              font-size: $f12;
              text-transform: uppercase;
              color: $primary-color;
              margin-bottom: 2rem;
              @extend %gotham-medium;
            }
          }
        }
      }
      .content {
        ul {
          padding-left: 0;
          li {
            color: $thirdy-color;
            margin-bottom: 1rem;
            line-height: 2.2;
            text-decoration: underline;
            p {
              text-transform: none;
              color: $thirdy-color;
              text-decoration: none;
            }
            a {
              font-size: 1.3rem;
              text-decoration: none;
              color: $thirdy-color;
              padding: 0.5rem 0;
            }
            &.email {
              text-decoration: underline;
            }
            &.address-footer {
              text-decoration: none;
            }
          }
        }
      }
    }
    #mobile-nav-accordion {
      button {
        padding: 0;
        align-items: center;
        font-size: $f12;
        text-transform: uppercase;
        color: $primary-color;
        margin: 0 0 4.5rem 0 !important;
        padding: 0 !important;
        @extend %gotham-medium;
        &.button-clicked-true {
          margin: 0 0 2rem 0 !important;
        }
      }
      .show {
        .content {
          margin-bottom: 2rem;
        }
      }
      .card-body {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 3rem;
        .content {
          ul {
            li {
              line-height: normal;
              margin-bottom: 2.5rem;
            }
          }
        }
        .title {
          display: none;
        }
      }
    }
  }
}
/*Media Queries*/

@include media-breakpoint-down(md) {
  .footer-middle-part {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    text-align: center !important;
    .content {
      margin-bottom: 4.5rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer-middle-content {
    padding-top: 3rem;
  }
}
