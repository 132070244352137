@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.product-item-content {
  margin-top: 5rem;
  flex: 0 1 32%;
  text-align: left;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $screen__m) {
    flex-basis: 50%;
  }
  @media screen and (max-width: $screen__xs) {
    flex-basis: 100%;
  }
  .product-item-image {
    flex: 0 1 40%;
    margin-bottom: 2rem;
    .product-image {
      &.show {
        display: block;
      }
      &.hide {
        display: none;
      }
      min-height: 32rem;
    }
  }
  .product-item-info {
    flex: 0 1 30%;
    .product-name {
      @extend %gotham-book;
      font-size: $f16;
      color: $primary-color;
    }
    margin-bottom: 2rem;
  }
  .product-item-additional-info {
    flex: 0 1 30%;
    display: flex;
    .product-price {
      width: 90%;
      font-size: $f16;
      .starting-at {
        @extend %gotham-book;
        color: $color-orange-brick;
      }
    }
    .product-item-compare {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;
      i {
        &.far {
          color: #858b8b;
        }
        font-size: 1.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .product-wishlist {
      cursor: pointer;
      width: 10%;
      text-align: center;
      svg {
        overflow: visible;
        &.active {
          animation: pulse 1s linear infinite;
        }
      }
    }
  }
}
