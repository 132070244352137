.usp-blocks-wrapper {
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 5.5rem;
  margin-bottom: 4.5rem;
  overflow: hidden;
  .slick-track {
    display: flex;
    width: 100%;
    justify-content: space-between;
    justify-items: center;
    align-items: flex-start;
  }
  .slick-slide {
    width: calc(1 / 4 * 100% - (1 - 1 / 4) * 4%);
    text-align: center;
  }
  h3 {
    color: $thirdy-color;
    text-transform: none;
    margin-bottom: 4.5rem;
    small {
      font-size: $f14;
      line-height: normal;
      @media (max-width: $screen__s) {
        font-size: 1rem;
      }
    }
  }
  p {
    margin-top: 3.5rem;
    color: $secondary-color;
    line-height: 2.2rem;
    padding: 0 2rem;
  }
  .slick-active {
    .slider-custom-dot {
      background: $color-gray-light;
    }
  }
  .slider-custom-dot {
    border: 2px solid $color-gray-light;
  }
  .cmsblock {
    h3 {
      font-size: 2.4rem;
    }
    svg {
      width: 60%;
    }
    img {
      width: 70%;
    }
    text-align: -webkit-center;
  }
}
.usp-container {
  border-top: 0.5rem solid #eef1f0;
}
@media screen and (max-width: $screen__m) {
  .usp-blocks-wrapper {
    max-width: 100%;
    margin: 3.5rem auto;
    overflow: hidden;
    .slick-track {
      display: flex;
      width: 100%;
      flex-wrap: unset;
    }
  }
}
