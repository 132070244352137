.view-order {
  margin-bottom: 25rem;
  .back-button {
    font-size: 2.1rem;
    text-decoration: none;
    i {
      margin-right: 1rem;
    }
  }
  .order-id {
    h2 {
      font-size: $f34;
    }
  }

  .order-status {
    .status-container {
      display: inline-block;
      border: 1px solid $primary-color;
      border-radius: 0.5rem;
      padding: 2rem 3.6rem;
    }
  }

  .order-date {
    color: $color-black;
    font-size: $f16;
  }
  .order-items {
    h3 {
      color: #858b8b;
      font-size: 3rem;
      @extend %gotham-book;
    }
    .dataTables_wrapper {
      width: 100%;
      &.order-information-table {
        .table {
          table-layout: auto;
          margin-bottom: 0;
          th {
            font-size: 2rem;
            color: #858b8b;
          }
          thead {
            &[data-test="table-foot"] {
              display: none;
            }
            tr {
              border-top: 0.2rem solid #ebebeb;
              border-bottom: 0.2rem solid #ebebeb;
              th {
                padding: 2.5rem 0;
                width: 100px;
                &:first-of-type {
                  max-width: 512px;
                  width: 1px;
                }
              }
            }
          }
          tbody {
            tr {
              font-size: $f16;
              border-bottom: 2px solid #ebebeb;

              td {
                font-size: $f16;
                padding: 2.5rem 0;
                a {
                  font-size: $f16;
                  color: #ff6d2d;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    #order-view-summary-footer {
      background-color: #f1f4f6;
      min-height: 17.4rem;
      width: 100%;
      thead {
        &[data-test="datatable-head"] {
          display: none;
        }
      }
      tbody {
        td:first-of-type {
          max-width: 66%;
          width: 66%;
          @media screen and (max-width: 1440px) {
            width: 65%;
          }
          @media screen and (max-width: 1280px) {
            width: 64%;
          }
          @media screen and (max-width: 1140px) {
            width: 62%;
          }
          @media screen and (max-width: 998px) {
            width: 64%;
          }
          @media screen and (max-width: 768px) {
            width: 62%;
          }
          @media screen and (max-width: 555px) {
            width: 55%;
          }
        }
        tr {
          border-bottom: none;
          color: #858b8b;
          &:first-of-type {
            td {
              padding: 0 0;
              &:nth-child(3),
              &:nth-child(4) {
                padding: 2.5rem 0 0;
              }
            }
          }
          &:last-of-type {
            color: #858b8b;
            @extend %gotham-medium;
            padding-right: 10px;
            td {
              &:nth-child(3),
              &:nth-child(4) {
                border-top: 1px solid #858b8b;
              }
              &:nth-child(4) {
                text-align: right;
              }
              &:last-of-type {
                color: #f1f4f6;
              }
            }
          }
        }
      }
    }
  }
  .order-information {
    p {
      color: #858b8b;
    }
    h3 {
      color: #858b8b;
      @extend %gotham-book;
      border-bottom: 0.2rem solid #ebebeb;
      margin-bottom: 3rem;
    }
    h4 {
      color: #858b8b;
      @extend %gotham-medium;
    }
    .credit-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 1.6rem;
      margin-bottom: 3rem;
      align-items: center;
      span {
        border: 1px solid #ff6d2d;
        min-width: 9rem;
        text-align: center;
        padding: 1.8rem 0;
        border-radius: 0.5rem;
        color: #ff6d2d !important;
        margin-right: 2rem;
      }
    }
    .order-information-item {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 576px) {
    .col-sm-6 {
      width: 50%;
    }
  }
  .order-attribute-name {
    @extend %gotham-medium;
  }
  .order-attribute-value {
    color: #3a3a3a;
    a {
      color: #3a3a3a;
      padding-left: 0;
    }
  }
}
