.search-wrapper {
  .md-form {
    position: relative;
    .search-input {
      border-color: $primary-color !important;
      @extend %gotham-medium;
    }
    label {
      @extend %gotham-medium;
      font-size: $f20;
      line-height: 2;
      margin: 0;
      &.active {
        color: $primary-color !important;
        font-size: $f16;
      }
    }
    svg {
      position: absolute;
      right: 0;
      top: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .search-results {
    font-size: $f34;
    @extend %gotham-medium;
    .search-results-inner {
      display: inline-block;
      font-size: $f34;
      @extend %gotham-medium;
    }
    .accordion-item {
      margin: 2.5rem 0;
    }
  }
  .accordion-item {
    .accordion-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      .accordion-title {
        background-color: $color-gray-block;
        color: #444;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
        h3 {
          @extend %gotham-book;
          padding: 3.2rem 2rem;
          color: #b3b9b9;
          text-align: left;
          font-size: $f16;
        }
      }

      .accordion-title:hover {
        &.active {
          background-color: #ccc;
        }
      }

      .rotate {
        transform: rotate(90deg);
      }
    }

    .accordion-icon {
      margin-left: auto;
      transition: transform 0.6s ease;
      margin-right: 3rem;
    }
    .accordion-content {
      background-color: $color-gray-block;
      transition: all ease-in-out 0.4s !important;
      padding: 0 3.4rem;
      &.closed {
        transition: all ease-in-out 0.4s !important;
        max-height: 0;
        overflow: auto;
      }
      &.open {
        max-height: 50rem;
        overflow: auto;
        margin-top: 3rem;
      }
      .accordion-text {
        font-weight: 400;
        font-size: 1.4rem;
        padding: 2rem;
        h5 {
          font-size: $f21;
          color: $thirdy-color;
          line-height: 3.6rem;
          a {
            padding-left: 1rem;
          }
        }
      }
    }
  }
  .assembly-inner-item-container {
    display: flex;
    background-color: $color-gray-block;
    text-align: left;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    border-bottom: 0.2rem solid $color-gray-bottom-border;
    padding: 2rem 0;
    .assembly-item-content {
      color: $secondary-color;
      @extend %gotham-medium;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
      }
      &.name {
        flex: 0 0 48%;
        font-size: 2rem;
        margin-right: 2%;
      }

      &.video,
      &.pdf {
        flex: 0 0 25%;
        a {
          color: $secondary-color;
          text-decoration: none;
          padding: 0;
          font-size: $f14;
          vertical-align: middle;
        }
      }
      .item {
        padding-left: 2.5rem;
      }
    }
  }
  .search-box {
    margin-top: 4rem;
  }
  .no-results {
    background-color: #f1f4f6;
    color: #444;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    min-height: 10rem;
    line-height: 10rem;
    padding: 0 2rem;
    margin: 2.5rem 0;
    background-color: $color-gray-block;
    h3 {
      @extend %gotham-book;
      padding: 3.2rem 2rem;
      color: #b3b9b9;
      text-align: left;
      font-size: $f16;
    }
  }
}
@media screen and (max-width: $screen__m) {
  .container-fluid {
    &.assembly-page {
      .search-wrapper {
        .search-results {
          font-size: $f14;
          .search-results-inner {
            display: inline-block;
            font-size: $f14;
          }
        }
        padding: 0;
        .assembly-inner-item-container {
          display: flex;
          text-align: left;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          .assembly-item-content {
            color: $secondary-color;
            position: relative;
            svg {
              position: absolute;
              top: 50%;
              bottom: 50%;
              transform: translate(-50%, -50%);
            }
            &.name {
              flex: 0 0 100%;
              font-size: $f16;
              margin-bottom: 1rem;
            }

            &.video,
            &.pdf {
              flex: 0 0 45%;
              a {
                color: $secondary-color;
                text-decoration: none;
                padding: 0;
              }
            }
            .item {
              padding-left: 2.5rem;
            }
          }
        }
        .accordion-item {
          margin-top: 1.5rem;
          .accordion-section {
            .accordion-title {
              h3 {
                font-size: $f20;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .container-fluid {
    &.assembly-page {
      margin-bottom: 0;
      .search-wrapper {
        .assembly-tab {
          .accordion-item {
            .accordion-section {
              .accordion-content {
                padding: 0 2.5rem;
              }
              .accordion-title {
                h3 {
                  font-size: $f20;
                }
              }
            }
          }
        }
      }
    }
  }
}
