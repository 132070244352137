@import "./checkoutOrderReview/checkoutOrderReview";
@import "./checkoutPaymentMethod/checkoutPaymentMethod";
@import "./checkoutProcess/checkoutProcess";
@import "./checkoutShippingMethod/checkoutShippingMethod";
@import "./checkoutAddresses/checkoutAddresses";
@import "./crossell/crossell";
@import "./checkoutLogin/checkoutLogin";
@import "./order-success/order_success";
@import "./checkoutMobileComponents/orderSummary/mobileOrderSummary";
.checkout {
  max-height: 100%;
  min-height: calc(100vh - 580px);
  h2 {
    font-size: $f34;
    line-height: 3.6rem;
  }
  .step {
    margin-bottom: 6rem;
    @media screen and (max-width: "768px") {
      margin-bottom: 2.5rem;
    }
  }
  .discount-caret {
    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .checkout-inner {
    flex-direction: column;
  }
}
@media screen and (max-width: $screen__xs) {
  .checkout {
    .checkout-steps {
      margin-bottom: 9rem;
    }
    h3 {
      font-size: 2.4rem;
      padding-bottom: 3rem;
    }
  }
}
