.shop-look {
  .shop-look-container {
    max-width: 1800px;
    margin: 5rem auto 3rem auto;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    z-index: 0;
    .shop-look-text {
      z-index: 0;
      flex: 1 0 65%;
      p {
        color: $secondary-color;
        font-size: $f21;
      }
    }
    .shop-look-btn {
      text-align: right;
      flex: 0 1 35%;
      padding: 2rem 0;

      a {
        text-decoration: none;
      }
      button {
        max-width: 33rem;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .shop-look {
    .shop-look-container {
      .shop-look-text {
        h1 {
          font-size: 4.8rem;
          line-height: 5rem;
        }
      }
      .box-wrapper {
        flex-direction: column;
        text-align: center;
        .shop-look-btn {
          text-align: center;
          flex: none;
          .btn {
            width: 30rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .shop-look {
    .shop-look-container {
      .shop-look-text {
        h1 {
          max-width: 70%;
          margin: 0 auto;
        }
        p {
          font-size: $f16;
          line-height: 3.6rem;
        }
      }
    }
  }
}
