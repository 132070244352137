#checkout-email-container {
  .check-button.form-check {
    margin-bottom: 2.7rem;
    padding-left: 2.2rem;
  }
  p {
    padding-left: 4.5rem;
    font-size: $f16;
    color: $secondary-color;
    @extend %gotham-medium;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 997px) {
      text-align: left;
      font-size: 1.4rem;
    }
  }
  .md-form {
    width: 100%;
    margin-top: 2rem;
    padding-left: 4.5rem;

    input {
      font-size: 1.9rem;
      margin-bottom: 2rem;
    }
    label {
      font-size: 1.9rem;
      left: 4.5rem;
      &.active {
        top: 0.2rem;
      }
    }
  }
  .login-shown {
    margin-bottom: 3rem;
    .form-control {
      margin-bottom: 1rem;
    }
    .md-form {
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #checkout-email-container {
    p {
      margin-bottom: 1rem;
    }
  }
}
