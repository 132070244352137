@import "./b2bStepOne/registerStepOne";
@import "./b2bStepTwo/registerStepTwo";
@import "./b2bStepThree/registerStepThree";
@import "./thankYouPage/thankYouPage";
.b2b-banner {
  object-position: center;
}
#trade-register-B2B {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  padding-top: 0.5rem;
  h2 {
    font-size: 3.4rem;
  }
  #title-holder {
    max-width: 141rem;
    width: 100%;
    .title-decription-block {
      display: flex;
      > div {
        padding: 0;
      }
      .description {
        background: $color-gray-block;
        padding: 4rem;

        p {
          color: $thirdy-color;
          font-size: 2rem;
          line-height: 3.2rem;
          &:first-of-type {
            margin-bottom: 2.5rem;
          }
        }
      }
    }
  }
  .b2b-form {
    padding-left: 10rem;
    padding-right: 10rem;
    .browse-block {
      .md-form {
        &.default-input-field-container {
          margin-bottom: 0;
        }
        overflow: hidden;
      }
    }
    @media (max-width: 1340px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .step-icon-content {
    display: flex;
    justify-content: center;
    figure {
      img {
        width: 10rem;
        height: 10rem;
        @media screen and (max-width: 500px) {
          width: 7rem;
          height: 7rem;
        }
      }

      &.padding-right {
        padding-right: 14.5rem;
        @media screen and (max-width: 769px) {
          padding-right: 5rem;
        }
        @media screen and (max-width: $screen__xs) {
          padding-right: 2rem;
        }
      }
      .figure-caption {
        font-size: $f14;
        &.text-orange {
          color: $primary-color;
        }
        @media screen and (max-width: 500px) {
          width: 90%;
          text-align: center;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  input {
    font-size: 2rem;
    padding: 1.5rem 2.2rem;
    &.specify-input-field {
      padding: 0 2.2rem !important;
      font-size: 1.4rem;
    }
    &.validation-error {
      border-color: red !important;
    }
    &::placeholder {
      font-weight: 400;
    }
  }

  .figure-padding-top {
    padding-top: 1rem !important;
    text-align: center;
    letter-spacing: 0;
    opacity: 1;
  }
  .step-image-padding {
    padding-top: 4rem;
    @media screen and (max-width: 500px) {
      .img-block {
        display: flex;
        justify-content: center;
      }
    }
  }
  .stepper-enter-done {
    padding-top: 2rem;
    opacity: 1;
    transition: all 500ms ease-in-out;
    max-height: 230rem;
    overflow: hidden;
  }
  .stepper-enter {
    transition: all 500ms ease-in-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  .stepper-enter-active {
    transition: all 500ms ease-in-out;
    opacity: 1;
    transition-delay: 300ms;
    max-height: 0;
    overflow: hidden;
    position: relative;
  }
  .stepper-exit-done {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
  }
  .stepper-exit {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
  }
  .stepper-exit-active {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
    transition-delay: 150ms;
    position: relative;
  }
}
@media (max-width: 1140px) {
  #trade-register-B2B {
    .b2b-form {
      .browse-block {
        flex-direction: column;
        margin-bottom: 2rem;
        > div {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
        div.browse-btn {
          text-align: left !important;
        }
      }
    }
  }
}
@media (max-width: $screen__s) {
  #trade-register-B2B {
    #title-holder {
      .title-decription-block {
        margin-bottom: 3rem;
        .description {
          flex-direction: column;
          padding: 2.5rem;
          p {
            font-size: $f16;
            width: 100%;
            line-height: 2.1rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
@media (max-width: $screen__xs) {
  #trade-register-B2B {
    #trade-register-step-two {
      &.stepper-enter-done {
        overflow: visible;
        .grey-text {
          input.validation-error#email{
            margin-top: 3.5rem;
            padding: 2rem !important;
          }
          input.validation-error#password  {
            margin-top: 5rem;
            padding: 2rem !important;
          }
          input.validation-error#email  ~ label,
          input.validation-error#password  ~ label {
            top: 0 !important;
          }
          input.validation-error ~ label.password.active,
          input.validation-error ~ label.email.active {            
              white-space: normal;
              transform: translateY(-5rem) translateX(-1.6rem) scale(0.8) !important;
            }
            input.validation-error ~ label.password,
            input.validation-error ~ label.email{            
              white-space: normal;  
            }
        }
      }
    }
    .b2b-form {
      .browse-block {
        div.browse-btn {
          button {
            max-width: 100%;
          }
        }
      }
    }
  }
}
