.product-page-container {
  .details-container {
    margin-bottom: 14rem;
    .details-title-container {
      background-color: $color-gray-block;
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        text-align: center;
        padding: 4rem;
      }
      h1 {
        font-size: $f64;
        line-height: 7.2rem;
        color: $primary-color;
        @extend %gotham-medium;
      }
      h4 {
        @extend %gotham-book;
        color: #5c5c5c;
        font-size: 2.1rem;
      }
    }
    .details-image-container {
      .hotspotContentContainer {
        .view {
          overflow: visible;
        }
        .img-slider {
          max-height: 100%;
          height: 50rem;
          max-width: 100%;
          object-fit: unset;
        }
        .hotSpot.hotspot-active {
          .card {
            background: rgba($white, 95%) !important;
            border: 0.2rem solid $primary-color !important;
            .card-body {
              color: $secondary-color;
              font-size: 1.7rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
}
/*Media Queries*/

@media screen and (max-width: $screen__xs) {
  .details-container {
    margin-bottom: 5rem;
    .details-title-container {
      h1 {
        font-size: $f34;
        line-height: 3.8rem;
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
    .details-image-container {
      .hotspotContentContainer {
        .img-slider {
          height: 30rem;
        }
      }
    }
  }
}
