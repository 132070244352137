@import "~app/style/app";
@mixin noShadow {
  box-shadow: none;
}
#mobile-nav-accordion {
  button {
    &.orange-button {
      max-width: 100%;
      &.outline {
        background-color: transparent !important;
        a {
          color: $primary-color !important;
        }
      }
    }
    i {
      color: $color-gray-light;
      &:before {
        font-size: 1.85rem;
      }
    }
  }
  ul {
    padding: 0;
    p {
      color: $thirdy-color;
    }
    a {
      color: $thirdy-color;
    }
  }
  .no-shadow {
    @include noShadow();
  }
  .card {
    @include noShadow();
  }
}
