#toolbar {
  .search-result-toolbar {
    display: flex;
    align-items: center;
    .sort-control {
      width: 100%;
    }
    .search-product-number {
      color: $color-gray-light;
      font-size: $f20;
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  #toolbar {
    .search-result-toolbar {
      flex-direction: column-reverse;
      .search-product-number {
        margin-bottom: 2rem;
      }
    }
  }
}
