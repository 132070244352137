@import "./checkoutReviewItem/checkoutReviewItem";
.order-summary-container {
  background-color: $color-gray-block;
  .order-summary-top-content {
    margin: 1rem 0 2rem;
    display: inline-block;
  }
  .order-summary-qty {
    color: $thirdy-color;
  }
  .order-items-review {
    max-height: 45rem;
    overflow-y: auto;
  }
}
.order-summary-subtotal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  > div {
    font-size: $f16;
    margin: 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    &:last-child {
      padding-bottom: 3rem;
      border-bottom: 1px solid;
    }
  }
  .discount-col {
    padding: 0;
  }
}
.order-summary-total {
  margin: 1.5rem 1rem;
  padding-bottom: 3rem 1.5rem;
  > div {
    display: flex;
    justify-content: space-between;
    font-size: $f16;
    @extend %gotham-medium;
  }
}

.checkout-inner {
  .discount-code {
    .discount-caret {
      .fa {
        font-size: 2.4rem;
      }
    }
  }
}
