.footer-bottom-content {
  background-color: $color-orange-footer;
  .footer-bottom-content-inner {
    @extend %block-width-1450;
    > div {
      padding: 0 !important;
    }
    p {
      color: $white;
    }
    .footer-bottom-links {
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      div.left,
      div.right {
        a {
          color: $white;
        }
      }
      div.left {
        display: flex;
        > div {
          margin-right: 3.5rem;
        }
      }
      div.right {
        display: flex;
        > div {
          &:not(:last-child) {
            margin-right: 4rem;
          }
        }
      }
    }
  }
}

@media (max-width: $screen__l) {
  .footer-bottom-content {
    .footer-bottom-content-inner {
      .footer-bottom-links {
        div.left {
          > div {
            margin-right: 0;
          }
        }
        div.right {
          > div {
            &:not(:last-child) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $screen__s) {
  .footer-bottom-content {
    .footer-bottom-content-inner {
      .footer-bottom-links {
        div.left,
        div.right {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 50%;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .footer-bottom-content {
    .footer-bottom-content-inner {
      .footer-bottom-links {
        margin-top: 3rem;
        div.left,
        div.right {
          > div {
            margin-right: 0 !important;
            margin-bottom: 3rem;
          }
        }
        div.right {
          width: 100%;
          flex-direction: row;
          div {
            width: 50%;
            a {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
