@import "./toggle-menu/toggle-menu";
@mixin topAnimation() {
  transition: top 0.5s ease-in-out, padding 0.5s ease-in-out;
  transition-property: top, padding;
  transition-duration: 0.5s, 0.5s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0.75s, 0.75s;
}
@mixin topAnimationSlow() {
  transition: top 0.75s ease-in-out, padding 0.75s ease-in-out;
  transition-property: top, padding;
  transition-duration: 0.75s, 0.75s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
}

@mixin fixedPosition() {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin flex10() {
  flex: 1 0 60%;
  &:first-of-type {
    flex: 1 0 10%;
    margin-left: 2.2rem;
  }
  &:last-of-type {
    flex: 1 0 10%;
    margin-right: 3.2rem;
    height: 2.4rem;
    width: 2.4rem;
  }
}

#mobile-header {
  height: 8rem;
  z-index: 1001 !important;
  #mobile-navigation-container {
    background-color: white;
    z-index: 10;
    > div {
      @include flex10();
      &#logo-container {
        text-align: center;
        position: relative;
        #duc-duc-letter-logo {
          @include topAnimationSlow();
          top: -15rem;
          position: absolute !important;
        }
        #duc-duc-logo {
          @include topAnimation();
          top: 0.9rem;
          z-index: 3;
          position: absolute !important;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          @media screen and(max-width: 400px) {
            top: 0.6rem;
          }
          .navbar-brand {
            top: -3rem;
            z-index: 2;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: relative;
            svg {
              width: 100%;
              height: 7.7rem;
              margin: 0 auto;
              left: 0;
              right: 0;
              top: 0;
              z-index: 10;
            }
          }
          #logo-shape {
            text-align: center;
            top: 2.3rem;
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            &:before {
              top: 0;
              left: 0;
              content: "";
              position: absolute;
              width: 100%;
              height: 0.5rem;
              background-color: white;
            }
            svg {
              position: absolute;
              left: 0;
              right: 0;
              width: 100%;
              // @media screen and(max-width: 768px) {
              //   left: -4rem;
              // }
            }
          }
        }
      }
    }
  }
  #mobile-navigation {
    @include fixedPosition();
    transform: translateY(-100vh);
    background-color: white;
    transition: all 0.5s ease-in-out;
    padding-top: 16rem;
    .show.navbar-collapse {
      ul.navbar-nav  {
        >a {
          text-align: center;
          color: $primary-color;
          border-bottom: 0.1rem solid $color-gray-light;
          padding: 1.1rem 0 !important;
          @extend %gotham-medium;
        }
      }
    }
    &.is-open {
      transform: translateY(0);
      overflow: auto;
    }
    .dropdown-bottom-links {
      padding-top: 3rem;
      width: 80%;
      margin: 0 auto;
      .bottom-links-part {
        text-align: center;
        padding: 0;
        width: 33.3%;
        .nav-link {
          padding: 0;
        }
        svg {
          margin-bottom: 1rem;
          width: 5rem;
          height: 5rem;
          @media (max-width: $screen__xs) {
            width: 3rem;
            height: 3rem;
          }
        }
        a {
          margin-right: 0 !important;
          span {
            @extend %gotham-medium;
            font-size: $f12;
          }
        }
      }
    }
    .dropdown {
      .toggle-menu-item {
        .toggle-dropdown {
          li {
            a {
              font-size: $f14;
            }
          }
        }
      }
    }
    .dropdown-menu-footer-links {
      margin-top: 3rem;
      padding-top: 3rem;
      border-top: 0.1rem solid $color-gray-light;
      .dropdown-menu-footer-links-content {
        .menu-footer-links-part {
          a {
            padding: 0;
            margin-bottom: 2.5rem;
            span {
              color: $color-gray-light;
              text-transform: none;
            }
          }
        }
      }
    }
    .search-icon {
      svg {
        width: 3rem;
        height: 3rem;
        overflow: visible;
      }
    }
  }

  .hamburger-menu {
    z-index: 10;
    padding: 1rem;
    // height: 2rem;
    label {
      padding: 0;
    }
  }
  &.top-nav-collapse {
    #mobile-navigation-container {
      > div {
        &#logo-container {
          #duc-duc-logo {
            @include topAnimationSlow();
            top: -15rem;
          }
          #duc-duc-letter-logo {
            @include topAnimation();
            top: -2rem;
          }
        }
      }
    }
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .toggle-dropdown {
    transition: max-height 0.2s ease-in-out;
    max-height: 0;
    background-color: #eef1f0;
    overflow: hidden;
    &.not-active {
      max-height: 0;
      transition: max-height 0.2s ease-in-out;
    }
    &.active {
      max-height: 1000px;
      transition: max-height 0.2s ease-in-out;
      padding-top: 1.5rem !important;
    }
  }
  .dropdown {
    text-align: center;

    .dropdown-toggle {
      &.static-link {
        border-bottom: 0.1rem solid $color-gray-light;
        .not-active {
          border-bottom: 0 solid transparent;
        }
        a {
          color: $secondary-color;
          margin: 0 !important;
          padding: 0.6rem !important;
          text-transform: uppercase !important;
        }
      }
      &.mobile-account-links {
        a {
          @extend %gotham-medium;
          color: $primary-color;
        }
      }
    }
  }
  .bottom-links-part {
    svg {
      width: 50%;
    }
  }
  #navigation-menu-toggles {
    .menu-toggle {
      flex: 0 0 48%;
      font-size: 1.3rem;
      background-color: rgba(238, 241, 240, 0.5);
      padding: 1.5rem 0;
      text-transform: uppercase;
      align-items: center;
      display: flex;
      justify-content: center;

      &.active {
        background-color: #eef1f0;
      }
      &.not-active {
        background-color: rgba(238, 241, 240, 0.5);
      }
      p {
        text-transform: uppercase;
        color: $primary-color;
      }
    }
    &.signed-in {
      .menu-toggle {
        flex: 0 0 32%;
      }
    }
  }
  div {
    &.toggle-enter-done {
      opacity: 1;
      max-height: 60rem;
      transition: all 300ms ease-in;
      transition-delay: 1s;
      overflow: hidden;
    }
    &.toggle-enter {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 300ms ease-in;
    }
    &.toggle-enter-active {
      opacity: 1;
      max-height: 60rem;
      transition: all 300ms ease-in;
      transition-delay: 1s;
      overflow: hidden;
    }
    &.toggle-exit {
      opacity: 1;
      max-height: 60rem;
      overflow: hidden;
    }
    &.toggle-exit-active {
      opacity: 0;
      max-height: 0;
      transition: all 300ms ease-in;
      overflow: hidden;
    }
  }
}
