@import "./dropdown/dropdown";
@import "./colorPicker/colorPicker";
@import "./field/field";
@import "./storagePicker/storagePicker";
@import "./checkbox/checkbox";

.option-container {
  display: flex;
  align-items: center;
  span.option-title {
    @extend %gotham-medium;
    font-size: $f16;
    color: $secondary-color;
  }

  .grey-text {
    color: $secondary-color;
  }

  button.btn {
    background-color: transparent !important;
    border: 1.5px solid $color-gray-light;
    box-shadow: none;
    color: $thirdy-color;
    text-align: left;
    font-size: 1.4rem;
    vertical-align: middle;
    @extend %gotham-book;
    padding: 1.5rem !important;
    &.floating-right-arrow {
      i {
        float: right;
        font-size: 1.6rem;
        color: $primary-color;
      }
    }
    &:hover {
      border-color: $primary-color;
    }
  }
}
