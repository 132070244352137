.error-page {
    h3 {
      padding-bottom: 4rem;
    }
    h4 {
      text-transform: none;
      padding-bottom: 1rem;
      color: $secondary-color;
      @extend %gotham-medium;
    }
    p {
      color:$secondary-color;
    }
    .error-page-text {
        border-bottom: 0.2rem solid $color-gray-bottom-border;
    }
    ul.custom-list {
        margin: 1.5rem 0;
        padding-left: 0;
        li {
            font-size: $f14;
            color: $secondary-color;
            @extend %gotham-book;
            &:before {
              display: none;
            }
        }
    }
    .error-page-buttons {
      margin: 4rem 0 10rem 0;
      flex-direction: row;
      display: flex;
      a {
        max-width: 35rem;
  
        button {
          max-width: 33rem;
          &.account-btn {
            border: 0.15rem solid $primary-color !important;
          }
        }
        &:first-child {
          margin-right: 2rem;
        }
      }
    }
  }
  @media screen and (max-width: $screen__s) {
    .error-page {
      h3 {
        font-size: $f34;
        line-height: 3.6rem;
      }
      h4 {
        font-size: $f16;
      }
    }
  }
  @media screen and (max-width: $screen__xs) {
    .error-page {
      .error-page-buttons {
        margin: 4rem 0 10rem 0;
        flex-direction: column;
        a {
          &:first-child {
            margin-right: 0;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
  