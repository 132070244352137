.usp-contact {
  max-width: 110rem;
  margin: 5rem auto;
  .usp-contact-wrapper {
    display: flex;
    justify-content: space-between;
    text-align: center;
    > div {
      width: 100%;
      a {
        text-decoration: none;
      }
      p,
      button {
        margin: 0 0 2rem 0;
        max-width: 33rem;
      }
    }
    .slick-list {
      margin: 0 -1.5rem;
      overflow: visible;
      .slick-track {
        overflow: visible;
      }
      .slick-slide {
        padding: 0 1.5rem;

        h3 {
          font-size: $f34;
          line-height: 3.6rem;
        }
        p {
          color: $secondary-color;
          padding: 0 4rem;
          margin-top: 4rem;
          &.text-orange {
            margin-top: 0;
            color: $primary-color;
            @extend %gotham-medium;
          }
        }
        svg {
          min-height: 10rem;
        }
      }
    }
    .slick-active {
      .slider-custom-dot {
        background: $color-gray-light;
      }
    }
    .slider-custom-dot {
      border: 0.2rem solid $color-gray-light;
    }
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__s) {
  .usp-contact {
    max-width: 100%;
    margin: 5rem auto;
    .usp-contact-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      > div {
        width: 100%;
        a {
          text-decoration: none;
        }
        button {
          margin: 4rem 0 1.4rem 0;
          max-width: 33rem;
        }
        p {
          max-width: 100%;
        }
        .text-orange {
          color: $primary-color;
        }
      }
      .slick-list {
        margin: 0;
        overflow: hidden;
        .slick-track {
          overflow: hidden;
        }
        .slick-slide {
          padding: 0;
        }
      }
    }
  }
}
