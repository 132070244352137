@import "./shareBtn/shareBtn";

.share-wishlist-container {
  h3 {
    font-size: $f34;
  }
  .share-text-area {
    margin-bottom: 5rem;
  }
  .req-block {
    margin-bottom: 4rem;
    border-bottom: 0.2rem solid $color-gray-bottom-border;
    text-align: right;
    .required-text-block {
      display: inline-flex;
    }
  }
  .validation-error {
    border-color: red !important;
  }
  .validation-error {
    .react-select__control {
      border-color: red !important;
    }
  }
}
@media screen and (max-width: 420px) {
  .share-wishlist-container {
    h3 {
      &.share-info {
        padding-bottom: 4rem;
      }
    }
    .share-text-area.email-addresses {
      textarea.validation-error ~ label.active {
        top: 0.7rem;
      }
      textarea ~ label.active {
        top: -1.8rem;
      }
      label {
        white-space: normal;
      }
    }
  }
}