@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.minicart-wrapper {
  .minicart-body {
    padding: 0 2.2rem;
    .minicart-item {
      display: flex;
      padding-bottom: 1.5rem;
      border-bottom: 0.2rem solid $color-gray-bottom-border;
      margin-top: 1.5rem;
      padding: 2.5rem 0;
      width: 100%;
      &:first-of-type {
        border-top: 0.2rem solid $color-gray-bottom-border;
      }

      .minicart-item-image {
        flex-basis: 9rem;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 1.5rem;
        height: 10rem;
        border: 0.1rem solid;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 8rem;
          height: 8rem;
          display: block;
        }
      }

      .minicart-item-content {
        flex-grow: 1;

        .minicart-item-name {
          text-decoration: none;
          display: block;
          text-align: left;
          font-size: 1.2rem;
          line-height: 1.5rem;
          min-height: 2rem;
        }

        .minicart-sku-remove-container {
          display: flex;
          justify-content: space-between;
          line-height: 1;
          align-items: flex-end;
        }

        .minicart-item-sku {
          font-size: 1.2rem;
          line-height: 1.5rem;
        }

        .minicart-item-remove,
        .minicart-item-edit {
          svg {
            &.active {
              animation: pulse 1s linear infinite;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }

        hr {
          margin: 0.5rem 0 0.5rem;
        }

        .minicart-item-price {
          text-align: left;
          font-size: 1.6rem;
          line-height: 1.9rem;
        }

        .minicart-qty-edit-container {
          display: flex;
          justify-content: space-between;
          .qty-click svg {
            width: 20px;
            height: 20px;
          }
          .quantity-field .input-field {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0 0.5rem;
          }
          .loading-container {
            margin-left: 0.5rem;
          }
        }

        .minicart-item-qty {
          font-size: 1.2rem;
          line-height: 1.5rem;
          span {
            color: $secondary-color;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 600;
            margin-right: 0.5rem;
          }
          .md-form .form-control {
            font-size: 1.2rem;
            min-width: 3rem;
            max-width: 3rem;
            height: 3rem;
            padding: 0 !important;
            margin-left: 1rem;
            text-align: center;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }

        .minicart-item-edit {
          font-size: 1.2rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}
