.shop-slider {
  position: relative;
  .shop-look-slider-content {
    .slick-list {
      margin: 0 -1.5rem;
      overflow: visible;
      .slick-track {
        overflow: visible;
      }
      .slick-slide {
        padding: 0 1.5rem;
        p {
          color: $secondary-color;
          text-align: right;
          font-size: $f12;
          padding-top: 0.5rem;
        }
      }
    }
    .slick-active {
      .slider-custom-dot {
        background: $color-gray-light;
      }
    }
    .slider-custom-dot {
      border: 0.2rem solid $color-gray-light;
    }
  }
  .view {
    overflow: visible;
  }
}
@media screen and (max-width: $screen__s) {
  .shop-slider {
    .shop-look-slider-content {
      .slick-list {
        .slick-slide {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
