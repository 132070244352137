.mobile-cart-items {
  .item-controls {
    margin-top: 2rem;
  }
  @media (max-width: 992px) {
    .item-container {
      h5 {
        font-size: 1.6rem;
        font-family: "GothamMedium";
        &.item-name  {
          font-family: "GothamBook";
        }
      }
    }
    .item-options-container {
      .item-option-name {
        font-size: 1.4rem;
        color: $secondary-color;
      }
      .item-option-value {
        font-size: 1.4rem;
        color: $thirdy-color;
        padding-left: 0;
        margin-left: -0.4rem;
      }
    }
  }
}
