@import "./bottom/bottom";
@import "./middle/middle";
@import "./top/top";

// footer {
//   &.page-footer {
//     .container-fluid {
//       &.compare-list-container {
//         width: 100%;
//         min-height: 0;
//         > .row {
//           margin: 0;
//           > .col {
//             padding: 0;
//           }
//         }
//         &.closed {
//           position: fixed;
//         }
//         position: fixed;
//       }
//     }
//   }
// }

footer {
  &.page-footer {
    .container-fluid {
      &.compare-list-container {
        width: 100%;
        > .row {
          margin: 0;
          > .col {
            padding: 0;
          }
        }
      }
    }
  }
}
