.about-static-page {
  padding-top: 10rem;
  padding-bottom: 10rem;
  @extend %block-width-1450;
  .static-page-top-block {
    .block-with-left-img,
    .block-with-right-img {
      justify-content: space-between;
      .block-part {
        &.block-text {
          width: calc(37% - 2rem);
          padding: 10rem 7rem;
          border: 0.4rem dotted $primary-color;
          text-transform: none;
          h5 {
            font-size: $f34;
            line-height: 4.2rem;
            @extend %gotham-medium;
            color: $primary-color;
            text-transform: uppercase;
            margin-bottom: 3.5rem;
          }
          p {
            color: $secondary-color;
            font-size: $f21;
            line-height: 3.6rem;
          }
          button {
            max-width: 33rem;
          }
        }
        &.block-img {
          width: calc(63% - 2rem);
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &:first-child {
      margin-bottom: 3rem;
    }
  }
}
/*Media Queries*/
@media (max-width: $screen__m) {
  .about-static-page {
    .static-page-top-block {
      .block-with-left-img,
      .block-with-right-img {
        .block-part {
          &.block-text {
            padding: 10rem 2rem;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .about-static-page {
    padding-top: 0;
    padding-bottom: 6rem;
    .static-page-top-block {
      .block-with-left-img,
      .block-with-right-img {
        .block-part {
          &.block-text {
            width: 100%;
            padding: 4rem 2rem;
            text-align: center;
          }
          &.block-img {
            display: none;
          }
        }
      }
      .block-with-right-img {
        flex-direction: column-reverse;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .about-static-page {
    .static-page-top-block {
      .block-with-left-img,
      .block-with-right-img {
        .block-part {
          &.block-text {
            h5 {
              margin-bottom: 2.5rem;
            }
            p {
              font-size: $f16;
              line-height: 2.4rem;
            }
          }
        }
      }
    }
  }
}
