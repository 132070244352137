.product-page-container {
  .modal {
    &.show {
      .modal-dialog {
        padding-top: 13rem;
        &.modal-product-page-pdf {
          .modal-content {
            .modal-body {
              max-width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .product-page-pdf {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1.2;
    span {
      font-size: 1.2rem;
      margin-left: 1rem;
      text-decoration: underline;
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .product-page-container {
    .product-page-pdf {
      margin: 1.5rem 0;
      display: block;
      text-align: right;
    }
  }
}