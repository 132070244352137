#trade-register-B2C {
  .session-messages-inner {
    width: 100%;
    text-align: center;
    .session {
      &.danger {
        svg {
          margin-right: 0.5rem;
          position: relative;
          left: 0;
          top: 0.5rem;
        }
      }
    }
  }
  #title-holder {
    max-width: 100% !important;
    .title-decription-block {
      display: flex;
      margin-bottom: 6rem;
      .col {
        padding: 0;
      }
      h2 {
        font-size: $f34;
        line-height: 3.6rem;
      }
      .description {
        background: $color-gray-block;
        padding: 3rem;
        display: flex;
        p {
          color: $thirdy-color;
          width: 60%;
          font-size: $f20;
          line-height: 3.2rem;
        }
        a {
          font-size: $f20;
          text-align: right;
          width: 40%;
        }
      }
    }
  }
  .create-account-form {
    border-bottom: 0.2rem solid $color-gray-bottom-border;
    margin-bottom: 3rem;
    padding-left: 10rem;
    padding-right: 10rem;
    .grey-text {
      &.right-column {
        margin-bottom: 5rem;
      }
    }
    .switch-and-required {
      display: flex;
      width: 100%;
      div {
        &:nth-child(1) {
          width: 80%;
        }
      }
      .text-right.required-text-block {
        width: 20%;
      }
    }
    .switch-container {
      label {
        font-size: $f14;
        @extend %gotham-book;
        color: $thirdy-color;
        text-transform: none;
        padding: 0;
        a {
          color: $thirdy-color;
          padding-left: 0.5rem;
        }
      }
      &:nth-child(1) {
        margin-bottom: 5rem;
      }
    }
  }
  .account-submit {
    margin-bottom: 10rem;
    a {
      text-align: right;
      .account-submit-btn {
        max-width: 33rem;
        text-align: right;
      }
    }
  }

  .custom-select {
    border: 0.1rem solid $thirdy-color !important;
    border-radius: $border-radius !important;
    height: 5.7rem;
    padding-left: 2rem !important;
    box-sizing: border-box;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @extend %gotham-medium;
    font-size: $f20;
    &::placeholder {
      padding-left: 2rem !important;
      color: $thirdy-color !important;
    }
    option {
      padding-left: 2rem;
      padding-top: 2rem;
      color: $thirdy-color !important;
      font-size: $f20;
      @extend %gotham-medium;
    }
  }

  .span-small {
    text-align: left;
    font: Book 14px/20px Gotham;
    letter-spacing: 0;
    color: $thirdy-color;
    opacity: 1;
  }
  .span-big {
    text-align: left;
    font: Medium 20px/4px Gotham;
    letter-spacing: 0;
    color: $secondary-color;
    opacity: 1;
    @extend %gotham-medium;
  }

  .select-div-container {
    padding-bottom: 4.2rem;
  }
  .how-did-u {
    padding-bottom: 2.5rem;
  }
  .multiselect-how-did-u {
    padding-bottom: 1.7rem;
  }
  .checkbox-container {
    margin-bottom: 0;
  }
  input {
    &.validation-error {
      border: 1px solid red !important;
    }
  }
  span {
    &.validation-error {
      color: red !important;
      a {
        color: red !important;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  #trade-register-B2C {
    .create-account-form {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media (max-width: $screen__s) {
  #trade-register-B2C {
    #title-holder {
      .title-decription-block {
        margin-bottom: 3rem;
        .description {
          flex-direction: column;
          p {
            font-size: $f16;
            width: 100%;
            line-height: 2.1rem;
            margin-bottom: 2rem;
          }
          a {
            width: 100%;
            font-size: $f16;
            text-align: left;
          }
        }
      }
    }
    .create-account-form {
      margin-bottom: 5rem;
      .switch-and-required {
        flex-direction: column;
        div {
          width: 100% !important;
        }
        .required-text-block {
          margin-bottom: 4rem;
        }
      }
    }
    .account-submit {
      margin-bottom: 5rem;
      a {
        text-align: left;
      }
    }
  }
}
@media (max-width: $screen__xs) {
  #trade-register-B2C {
    #title-holder {
      .title-decription-block {
        h2 {
          font-size: $f34;
        }
      }
    }
  }
}
