.listing-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5rem;
  &:after {
    content: "";
    flex: 1 1 100%;
    max-width: 32%;
  }
}
