@import "./options/options";
@import "./wishlist/wishlist";
.addons-padding {
  margin-top: 2rem;
}
.product-information {
  .addons-title,
  .selections-title {
    i {
      font-size: 1.6rem;
    }
    span {
      padding-left: 2rem;
      font-size: 2rem;
      @extend %gotham-book;
      color: $primary-color;
    }
  }

  .row.addon-item-row {
    margin-bottom: 3rem;
  }
  .addons-content {
    padding: 3.5rem 2rem;
    .addon-name {
      font-size: 1.6rem;
      color: $secondary-color;
      @extend %gotham-medium;
    }

    .addon-price {
      font-size: 1.4rem;
      color: $secondary-color;
      @extend %gotham-book;
    }

    > .row > {
      div {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        &.addon-control {
          align-items: space-between;
        }
      }
    }

    .addon-control {
      align-items: space-between;
      button.btn.orange-button.addon-add-to-cart {
        width: 7.6rem;
        height: 4.6rem;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        svg {
          width: 6rem;
          height: 6rem;
          position: absolute;
          left: 0;
          top: -0.5rem;
          right: 0;
          margin: 0 auto;
          rect.a {
            stroke: transparent;
          }
        }
      }
      @media (max-width: 992px) {
        justify-content: flex-end;
      }
    }
  }
  .out-of-stock-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h4 {
      color: $primary-color;
      font-weight: 700;
    }
    p {
      color: $secondary-color;
      font-size: 1.4rem;
    }
  }
  .notify-btn {
    &.btn {
      max-width: 33rem;
      width: 100%;
    }
  }
  .pdp-main-title {
    font-size: $f34;
    line-height: 3.6rem;
  }
  .pdp-sticky-part {
    z-index: 2;
  }
  .price-container {
    @media (max-width: 992px) {
      .price {
        margin-right: 17px; // Intentionally PX
      }
    }
    .price {
      @extend %gotham-book;
      font-size: $f34;
      color: $color-black;
    }
  }

  .qty-container {
    display: flex;
    align-content: center;
    align-items: center;
    @extend %gotham-book;
    font-size: $f16;
    color: $secondary-color;
    text-transform: uppercase;
    .quantity-field {
      @media (max-width: 992px) {
        margin-right: 2rem;
      }
    }
    span {
      @extend %gotham-medium;
    }
    .qty-control-wrapper > div {
      margin-left: 3.5rem;
    }
  }

  .description {
    p {
      color: $secondary-color;
    }
  }

  .ctas {
    button.large {
      svg {
        width: 2.5rem !important;
        height: 2.5rem !important;
      }
      max-width: 22rem;
    }
    button.wishlist-btn {
      margin-left: 4rem;
    }
  }

  .usps {
    @extend %gotham-book;
    font-size: $f14;
    color: $secondary-color;
    flex: auto;
    padding-top: 5rem;
    // border-top: 0.2rem solid $color-gray-bottom-border;
    .usp-item {
      cursor: pointer;
      a {
        color: $secondary-color;
        text-decoration: none;
      }
      svg {
        min-width: 3rem;
        @media (min-width: 992px) {
          margin-right: 1rem;
        }
        .c1,
        .a1,
        path {
          transition: all 0.3s ease-in-out;
        }
      }
      transition: color 0.3s ease-in-out;
      &:hover {
        color: $primary-color;
        svg {
          .c1,
          path {
            fill: $primary-color;
          }
          .a1 {
            stroke: $primary-color;
          }
        }
      }
      &.hover-assembly-true,
      &.hover-tear-true {
        span,
        a {
          color: $primary-color;
        }
      }
    }
    span {
      max-width: 3rem;
      line-height: 1.5rem;
    }
    .modal-backdrop {
      width: 100% !important;
      height: 100% !important;
    }
    .modal-dialog.usps-modal {
      min-width: 90rem;
      p {
        color: $secondary-color;
        font-size: $f14;
        line-height: 2rem;
        text-align: left;
        margin-bottom: 4rem;
      }
      .continue-shopping-wrapper {
        text-align: center;
        button.btn.btn-default {
          max-width: 33rem;
        }
      }

      .modal-content {
        .modal-body {
          max-width: 70rem;
        }
      }
      &.tearsheet-modal {
        padding-top: 5rem;
        h2,
        h4 {
          text-align: center;
        }
        h4 {
          @extend %gotham-book;
          color: $secondary-color;
        }
        .radio-content {
          &:first-child {
            margin-bottom: 5rem;
          }
        }
        .radio-with-input {
          &.first {
            display: flex;
            align-items: center;
            .radio-container {
              margin-bottom: 0;
            }
            .default-input-field-container {
              margin: 3rem 2.5rem;
              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
              }
              input {
                width: 6.1rem;
                height: 6.1rem;
              }
            }
            span {
              font-size: $f20;
              color: $secondary-color;
            }
          }
          &.second {
            display: flex;
            align-items: center;
            .radio-container {
              margin-bottom: 0;
            }
            .default-input-field-container {
              margin: 0 2.5rem;
              input {
                width: 15rem;
                height: 6.1rem;
              }
            }
          }
        }
        .modal-radio-buttons {
          margin: 3.5rem 0;
          .radio-container {
            label {
              @extend %gotham-book;
              padding: 0;
              color: $secondary-color;
            }
            span {
              padding: 0;
            }
          }
        }
        textarea {
          height: 14rem;
        }
        label.modal-text-label {
          font-size: $f16;
        }
        button.btn-outline-default {
          max-width: 15rem;
        }
        button.orange-button {
          max-width: 33rem;
        }
        .modal-buttons {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

/*Media Queries*/
@media only screen and (max-width: 992px) {
  .product-information {
    .ctas {
      button.large {
        max-width: 100%;
        width: 100%;
      }
      button.wishlist-btn {
        margin-top: 3rem;
        margin-left: 0;
      }
    }
    .usps {
      padding-top: 2.5rem !important;
      margin-bottom: 5rem;
      .usp-item {
        span {
          padding-top: 1rem;
        }
      }
      .fa {
        margin-right: 0;
        margin-bottom: 1rem;
      }
      span {
        text-align: center;
        max-width: 100%;
        padding: 0 5%;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .product-information {
    .usps {
      .modal-dialog.usps-modal {
        &.tearsheet-modal {
          padding-top: 10rem;
          .modal-radio-buttons {
            .radio-with-input {
              .default-input-field-container {
                margin-left: 1rem;
                margin-right: 1rem;
              }
              &.first {
                span {
                  padding: 0;
                }
              }
            }
          }
          .modal-buttons {
            flex-direction: column;
            button.btn {
              max-width: 100%;
              font-size: $f20;
              &.btn-outline-default {
                margin-bottom: 3rem;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .product-information {
    .qty-container {
      margin-bottom: 3rem;
      span {
        flex-grow: 1;
      }
      .quantity-field {
        .qty-control-wrapper {
          > div {
            margin-left: 0;
          }
        }
      }
    }
    .usps {
      .modal-dialog.usps-modal {
        .modal-content {
          .modal-body {
            h2 {
              font-size: $f34;
              line-height: 3.6rem;
            }
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 415px) {
  .product-information {
    .addons-content {
      .addon-control {
        margin-bottom: 1rem;
        flex-direction: column;
        .col {
          display: flex;
          justify-content: center;
          &:first-child {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
