#media-gallery-fullscreen {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 8;
  //   overflow: auto;
  &.slide {
    opacity: 0;
    z-index: -1;
    &.slide-enter {
      display: block;
      opacity: 0;
      transform: scale(0.97) translateY(0);
      z-index: 1;
    }
    &.slide-enter.slide-enter-active {
      opacity: 1;
      transform: scale(1) translateY(0);
      transition: opacity 0.5s linear 0.5s, transform 0.5s ease-in-out 0.5s;
      z-index: 1030;
    }
    &.slide-exit-done {
      opacity: 0;
      max-height: 0;
    }
    /* slide exit */
    &.slide-exit {
      opacity: 1;
      transform: scale(1) translateY(0);
      z-index: 1030;
    }
    &.slide-exit.slide-exit-active {
      opacity: 0;
      transform: scale(0.97) translateY(0);
      z-index: 1030;
      transition: opacity 0.5s linear 0.5s, transform 0.5s ease-in-out 0.5s;
    }
    &.slide-exit-done {
      opacity: 0;
      visibility: hidden;
      transform: scale() translateY(0);
      z-index: -1;
    }
    &.slide-enter-done {
      display: block;
      transform: scale(1) translateY(0);
      transition: opacity 0.5s linear 0.5s, transform 0.5s ease-in-out 0.5s;
      opacity: 1;
      z-index: 1030;
    }
    &.first {
      &.slide-exit-active,
      &.slide-exit-done {
        display: none;
      }
    }
  }
  #selected-image {
    position: relative;
    width: 50%;
    height: 75vh;
    margin: auto;
    > div > img {
      height: 75%;
    }
  }
  .controls {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    .btn {
      min-width: 1rem;
      border-radius: 5rem;
    }
  }
}
@media screen and (max-width: $screen__m) and (min-width: 769px) {
  #media-gallery-fullscreen {
    #selected-image {
      height: 65vh;
      img {
        padding-top: 6rem;
        box-sizing: border-box;
      }
    }
  }
}
@media screen and (max-width: $screen__s) and (min-width: 481px) {
  #media-gallery-fullscreen {
    #selected-image {
      height: 55vh;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #media-gallery-fullscreen {
    &.slide {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    #selected-image {
      width: 100%;
      height: auto;
      margin-top: 5rem;
      min-height: 30rem;
    }
  }
}
