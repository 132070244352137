@import "./slide/slide";
.main-slider {
  cursor: pointer;
  overflow: hidden;
  min-height: 78vh;
  width: 100%;
  @media screen and (max-width: 993px) {
    min-height: 90vh;
    max-height: 90vh;
  }
  .imageloader.imageloader-loaded {
    width: 100%;
    img {
      min-height: 78vh;
      @media screen and (max-width: 993px) {
        min-height: 90vh;
        max-height: 90vh;
      }
    }
  }
  .slick-vertical {
    min-height: 78vh;
    width: 100%;
    .slick-list {
      min-height: 78vh;
      width: 100%;
      .slick-track {
        min-height: 78vh;
        width: 100%;
        .slick-slide {
          min-height: 78vh;
          width: 100%;
          > div {
            min-height: 78vh;
            width: 100% !important;
            > div {
              min-height: 78vh;
              width: 100% !important;
              > span {
                min-height: 78vh;
                > img {
                  min-height: 78vh;
                }
              }
            }
          }
          &.slick-active {
            border: transparent !important;
          }
        }
      }
    }
  }
  .slick-dots {
    right: 0;
    bottom: 50%;
    width: auto;
    li {
      width: 4rem;
      height: 4rem;
    }
    .main-dots {
      display: flex !important;
      flex-direction: column !important;
      width: auto;
      .slider-custom-dot {
        margin: 1rem auto;
      }
    }
  }
  .button-main-slider {
    text-decoration: none;
    margin-top: 6rem;
    width: 100%;
    // Black button
    // button {
    //   background-color: #252525 !important;
    //   color: $white !important;
    //   i {
    //     color: $white;
    //   }
    // }
  }
  h1 {
    // Black title
    color: #252525 !important;
    font-size: 10rem;
  }
  p {
    // Black paragraph
    color: #252525 !important;
    font-size: $f30;
    text-transform: none;
  }
  /*One child in slider*/
  &.slider-one-child {
    .rgba-black-light {
      background: transparent;
    }
    img {
      object-fit: cover;
    }
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__m) {
  .main-slider {
    h1 {
      font-size: $f64;
      line-height: 6.2rem;
    }
    p {
      font-size: $f26;
    }
  }
}
@media screen and (max-width: $screen__s) {
  .main-slider {
    h1 {
      font-size: $f48;
      line-height: 5rem;
    }
    p {
      font-size: $f20;
      line-height: 2.8rem;
    }
    a.button-main-slider {
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .main-slider {
    .slick-slider {
      height: 78vh !important;
      .slick-list {
        height: 78vh !important;
        .slick-track {
          height: 78vh !important;
          .slick-slide {
            height: 78vh !important;
            img {
              height: 78vh !important;
            }
          }
        }
      }
    }
  }
  .main-slider {
    .slick-slider {
      width: 100%;
      .slick-list {
        height: 100% !important;
        .slick-track {
          img {
            height: 50rem;
            object-fit: cover;
          }
        }
      }
      .slick-dots {
        bottom: -5rem !important;
        transform: rotate(270deg);
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
      }
    }
    /*One child in slider*/
    &.slider-one-child {
      img {
        object-fit: cover;
        object-position: left;
      }
    }
  }
}
