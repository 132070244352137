.pdp-media-gallery {
  .mediaGallery {
    position: relative;
  }
  #img-material-color-desc {
    position: absolute;
    bottom: 18rem;
    font-size: $f12;
    color: $secondary-color;
    border: 0.15rem solid $color-gray-light;
    border-radius: $border-radius;
    padding: 1.5rem;
  }
  #media-gallery-fullscreen {
    padding-top: 7rem;
    height: 100%;
    #selected-image {
      padding: 3rem;
      box-sizing: border-box;
      border: 0.4rem dotted $primary-color;
      height: 95% !important;
      img {
        max-height: 75vh;
        object-fit: contain;
      }
    }
    .controls {
      top: 5rem;
      right: 20%;
      button.btn {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 1rem !important;
        svg {
          overflow: visible;
        }
      }
    }
    .carousel-thumbnails {
      bottom: 14rem;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .pdp-media-gallery {
    #media-gallery-fullscreen {
      #selected-image {
        img {
          max-height: 40rem;
        }
      }
      .controls {
        top: 3rem;
        right: 19%;
      }
    }
  }
}
@media only screen and (max-width: $screen__s) {
  .pdp-media-gallery {
    #media-gallery-fullscreen {
      #selected-image {
        img {
          max-height: 40rem;
        }
      }
    }
  }
}
@media only screen and (max-width: $screen__xs) {
  .pdp-media-gallery {
    #media-gallery-fullscreen {
      padding-top: 0;
      #selected-image {
        border: none;
        width: 85%;
        height: 60% !important;
        img {
          max-height: 50rem;
        }
      }
      .carousel-thumbnails {
        bottom: 8rem;
      }
      .controls {
        right: 3%;
        top: 4.5rem;
      }
    }
  }
}
