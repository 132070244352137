@import "./aboutUsCustom/aboutUsCustom";
@import "./aboutUsInstagram/aboutUsInstagram";
@import "./aboutUsStatic/aboutUsStatic";
@import "./aboutUsStudioDac/aboutUsStudioDuc";
@import "./aboutUsTop/aboutUsTop";
.about-us-page {
  overflow: hidden;
}
@media screen and (max-width: $screen__xs) {
  .about-us-page {
    a.btn-link {
      button {
        max-width: 100% !important;
      }
    }
  }
}
