@mixin animateForwardMaxHeight() {
  transition: min-height 0.4s ease-in-out, max-height 0.4s ease-in-out;
  transition-property: min-height, max-height;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0, 0;
}
@mixin animateForwardPadding() {
  transition: padding 0.4s ease-in-out;
  transition-property: max-height, padding;
  transition-duration: 0, 0;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0, 0;
}

@import "./shopTheLook-content/shopTheLook-content";
.shop-the-look-page {
  .shop-the-look-tabs-container {
    margin: 3rem 0;
    .shop-the-look-tabs {
      @extend %block-width-1450;
      .row {
        > div {
          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            padding-right: 0;
          }
          @media screen and (max-width: $screen__m) {
            &:first-of-type {
              padding-left: 1.5rem;
            }
            &:last-of-type {
              padding-right: 1.5rem;
            }
            margin-bottom: 1.5rem;
          }
        }
        .tabs-item {
          cursor: pointer;
          border: 0.1rem solid $thirdy-color;
          text-align: center;
          border-radius: 5px;
          // flex: 1 0 22%;
          padding: 2.2rem 0;
          font-size: 2rem;
          transition: all 0.2s ease-in-out;
          font-weight: 700;
          color: $thirdy-color;
          &::after {
            content: "";
            border-radius: 5px;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
          }

          &:hover {
            color: $primary-color;
            border: 0.1rem solid $primary-color;
            box-shadow: inset 0px -5px 0px 0px $primary-color;
          }

          &:hover::after {
            opacity: 1;
          }

          &.active {
            border: 0.1rem solid $primary-color;
            box-shadow: inset 0 -5px 0px 0px $primary-color;
            color: $primary-color;
          }
          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            padding-right: 0;
          }
        }
      }
    }
  }
  .shop-the-look-content {
    .shop-the-look-top {
      @extend %block-width-1450;
      h3 {
        font-size: $f34;
        line-height: 3.6rem;
      }
    }
    .shop-the-look-inner-content {
      overflow: hidden;
      .nav.nav-tabs {
        @extend %block-width-1450;
        .nav-link {
          border: 0.1rem solid $primary-color;
        }
      }
      .shop-the-look-content-container {
        background-color: #f1f4f6;

        .shop-the-look-content {
          @extend %block-width-1450;
          padding: 12rem 0 20rem;
          .shop-the-look-item {
            @include animateForwardPadding();
            a {
              .imageloader {
                img {
                  @include animateForwardMaxHeight();
                  min-height: 45.3rem;
                  max-height: 45.3rem;
                  object-fit: cover;
                  @media screen and (max-width: 1150px) {
                    min-height: 33rem !important;
                    max-height: 33rem !important;
                  }
                  @media screen and (max-width: 575px) {
                    min-height: 45.3rem !important;
                    max-height: 45.3rem !important;
                  }
                }
              }
              h3 {
                @extend %gotham-book;
              }
            }
          }
        }
      }
    }
    .tabs-container {
      .tab-inner-content {
        background-color: $color-gray-block;
        padding: 12rem 0;
        .tab-inner-elements {
          .tab-content {
            .shop-gallery-images {
              @extend %block-width-1450;
              padding-left: 2rem;
              padding-right: 2rem;
              .gallery-block {
                flex: 1 1 25%;
                margin: 3rem;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: $screen__s) {
  .shop-the-look-page {
    .shop-the-look-content {
      .shop-the-look-inner-content {
        .shop-the-look-content-container {
          .shop-the-look-content {
            padding: 3.5rem 0;
          }
        }
      }
      .tabs-container {
        .tab-inner-content {
          padding: 6rem 0;
        }
      }
    }
  }
}
