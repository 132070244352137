/*Microsoft Edge*/
@supports (-ms-ime-align: auto) {
  .login-modal-container {
    .modal.show {
      .modal-dialog {
        .modal-content {
          .modal-body {
            width: 50%;
            @media screen and (max-width: $screen__s) {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
.modal-backdrop {
  background: $white !important;
  opacity: 0.6 !important;
}
.close-btn {
  position: absolute;
  height: 4rem;
  width: 4rem;
  button {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    background-image: url("../../../assets/icon/closeBtn.svg");
    background-repeat: no-repeat;
  }
}
.modal-wrapper {
  .modal-content {
    background: $white 0% 0% no-repeat padding-box !important;
    // outline: 4px dotted $primary-color !important;
    opacity: 1;
  }
}

.forgot-link {
  text-align: left !important;
  text-decoration: underline !important;
  letter-spacing: 0;
  color: $primary-color !important;
  opacity: 1;
}
.custom-input {
  border: 0.1rem solid $thirdy-color !important;
  border-radius: 0.5rem !important;
  padding: 2rem !important;
  box-sizing: border-box;
  &.disabled {
    filter: opacity(0.5);
  }
}

.modal.show {
  .modal-dialog {
    position: relative;
    display: flex;
    margin: 0 auto;
    min-width: 70rem;
    padding-top: 6rem;
    .modal-content {
      margin: 0 auto;
      .modal-header {
        padding: 0;
      }
      .modal-body {
        max-width: 38rem;
        padding: 6rem 1rem;
        margin: 0 auto;
        label {
          font-size: 2rem;
          padding: 0 2rem;
          &.active {
            padding: 0 2rem;
            transform: translateY(-25px);
          }
        }
        input {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          z-index: 9;
          &.validation-error {
            border: 1px solid red !important;
          }
        }
        span {
          color: $primary-color;
          text-transform: none;
        }
        .or-text {
          font-size: 2rem;
          margin: 3rem 0;
        }
        .custom-modal-btn {
          display: flex;
          flex-direction: row;
          margin: 2.5rem 0;
          align-items: center;
          justify-content: space-between;
          label,
          span {
            font-size: $f14 !important;
            color: $thirdy-color !important;
            margin: 0 !important;
          }
          .check-button {
            margin: 0;
          }
        }
        .text-bottom {
          cursor: pointer;
          margin-top: 3rem;
          &.trade-link {
            margin-top: 1.5rem;
            .trade {
              text-decoration: underline;
            }
          }
        }
        .btn-create-account {
          a {
            text-decoration: none;
            button {
              max-width: 33rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .modal.show {
    .modal-dialog {
      min-width: 100% !important;
      padding: 12rem 6rem 6rem 6rem;
    }
  }
}
label {
  font-size: 2rem;
  padding: 0 2rem;
  &.active {
    padding: 0 2rem;
    transform: translateY(-25px);
  }
}
@media screen and (max-width: $screen__xs) {
  .modal.show {
    .modal-dialog {
      min-width: 100% !important;
      padding: 8rem 3rem 3rem 3rem;
      button {
        &.white-button {
          font-size: $f14;
        }
      }
    }
  }
}
