@import "./accordionItem/accordion-item";
.faq-page {
  .accordion-content {
    div {
      p {
        font-size: $f21;
        margin: 3rem 0;
        line-height: 3.2rem;
      }
      ul {
        list-style-type: none;
        li {
          font-size: $f21;
          color: $secondary-color;
          &:before {
            content: "\2022";
            color: $primary-color;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
            vertical-align: middle;
          }
        }
      }
      a {
        display: inline;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .faq-page {
    .accordion-item {
      margin: 0 -3rem;
      .accordion-content {
        padding: 0 3rem;
        div {
          p {
            font-size: $f16;
            margin: 3rem 0;
          }
          ul {
            padding: 0 0 0 2rem;
            list-style-type: none;
            li {
              font-size: $f16;
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
}
