.gallery-block {
  position: relative;
  transition: transform 0.5s;
  span {
    cursor: pointer;
    padding: 0;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      font-size: $f16;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1.5rem 0;
      color: $white;
      background: rgba($primary-color, 0.85);
      text-decoration: none;
      text-align: center;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      -o-transition: opacity 0.5s;
      transition: opacity 0.5s;
    }
  }

  &:hover {
    p {
      opacity: 1;
    }
  }
  .modal.show {
    .gallery-block-modal {
      .modal-content {
        .modal-body {
          max-width: 100%;
          padding: 2rem;
          h4 {
            margin-top: 1rem;
            padding: 2.5rem;
            text-transform: none;
            background-color: $color-gray-block;
          }
        }
      }
    }
  }
}

