.checkout {
  .checkout-email {
    padding-bottom: 1rem;
    border-bottom: 2px solid $color-gray-bottom-border;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
  .customer-info {
    margin-bottom: 2.5rem;
  }
  .space-divider {
    margin-bottom: 6rem;
  }
  .state-field,
  .country-field {
    .react-select__input {
      input {
        width: 0 !important;
      }
    }
  }
  .checkout-column {
    h3 {
      padding-top: 1rem;
    }
  }
  h3 {
    color: $secondary-color;
    font-size: 3rem;
    @extend %gotham-book;
  }
  .checkout-column {
    &.one {
      &:nth-child(2) {
        .customer-info {
          margin-top: 1rem;
        }
      }
      .check-col-one {
        margin-bottom: 4rem;
        label {
          padding: 0;
        }
      }
      .react-select-container {
        margin-bottom: 2.5rem;
      }
      .default-input-field-container {
        &.small {
          input {
            margin: 0 0 2.5rem 0;
            width: 100%;
          }
          label {
            padding: 0;
          }
        }
      }
    }
  }
  .validation-error {
    border-color: red !important;
  }
}
@media screen and (max-width: $screen__xs) {
  .checkout {
    .checkout-email {
      padding-bottom: 4rem;
    }
  }
}
