.options-selector {
  width: 40%;
  height: 100%;
  @media (min-width: 992px) {
    min-width: 75rem;
    max-width: 75rem;
  }
  max-width: 100%;
  min-width: 100%;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 1040;

  border: 1px solid $color-orange-dark-nav;
  background-color: white;
  box-shadow: -10px 0px 6px #0000001f;

  .options-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .selector-header {
      background-color: $color-orange-dark-nav;
      padding: 2.4rem 0;
      border-bottom: 1px solid $color-orange-dark-nav;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        h3 {
          @media (min-width: 992px) {
            font-size: 3.4rem;
          }
          font-size: 2rem;
          @extend %gotham-medium;
          padding-bottom: 0;
          text-align: center;
          color: white;
        }
        i {
          text-align: center;
          vertical-align: middle;
          font-size: 3.5rem;
          color: white;
          cursor: pointer;
        }
      }
      .back-button {
        span {
          color: white;
          text-decoration: underline;
          font-size: 1.6rem;
          @extend %gotham-book;
          cursor: pointer;
        }
        i {
          margin-right: 1.5rem;
        }
      }
    }
    .selector-content {
      flex: 1 1 auto;
      @media (min-width: 992px) {
        padding: 7rem 7rem 0;
      }
      padding: 3rem 3rem 0;
      &.color-selection {
        padding: 3rem 3rem 0;
      }
      overflow-y: scroll;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      > div {
        padding: 4.5rem 4rem 0;
        background-color: $color-gray-background;
      }
      .radio-container .radio {
        background-color: $color-gray-background;
      }
      .view-details-container {
        padding: 4rem;
        .details-background {
          background-color: $color-gray-background;
          padding: 5rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          .color-swatch {
            @media (min-width: 992px) {
              width: 45rem;
              height: 45rem;
            }

            width: 25rem;
            height: 25rem;

            margin-bottom: 4rem;
          }
        }
        .details-size {
          img {
            margin-bottom: 1.5rem;
          }
          h4 {
            padding-bottom: 1rem;
            color: $primary-color;
            @extend %gotham-medium;
          }
          .dimensions {
            margin-bottom: 4rem;
            .dimension-part {
              padding: 0;
            }
          }
        }
      }
    }
    .selector-footer {
      z-index: 2;
      @media (min-width: 992px) {
        padding: 3rem 7rem 3rem;
      }
      padding: 3rem 3.5rem 3rem;
      box-shadow: 0px -3px 6px $color-black-shadow;
      .selected-title {
        color: $secondary-color;
        font-size: 1.6rem;
      }
      .selected-value {
        color: $thirdy-color;
        font-size: 1.6rem;
        vertical-align: middle;
        .color-swatch {
          width: 2.5rem;
          height: 2.5rem;
        }
        .selected-value-text {
          display: inline-block;
          text-align: right;
          vertical-align: super;
        }
      }
      .view-selected-details-container {
        .view-selected {
          font-size: 2rem;
          @extend %gotham-medium;
          text-decoration: underline;
          color: $primary-color;
          cursor: pointer;
        }
        @media (max-width: 992px) {
          width: 100%;
          display: flex;
          justify-content: center;
          .view-selected {
            justify-content: center;
            width: 100%;
            display: flex;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .options-selector {
    .options-container {
      padding-bottom: 8rem;
      .selector-footer {
        padding-bottom: 8rem;
      }
    }
  }
}
