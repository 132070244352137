.storage-container {
  background-color: #eef1f0;
  padding: 30px 0;

  .switch-container {
    .form-check-label {
      padding-left: 0;
      font-size: $f16;
      @extend %gotham-medium;
    }
    &.checked {
      .form-check-label {
        color: $primary-color;
      }
    }
  }
}
