@import "./dropdown/dropdown";
@import "./menu/menu";
@import "./right/right";
@import "./mobile/mobile";
.top-nav-collapse {
  .curve {
    top: 6.4rem;
  }
}
@mixin topAnimation() {
  transition: top 0.5s ease-in-out, padding 0.5s ease-in-out;
  transition-property: top, padding;
  transition-duration: 0.5s, 0.5s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0.75s, 0.75s;
}
@mixin topAnimationSlow() {
  transition: top 0.75s ease-in-out, padding 0.75s ease-in-out;
  transition-property: top, padding;
  transition-duration: 0.75s, 0.75s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
}
header {
  .z-index-overwrite {
    z-index: 3 !important;
  }
  nav {
    &.navbar {
      box-shadow: none;
      &.scrolling-navbar {
        padding: 0;
        &.top-nav-collapse {
          padding: 0;
        }
      }
      padding: 0;
      .container.navbar-container {
        max-width: 95%;
        .nav-items,
        .logo-container {
          padding-right: 0;
          background-color: #fff;
          @media screen and (max-width: 991px) {
            background-color: transparent;
          }
          ul {
            .dropdown {
              padding-top: 1.7rem;
              padding-bottom: 1.7rem;
            }
          }
        }
        .nav-items {
          z-index: 11;
          padding-left: 10rem;
          @media screen and (max-width: 991px) {
            width: auto;
            align-self: flex-start;
          }
        }
        .logo-container {
          z-index: 9;
          padding-top: 1.7rem;
          padding-bottom: 1.7rem;
          @media screen and(max-width: 769px) {
            z-index: 20;
          }
        }
        #duc-duc-letter-logo {
          @include topAnimationSlow();
          top: -15rem;
        }
        #duc-duc-logo {
          @include topAnimation();
          top: 1.7rem;
          position: absolute !important;
          z-index: -1;
          .navbar-brand {
            top: -0.5rem;
            z-index: 6;
            position: absolute;
            margin: 0 auto;
            left: 0;
            right: 0;

            svg {
              width: 100%;
              height: 7.7rem;
              margin: 0 auto;
              left: 0;
              right: 0;
            }
          }
          #logo-shape {
            top: 4.37rem;
            left: -29%;
            z-index: 4;
            &:before {
              top: 0;
              left: 0;
              content: "";
              position: absolute;
              width: 100%;
              height: 0.5rem;
              background-color: $white;
            }
            svg {
              position: relative;
            }
          }
        }
        .hamburger-button__button {
          display: none;
        }
      }
      &.top-nav-collapse {
        .container.navbar-container {
          #duc-duc-logo {
            @include topAnimationSlow();
            top: -15rem;
          }
          #duc-duc-letter-logo {
            @include topAnimation();
            top: 0rem;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  header {
    nav.navbar {
      .container#main-navigation-header {
        display: flex;
        flex-direction: column;
        .row {
          margin: 0;
        }
        #duc-duc-logo {
          #logo-shape {
            top: 5.8rem;
          }
        }

        .menu-items {
          width: 100%;
          .navbar-collapse {
            background: $white;
          }
        }
        .hamburger-button__button {
          display: block;
        }
      }
    }
  }
}
