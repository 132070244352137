@import "./filter/filter";
.filter-title {
  h3 {
    letter-spacing: 0;
    color: $secondary-color;
    text-transform: lowercase;
    font-size: $f30;
    padding-bottom: 3rem;
    line-height: 3.6rem;
    @extend %gotham-book;
  }
}

.filter-list {
  list-style: none;
}
/*Sidebar mobile*/
.sidebar-mobile {
  margin-bottom: 4rem;
  button.btn-default {
    background-color: transparent !important;
    color: $color-orange-modal-border !important;
    @extend %gotham-book;
    border: 0.1rem solid $color-orange-modal-border !important;
    max-width: 100%;
    padding: 1.75rem 1rem !important;
  }
  .overlay {
    min-width: 100% !important;
        min-height: 100% !important;
    #sidebar {
      .filter-title {
        padding: 0;
        .title {
          padding: 1rem 0;
          font-size: $f20;
          color: $white;
          background-color: $color-orange-modal-border;
        }
      }
      .close-btn {
        top: 0;
        bottom: 0;
        margin: auto;
        right: 5%;
        i {
          &::before {
            font-size: 3.5rem;
          }
        }
      }
      .filter-items {
        padding: 0;
        .sidebar-item {
          .sidebar-item-toggle {
            margin: 0;
            border-radius: 0;
            padding: 1.5rem 0;
            background-color: transparent;
            border-bottom: 0.1rem solid $color-gray-light;
            &.open {
              background-color: transparent;
            }
            a {
              padding-left: 3rem;
              i.filter-icon {
                color: $color-gray-light;
                right: 2.5rem;
              }
            }
          }
        }
      }
    }
    .overlay-footer {
      div.overlay-footer-inner {
        position: fixed;
        bottom: 0;
        flex-direction: row;
        display: flex;
        padding: 3rem 1rem;
        width: 100%;
        border: 0.1rem solid $color-orange-modal-border;
        button {
          &.apply-btn {
            max-width: 100%;
            background: $color-orange-modal-border !important;
            color: $white !important;
          }
        }
      }
    }
  }
}
