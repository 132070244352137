.customer-sidebar {
  h3 {
    font-size: 3rem;
  }
}

.mobile-sidebar {
  button.btn {
    box-shadow: none !important;
    width: 100%;
    max-width: none;
    text-align: left;
    background-color: transparent !important;
    color: $color-black !important;
    border: 0.1rem solid $color-black;
    &:hover,
    &:focus {
      background-color: $primary-color !important;
      color: $white !important;
      border-color: transparent;
    }
    > i {
      color: $primary-color;
      float: right;
      display: inline-block;
    }
  }
}

.overlay {
  background-color: $white;
  min-height: 100% !important;
  .mobile-account-menu {
    .mobile-account-menu-item {
      height: 6rem;
      color: black;
      font-size: 2rem;
      @extend %gotham-book;
      &.title {
        background-color: $color-orange-modal-border;
        color: $white;
        font-size: 2rem;
        .close-btn {
          top: 1.1rem;
          right: 6rem;
          i {
            font-size: 3.5rem;
          }
        }
      }
    }
    a {
      @extend %gotham-book;
      text-decoration: none;
      width: 100%;
      &.active {
        background-color: $color-gray-filter;
      }
    }
  }
}
