header {
  nav.navbar {
    .nav-items {
      padding-left: 2.5%;
      width: 100%;
      z-index: 2;
      @media screen and(max-width: 768px) {
        width: 33%;
      }
      .dropdown-toggle {
        &:after {
          display: none;
        }
      }
      .menu-items {
        align-items: center;
        ul {
          .dropdown {
            display: flex;
            align-items: center;
          }
          a {
            padding-left: 0 !important;
            padding-right: 0 !important;
            @extend %gotham-medium;
            font-size: 1.3rem;
            padding: 1rem;
            border-bottom: 0.1rem solid transparent;
            .Kids {
              color: #ff9015;
            }
            .Baby {
              color: #ff9015;
            }
            .Storage {
              color: #ff9015;
            }
            .Study {
              color: #ff9015;
            }
            .Play {
              color: #ff9015;
            }
            &:hover {
              border-bottom: 0.1rem solid $color-orange-dark-nav;
            }
            &:after {
              display: none;
            }
            color: $color-orange-dark-nav;
          }
          div.static-link {
            padding-left: 0;
            padding-right: 0;
            a {
              @extend %gotham-book;
              color: $secondary-color;
              &:hover {
                border-bottom: 0.1rem solid transparent;
              }
            }
          }
        }
      }
    }
    #nav-dropdown-7 {
      a.nav-link {
        color: #858b8b;
        @extend %gotham-book;
        font-weight: 300;
        &:hover {
          border-bottom: 0.1rem solid transparent;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  header {
    nav.navbar {
      .nav-items {
        .menu-items {
          ul {
            a {
              margin-right: 1.5rem !important;
            }
          }
        }
      }
    }
  }
}
