#trade-register-B2B {
  input {
    .validation-error {
      border: 0.1rem solid red !important;
    }
  }

  #trade-register-step-three {
    .col {
      .md-form {
        &:first-of-type {
          margin-top: 2rem;
        }
      }
    }
    .terms-block {
      margin-left: 50%;
      display: flex;
      > div {
        &:nth-child(1) {
          width: 80%;
        }
      }
      .required-text-block {
        width: 20%;
      }
    }
    .validation-error {
      .switch-container {
        label {
          color: red;
          a {
            color: red;
          }
        }
      }
    }
    .switch-container {
      label {
        font-size: $f14;
        @extend %gotham-book;
        color: $thirdy-color;
        text-transform: none;
        padding: 0;
        a {
          color: $thirdy-color;
          padding-left: 0.5rem;
        }
      }
      &:nth-child(1) {
        margin-bottom: 5rem;
      }
    }
    .step-three-buttons {
      border-top: 0.2rem solid $color-gray-bottom-border;
      padding-top: 4rem;
      padding-bottom: 10rem;
      button.step-three-submit {
        max-width: 33rem;
      }
    }
  }
  .state-selection-container {
    margin-bottom: 3rem;
    input {
      margin-left: 0;
      width: 100%;
    }
    label {
      &:not(.active) {
        padding-left: 0;
      }
      &.active {
        margin-left: 0;
      }
    }
    .select2 {
      .react-select-container {
        &.validation-error {
          .react-select__control {
            border: 0.1rem solid red !important;
          }
        }
        .react-select__control {
          border: 0.1rem solid $thirdy-color !important;
          &.react-select__control--is-disabled {
            background-color: $white;

            .react-select__value-container {
              .react-select__placeholder,
              .react-select__single-value {
                color: #495057;
              }
            }
          }
          .react-select__value-container {
            .react-select__placeholder {
              font-size: 2rem;
              color: hsl(0, 0%, 20%);
            }
            .react-select__input {
              input {
                padding: 0.9rem 0 !important;
              }
            }
          }
        }
      }
    }
  }
  #register-step-three {
    .row {
      .col {
        .md-form {
          &:first-of-type {
            margin-top: 3rem;
          }
          &:last-of-type {
            margin-top: 3rem;
          }
        }
      }
    }
  }
}
/*Media Queries*/
@media (max-width: $screen__m) {
  #trade-register-step-three {
    .step-three-check-block {
      > div {
        margin-left: 0 !important;
      }
      .terms-block {
        margin-left: 0 !important;
      }
    }
  }
}
@media (max-width: $screen__s) {
  #trade-register-step-three {
    .step-three-buttons {
      > div {
        > div {
          text-align: left !important;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  #trade-register-B2B {
    #trade-register-step-three {
      .step-three-buttons {
        padding-bottom: 8rem;
      }
    }
  }
}
@media (max-width: $screen__xs) {
  #trade-register-step-three {
    .step-three-check-block {
      .terms-block {
        flex-direction: column;
        > div {
          width: 100% !important;
        }
      }
    }
    .step-three-buttons {
      > div {
        > div {
          button {
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
// #mask-input::-webkit-input-placeholder {
//   font-size: 1.2rem !important;
// }
// .react-select__placeholder,
// .react-select__single-value {
//   font-size: 1.2rem !important;
// }
