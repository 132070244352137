.modal {
  &.show {
    .main-modal {
      min-width: 100%;
      height: 100%;
      padding: 0;
      .modal-content {
        background: rgba($white, 0.85) !important;
        border: none !important;
        height: 100%;
        .modal-body {
          max-width: 100% !important;
          padding: 0;
          min-width: unset;
          margin: 0;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .modal-inner {
            h2,
            span,
            p,
            button {
              color: $white;
            }
            &.one {
              display: inline-flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              flex: 1 0 49%;
              transition: all 0.5s ease-in-out;
              clip-path: circle(49vw at 5% 49%);
              background: $primary-color;
              .modal-one-inner {
                max-width: 51rem;
                svg {
                  margin-bottom: 7rem;
                  width: 15rem;
                  height: 18rem;
                }
                span {
                  font-size: $f26;
                  margin-bottom: 3rem;
                  display: inline-block;
                  &.mom {
                    font-size: 4.8rem;
                    margin: 0;
                    text-decoration: line-through;
                  }
                }
                p {
                  font-size: $f20;
                  line-height: 1.5;
                }
              }
            }
            &.two {
              flex: 1 0 41%;
              display: flex;
              justify-content: center;
              align-items: center;
              .modal-inner-content-two {
                display: flex;
                position: relative;
                align-items: center;
                flex-direction: row;
                margin-right: 2.5rem;
                max-width: 50rem;
                width: 100%;
                .close-btn {
                  position: absolute;
                  right: -8%;
                  top: -50%;
                }
                .md-form {
                  width: 100%;
                  max-width: 33.2rem;
                  flex: 0 0 auto;
                  margin: 0 5% 0 0;
                  input {
                    background: $white;
                    height: auto;
                  }
                }
                a {
                  text-decoration: none;
                  flex: 0 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__l) {
  .modal {
    &.show {
      .main-modal {
        .modal-content {
          .modal-body {
            .modal-inner {
              &.one {
                .modal-one-inner {
                  max-width: 51rem;
                  svg {
                    margin-bottom: 5rem;
                    width: 13rem;
                    height: 15rem;
                  }
                  span {
                    font-size: 2.2rem;
                    margin-bottom: 3rem;
                    display: inline-block;
                  }
                  p {
                    font-size: $f16;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__ml) {
  .modal {
    &.show {
      .main-modal {
        .modal-content {
          .modal-body {
            .modal-inner {
              &.one {
                .modal-one-inner {
                  max-width: 51rem;
                  svg {
                    margin-bottom: 5rem;
                    width: 11rem;
                    height: 13rem;
                  }
                  span {
                    font-size: 2rem;
                    margin-bottom: 3rem;
                    max-width: 40vw;
                    display: inline-block;
                  }
                  p {
                    font-size: 1.4rem;
                    line-height: 1.5;
                  }
                }
              }
              &.two {
                .modal-inner-content-two {
                  justify-content: center;
                  flex-direction: column;
                  .md-form {
                    margin-right: 0;
                    margin-bottom: 1.5rem;
                    label {
                      top: 0;
                    }
                  }
                  .close-btn {
                    top: -25%;
                    right: 7%;
                  }
                  .btn-link {
                    max-width: 33.2rem;
                    button {
                      width: 100%;
                      max-width: 33.2rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  .modal {
    &.show {
      .main-modal {
        padding: 0;
        .modal-content {
          .modal-body {
            .modal-inner {
              &.one {
                flex-direction: column;
                flex: 0 0 100%;

                -webkit-clip-path: circle(65.7% at 50% 1%);
                clip-path: circle(65.7% at 50% 1%);
                .modal-one-inner {
                  max-width: 100%;
                  svg {
                    margin-bottom: 2rem;
                  }
                  h2 {
                    font-size: 3.4rem;
                  }
                  span {
                    font-size: 2rem;
                    margin-bottom: 1.5rem;
                    display: inline-block;
                    &.mom {
                      font-size: 3.4rem;
                      margin: 0;
                      text-decoration: line-through;
                    }
                  }
                  p {
                    font-size: 1.6rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  .modal {
    &.show {
      .main-modal {
        padding: 0;
        .modal-content {
          .modal-body {
            .modal-inner {
              &.one {
                padding-top: 2.5rem;
                justify-content: flex-start;
                .modal-one-inner {
                  max-width: 100%;
                  svg {
                    margin-bottom: 2rem;
                  }
                  h2 {
                    font-size: 3.4rem;
                    line-height: 3.4rem;
                    max-width: 40rem;
                  }
                  span {
                    font-size: 2rem;
                    line-height: 2rem;
                    margin-bottom: 1.5rem;
                    display: inline-block;
                  }
                  p {
                    font-size: 1.6rem;
                    line-height: 1.6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .modal {
    &.show {
      .main-modal {
        padding: 0;
        .modal-content {
          .modal-body {
            .modal-inner {
              &.one {
                padding-top: 2.5rem;
                justify-content: flex-start;
                -webkit-clip-path: circle(82.5% at 50% 1%);
                clip-path: circle(82.5% at 50% 1%);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .modal {
    &.show {
      .main-modal {
        padding: 0;
        .modal-content {
          .modal-body {
            .modal-inner {
              &.one {
                padding-top: 2.5rem;
                justify-content: flex-start;
                -webkit-clip-path: circle(97% at 50% 1%);
                clip-path: circle(97% at 50% 1%);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .modal {
    &.show {
      .main-modal {
        .modal-content {
          .modal-header {
            border: none;
          }
          .modal-body {
            .modal-inner {
              &.one {
                padding-top: 1.5rem;
                justify-content: flex-start;
                -webkit-clip-path: circle(100% at 50% 2%);
                clip-path: circle(100% at 50% 2%);
                .modal-one-inner {
                  h2 {
                    margin-bottom: 0rem;
                    padding-bottom: 1rem;
                    font-size: 2.6rem;
                  }
                  span {
                    font-size: 1.6rem;
                    width: 100%;
                    min-width: 100%;
                    &.mom {
                      font-size: 2.6rem;
                      margin: 0;
                      text-decoration: line-through;
                    }
                  }
                  svg {
                    margin-bottom: 1rem;
                    width: 9rem;
                  }
                }
              }
              &.two {
                .modal-inner-content-two {
                  margin-right: auto;
                  .close-btn {
                    top: -40%;
                    right: 5%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
