#toolbar {
  .sort-control {
    .sort-options {
      .sorter-label {
        color: $secondary-color;
        font-size: $f20;
        vertical-align: middle;
        @extend %gotham-medium;
      }
      .select2 {
        margin-left: 1.5rem;
        width: 25rem;
        display: inline-block;
        .react-select__placeholder {
          color: $color-gray-light;
        }
        .react-select-container {
          .react-select__control {
            &:hover {
              .react-select__placeholder,
              .react-select__single-value {
                color: $thirdy-color;
              }
            }
            &.react-select__control--is-focused {
              border-color: $thirdy-color;
              .react-select__placeholder,
              .react-select__single-value {
                color: $thirdy-color;
              }
            }
          }
        }
      }
      .select-arrow {
        transition: transform 0.2s ease-in-out;
        padding-left: 0.5rem;
        svg {
          width: 2rem;
          cursor: pointer;
          transition: transform 0.2s ease-in-out;
          path,
          line {
            stroke-width: 4;
          }
        }
        &.ASC {
          svg {
            transform: rotate(90deg);
          }
        }
        &.DESC {
          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
  /*Toolbar mobile*/
  .toolbar-mobile {
    margin-bottom: 2rem;
    .show-filters-mobile,
    .sort-options {
      button {
        background-color: transparent !important;
        color: $color-orange-modal-border !important;
        @extend %gotham-book;
        border: 0.1rem solid $color-orange-modal-border !important;
        max-width: 100%;
        padding: 1.75rem 1rem !important;
        &.sort-by-btn {
          border: 0.1rem solid $color-gray-light !important;
          color: $color-gray-light !important;
        }
        &.apply-btn {
          background: $color-orange-modal-border !important;
          color: $white !important;
        }
      }
      .overlay {
        min-width: 100% !important;
        min-height: 100% !important;
        #sidebar {
          .filter-title {
            padding: 0;
            .title {
              padding: 1rem 0;
              font-size: $f20;
              color: $white;
              background-color: $color-orange-modal-border;
            }
            .filter-back {
              position: absolute;
              left: 3rem;
            }
          }
          .close-btn {
            top: 0;
            bottom: 0;
            margin: auto;
            right: 5%;
            i {
              &::before {
                font-size: 3.5rem;
              }
            }
          }
          .sidebar {
            .filter-title {
              h3 {
                display: none;
              }
              .filters {
                .filter {
                  .filter-toggle {
                    border-radius: 0;
                    margin: 0;
                    padding: 1.5rem 0;
                    background-color: transparent;
                    border-bottom: 0.1rem solid $color-gray-light;
                    &.show-true {
                      margin-bottom: 2rem;
                    }
                    h3 {
                      padding-left: 3rem;
                      line-height: normal;
                      display: block;
                      i.filter-icon {
                        right: 2.5rem;
                        &:before {
                          content: "\f0da";
                          color: $color-orange-modal-border;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .overlay-footer {
          div.overlay-footer-inner {
            position: fixed;
            bottom: 0;
            flex-direction: row;
            display: flex;
            padding: 3rem 1rem;
            width: 100%;
            border: 0.1rem solid $color-orange-modal-border;
          }
        }
      }
    }

    .sort-options {
      .sort-options-content {
        .select2.small {
          width: 100%;
          margin-left: 0;
          .react-select__control {
            border: 0.1rem solid $color-gray-light;
            .react-select__value-container {
              display: flex;
              padding: 0;
              justify-content: center;
              .react-select__placeholder {
                @extend %gotham-book;
              }
            }

            .react-select__indicators {
              .react-select__indicator {
                display: none;
              }
            }
          }
        }
        .sort-title {
          margin: 0;
          padding: 1.5rem 0;
          border-bottom: 0.1rem solid $color-gray-light;
          &.show-true {
            margin-bottom: 2rem;
          }
          h3 {
            @extend %gotham-book;
            color: $thirdy-color;
            padding-bottom: 0;
            padding-left: 3rem;
            line-height: normal;
            font-size: $f20;
            display: block;
            i.filter-icon {
              right: 2.5rem;
              &:before {
                content: "\f0da";
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
  .toolbar-mobile-clear {
    text-align: center;
    margin-bottom: 2rem;
    span {
      text-decoration: underline;
      font-size: $f16;
    }
  }
}
/*Media Queries*/
@media (max-width: $screen__s) {
  #toolbar {
    h3.plp-main-title {
      font-size: $f34;
      line-height: 3.6rem;
    }
  }
}
@media (max-width: $screen__xxs) {
  #toolbar {
    .toolbar-mobile {
      .show-filters-mobile,
      .sort-options {
        button {
          padding: 1.75rem 0.5rem !important;
        }
      }
      .show-filters-mobile {
        padding-right: 0.5rem;
      }
      .sort-options {
        padding-left: 0.5rem;
      }
    }
  }
}
