.custom-form {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .custom-input {
    &.validation-error {
      border-color: red !important;
    }
  }
  padding: 7rem 0 12rem 0;
  .block-with-right-img {
    @extend %block-width-1450;
    .block-part {
      padding: 0;
      h2 {
        font-size: $f64;
        line-height: 7.2rem;
      }
      p {
        font-size: $f21;
        line-height: 2.9rem;
        color: #5c5c5c;
      }
      .custom-form-top {
        padding-top: 6rem;
        justify-content: space-between;
        .custom-form-part {
          width: calc(50% - 1.5rem);
          &.custom-form-right {
            .check-button {
              margin-top: 4rem;
              label {
                display: inline;
                @extend %gotham-book;
                font-size: $f14;
                color: $thirdy-color;
                a {
                  color: $thirdy-color;
                  padding-left: 0.5rem;
                }
              }
            }
          }
        }
      }
      .custom-form-bottom {
        .custom-form-button {
          align-items: center;
          .part {
            width: 50%;
            &.left {
              color: $secondary-color;
            }
            &.right {
              text-align: right;
            }
          }
        }
      }
      &.block-img {
        padding-left: 3rem;
        box-sizing: border-box;
      }
    }
  }
}
/*Media Queries*/

@include media-breakpoint-down(md) {
  .custom-form {
    .block-with-right-img {
      flex-direction: column;
      .block-part {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        #title-holder {
          margin: 0;
        }
        &.block-text {
          margin-bottom: 3rem;
        }
        &.block-img {
          display: none;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .custom-form {
    .block-with-right-img {
      .block-part {
        .container {
          max-width: 100%;
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .custom-form {
    padding-bottom: 10rem;
    .block-with-right-img {
      .block-part {
        #title-holder {
          max-width: 75%;
          padding: 0;
        }
        h2 {
          font-size: $f34;
          line-height: 3.6rem;
        }
        .description {
          p {
            font-size: $f16;
          }
        }
        .custom-form-top {
          padding-top: 3rem;
          flex-direction: column;
          .custom-form-part {
            width: 100%;
          }
        }
        .custom-form-bottom {
          .custom-form-button {
            flex-direction: column;
            > div.part {
              width: 100%;
              &.right {
                button {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
