@import "./assemblyInstructionSearch/assemblyInstructionSearch";
@import "./assemblyTabs/assemblyTabs";
@import "./accordionAssembly/accordionAssembly";
@import "./assemblyTitleTab/assemblyTitleTab";
.assembly-page {
  .testSize {
    text-align: center;
    font-size: 52px;
    line-height: 5rem;
    margin-bottom: 2rem;
  }
  margin-bottom: 15rem;
  .search-container {
    border-top: 0.2rem solid $color-gray-bottom-border;
    .col.search-wrapper {
      max-width: 70rem;
      margin: 5rem auto;
      label {
        color: $primary-color;
      }
      .text-center {
        text-transform: none;
        font-size: $f21;
        line-height: 3.6rem;
      }
    }
  }
  .col.assembly-wrapper {
    @extend %block-width-1450;
    .assembly-title-content {
      h4 {
        text-transform: none;
        font-size: $f21;
        line-height: 3.6rem;
      }
    }
  }
  .breadcrumbs {
    @extend %block-width-1450;
  }
}
.assembly-row {
  border-top: 0.2rem solid $color-gray-bottom-border;
}
@media screen and (max-width: $screen__m) {
  .assembly-page {
    .search-container {
      border-top: 2px solid $color-gray-bottom-border;

      .col.search-wrapper {
        max-width: 70rem;
        margin: 5rem auto;
        .text-center {
          text-transform: none;
          font-size: $f16;
        }
      }
    }
    .col.assembly-wrapper {
      .assembly-title-content {
        h4 {
          text-transform: none;
          font-size: $f16;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .assembly-page {
    .search-container {
      .col.search-wrapper {
        max-width: 70rem;
        margin: 5rem auto;
        padding: 0 3rem;
        .md-form {
          label {
            font-size: $f16;
            top: 0.5rem;
          }
        }
        .text-center {
          line-height: 2;
        }
      }
    }
    .col {
      &.assembly-tab-title {
        .assembly-title-content {
          margin-bottom: 4.7rem;
          h2 {
            font-size: 2.8rem;
            max-width: 30rem;
            margin: 3.4rem auto;
          }
          h4 {
            text-transform: none;
            font-size: $f16;
          }
        }
      }
    }
  }
}
