.main-banner {
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: $screen__m) {
    height: 40rem;
  }
}
