@import "./assemblyModal/assemblyModal";

.assembly-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .assembly-tab {
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2%);
  }
  .accordion-item {
    .accordion-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      .accordion-title {
        background-color: $color-gray-block;
        color: #444;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
        h3 {
          @extend %gotham-book;
          padding: 3.2rem 2rem;
          text-align: left;
          text-transform: lowercase;
        }
      }

      .accordion-title:hover {
        &.active {
          background-color: #ccc;
        }
      }

      .rotate {
        transform: rotate(90deg);
      }
    }

    .accordion-icon {
      margin-left: auto;
      transition: transform 0.6s ease;
      margin-right: 3rem;
    }
    .accordion-content {
      background-color: rgba($color-gray-block, 0.5);
      transition: all ease-in-out 0.4s !important;
      padding: 0 3.4rem;
      &.closed {
        transition: all ease-in-out 0.4s !important;
        max-height: 0;
        overflow: auto;
      }
      &.open {
        max-height: 50rem;
        overflow: auto;
        margin-top: 3rem;
      }
      .accordion-text {
        font-weight: 400;
        font-size: 1.4rem;
        padding: 2rem;
        h5 {
          font-size: $f21;
          color: $thirdy-color;
          line-height: 3.6rem;
          a {
            padding-left: 1rem;
          }
        }
      }
    }
  }
  .assembly-inner-item-container {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    border-bottom: 0.2rem solid $color-gray-filter;
    padding: 2rem 0;
    &:last-child {
      border-bottom: none;
    }
    .assembly-item-content {
      color: $secondary-color;
      @extend %gotham-medium;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
      }
      &.name {
        flex: 0 0 40%;
        font-size: 2rem;
        margin-right: 10%;
      }

      &.video,
      &.pdf {
        flex: 0 0 25%;
        a {
          color: $secondary-color;
          text-decoration: none;
          padding: 0;
        }
      }
      .item {
        padding-left: 2.5rem;
      }
    }
  }
}
// MEDIA
@media screen and (max-width: $screen__m) {
  .assembly-wrapper {
    padding: 0;
    .assembly-tab {
      width: 100%;
      .assembly-inner-item-container {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .assembly-item-content {
          color: $secondary-color;
          position: relative;
          svg {
            position: absolute;
            top: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
          }
          &.name {
            flex: 0 0 100%;
            margin-right: 0;
            font-size: $f16;
            margin-bottom: 1rem;
          }

          &.video,
          &.pdf {
            flex: 0 0 45%;
            a {
              color: $secondary-color;
              text-decoration: none;
              padding: 0;
            }
          }
          .item {
            padding-left: 2.5rem;
          }
        }
      }
      .accordion-item {
        .accordion-section {
          .accordion-title {
            h3 {
              font-size: $f26;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__l) {
  .assembly-wrapper {
    .assembly-tab {
      .accordion-item {
        .accordion-section {
          .accordion-content {
            padding: 0 1.5rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .container-fluid {
    &.assembly-page {
      margin-bottom: 0;
      .assembly-wrapper {
        .assembly-tab {
          .accordion-item {
            .accordion-section {
              .accordion-content {
                padding: 0 2.5rem;
              }
              .accordion-title {
                h3 {
                  font-size: $f20;
                }
              }
            }
          }
        }
      }
    }
  }
}
