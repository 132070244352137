.color-option-container {
  @media (min-width: 992px) {
    .left-indent {
      padding-left: 2.2rem !important;
    }
  }

  .row {
    > div {
      padding-bottom: 2rem;
      display: flex;
      align-items: center;
    }

    .color-selection-button {
      cursor: pointer;
      border: 0.15rem solid $color-gray-light;
      padding: 0.8rem;
      border-radius: 0.5rem;
      background-color: transparent;
      color: $thirdy-color;
      display: flex;
      font-size: 1.4rem;
      @extend %gotham-book;
      align-items: center;
      min-height: 5rem;
      &.make-selection-validation {
        border-color: $primary-color;
        text-align: center;
        justify-content: space-around;
      }
      .color-swatch {
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        min-height: 3rem;
      }
      &:hover {
        border-color: $primary-color;
      }
    }
  }
}
.color-swatch {
  cursor: pointer;
  border: 0.1rem solid $secondary-color;
  border-radius: 0.5rem;
  display: inline-block;
  background-position: center;
  margin-right: 1rem;
}

.options-selector {
  .options-container {
    .color-selection {
      .swatch-listing {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
        grid-auto-rows: 1fr;

        &::before {
          content: "";
          width: 0;
          padding-bottom: 100%;
          grid-row: 1 / 1;
          grid-column: 1 / 1;
        }

        > *:first-child {
          grid-row: 1 / 1;
          grid-column: 1 / 1;
        }
      }

      > div {
        background-color: transparent;
        padding: 0;
        .nav {
          border: none;
          display: flex;
          justify-content: center;
          margin-bottom: 1.3rem;
          .nav-item {
            width: 50%;
            height: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            .nav-link {
              border: none;
              padding: 0;
              text-decoration: none;
              width: 100%;
              height: 100%;
              .nav-tab-container {
                width: 100%;
                height: 100%;
                font-size: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $thirdy-color;
                @extend %gotham-medium;
                border: 0.1rem solid $thirdy-color;
                border-radius: 0.5rem;
                &:hover {
                  border-color: $primary-color;
                  color: $primary-color;
                }
              }
              &.activated {
                .nav-tab-container {
                  border-width: 0.2rem;
                  padding-top: 0.2rem;
                  border-bottom-width: 0.4rem;
                  border-color: $primary-color;
                  color: $primary-color;
                }
              }
            }
            &:first-child {
              .nav-link {
                padding-right: 1.6rem;
              }
            }
            &:last-child {
              .nav-link {
                padding-left: 1.6rem;
              }
            }
          }
        }
        .tab-content {
          .row {
            justify-content: space-between;
          }
        }

        .color-swatch {
          margin-right: 0;
          width: 100%;
          height: 100%;
          border: 0.2rem solid transparent;
          padding: 1rem;
          .background-image {
            border-radius: 0.5rem;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-color: #eef1f0;
          }
          &.selected {
            position: relative;
            border: 0.2rem solid $primary-color;
          }
          color: transparent;
          overflow: hidden;
        }
      }
    }
  }
}
