@import "../activeFilter/activeFilter";
@import "./filterItem/filterItem";
.filter {
  .filter-container {
    width: 100%;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0 2.5rem 0 5rem;

    &.color-filter-container {
      display: flex;
      flex-wrap: wrap;
      .filter-list {
        margin-bottom: 2rem;
      }
    }
    &#compound-slider-filter {
      .filter-inner-content {
        div {
          color: $thirdy-color;
          &:first-of-type {
            div {
              &:first-of-type {
                text-align: left;
              }
              &:last-of-type {
                text-align: right;
              }
            }
          }
        }
      }
    }
    .filter-inner-content {
      text-align: center;
    }
    &.filter-enter-done {
      opacity: 1;
      transition: all 500ms ease-in-out;
      max-height: 200rem;
      overflow: hidden;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    &.filter-enter {
      transition: all 500ms ease-in-out;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }
    &.filter-enter-active {
      transition: all 500ms ease-in-out;
      opacity: 1;
      transition-delay: 300ms;
      max-height: 0;
      overflow: hidden;
      position: relative;
    }
    &.filter-exit-done {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 500ms ease-in-out;
    }
    &.filter-exit {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 500ms ease-in-out;
    }
    &.filter-exit-active {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 500ms ease-in-out;
      transition-delay: 150ms;
      position: relative;
    }
    &.filter-container-mobile {
      opacity: initial;
      &.filter-exit-active,
      &.filter-exit,
      &.filter-exit-done {
        opacity: initial;
        max-height: none;
      }
    }
  }
  .filter-toggle {
    &.show-true {
      background-color: $color-gray-filter;
    }

    border-radius: 0.5rem;
    margin: 0 0 2rem 0;
    width: 100%;
    cursor: pointer;
    h3 {
      color: $thirdy-color;
      padding: 0.25rem 0rem 0.25rem 5rem;
      font-size: 2rem;
      position: relative;
      width: 100%;
      .filter-icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
      }
    }
  }
}
