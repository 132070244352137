@keyframes pulsate {
  0% {
    border-color: transparent;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    border-color: #ff6d2d41;
    // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    border-color: transparent;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.hotspotContentContainer {
  .img-slider {
    height: 70rem;
    object-fit: cover;
  }
  .mask.hotSpot {
    z-index: 2;
    &.hotspot-active {
      z-index: 10;
    }
  }
  .hotspot-circle {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    background: $primary-color;
    opacity: 1;
    border-radius: 50%;
    position: relative;
    left: 0.8rem;
    top: 0.8rem;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      border: 1.85rem solid;
      border-color: transparent;
      border-radius: 50%;
      left: -0.8rem;
      top: -0.8rem;
      transition: border 0.2s ease-in-out;
      animation: pulsate 2.5s infinite;
      z-index: 0;
    }
    &:hover {
      &::after {
        border: 1.85rem solid #ff6d2d41;
      }
    }
  }
  .hotspot-active {
    .hotspot-circle {
      &::after {
        border: 1.85rem solid #ff6d2d41;
      }
    }
  }
  .img-hotspot {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.7rem;
    height: 3.7rem;
    cursor: pointer !important;
  }
  .img-hotspot2 {
    width: 3.7rem;
    height: 3.7rem;
    // top: 37rem;
    // left: 50rem;
    cursor: pointer;
  }
  .img-hotspotdetail {
    max-width: 17rem;
    max-height: 17rem;
    display: inline-block;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    cursor: pointer;
  }
  .detailContainer {
    box-shadow: 0px 1.2rem 3.4rem #00000018;
    border-radius: 1rem;
    opacity: 1;
    width: 15rem;
    height: 15rem;
    left: 2rem;
    top: 2rem;
  }
  .product-name {
    text-align: left;
    font: Medium 1.4rem Montserrat;
    font-size: 1.4rem !important;
    letter-spacing: 0;
    color: $primary-color;
    opacity: 1;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }
  .product-detail {
    text-align: left;
    font: Book 1.8re Gotham;
    font-size: 1.4rem !important;
    letter-spacing: 0;
    color: $primary-color !important;
    opacity: 1;

    line-height: 2rem;
  }
  .cardContainer {
    width: 25rem;
    max-width: 25rem;
    position: absolute;
    text-align: center;
    transform: rotate(180deg) translateX(43.2%);
    top: 8rem;
    background: $white 0% 0% no-repeat padding-box !important;
    box-shadow: 0rem 1.2rem 3.4rem #00000018 !important;
    border: 0.2rem solid $secondary-color !important;
    border-radius: 1rem !important;
    opacity: 1;
    z-index: 10;
    .card-body {
      .card-title {
        color: $primary-color;
      }
      p.card-text.product-detail {
        padding-top: 0;
        text-align: left;
        color: $color-orange-brick !important;
        .product-price {
          padding-left: 0.5rem;
        }
      }
    }
  }
  .dots {
    content: "";
    display: block;
    height: 4.5rem;
    position: absolute;
    top: calc(102%);
    width: 0.2rem;
    left: 49%;
    border-left: 0.4rem dotted $primary-color;
    // @media screen and (max-width: 1680px) {
    //   left: 98px;
    // }
    &::after {
      content: "";
      width: 10.5rem;
      height: 100%;
      position: absolute;
      -ms-transform: translate(-102%, -21%);
      transform: translate(-50%, -1%);
    }
  }
  .product-price {
    color: $thirdy-color;
  }
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 3.5rem;
    height: 12rem;
    transform: rotate(180deg);
    overflow: visible !important;
    background-attachment: fixed;
  }
}
@media screen and (max-width: 1160px) {
  .hotspotContentContainer {
    .card.cardContainer {
      width: 20rem;
      max-width: 20rem;
    }
    .dots {
      left: 9.3rem;
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .hotspotContentContainer {
    .img-slider {
      height: 30rem;
    }
    .card.cardContainer {
      width: 15rem;
      max-width: 15rem;
      img {
        border-radius: 1rem;
      }
      transform: rotate(180deg) translateX(36.85%);
    }
    .dots {
      left: 7.3rem;
    }
    .mask {
      height: 8rem;
    }
  }
}
