.shop-the-look-detail {
  margin-top: 5rem;
  margin-bottom: 10rem;
  .shop-the-look-detail-content {
    .shop-the-look-detail-top {
      @extend %block-width-1450;
      padding-bottom: 3rem;
      .breadcrumbs {
        padding-top: 0;
        padding-bottom: 2rem;
      }
      .shop-the-look-detail-title {
        h3 {
          padding-bottom: 0;
          font-size: $f34;
          line-height: 3.6rem;
        }
        div {
          a {
            font-size: $f21;
            padding: 0;
            @extend %gotham-medium;
            text-decoration: none;
            i {
              padding-right: 1.5rem;
            }
          }
        }
      }
    }
    .shop-the-look-detail-inner {
      @extend %block-width-1450;
      #title-holder {
        padding: 0 !important;
        max-width: 100% !important;
        .title-decription-block {
          display: flex;
          .description {
            background: $color-gray-block;
            padding: 2.8rem 4rem;
            margin-bottom: 1.5rem;
            @media screen and (max-width: $screen__s) {
              padding: 2.8rem 3rem;
            }
            p {
              color: $thirdy-color;
              text-transform: none;
              @media screen and (max-width: $screen__s) {
                font-size: $f16;
                line-height: 2.1rem;
              }
              font-size: 2rem;
              line-height: 3.2rem;
            }
          }
        }
      }
      .shop-detail-hotspot {
        .hotspotContentContainer {
          max-height: 90rem;
          .view {
            overflow: visible;
            width: fit-content;
            margin: 0 auto;
            img {
              max-height: 90rem;
              object-fit: contain;
              width: auto;
            }
          }
          .mask {
            height: 3.5rem;
          }
          .cardContainer {
            img {
              width: 100%;
            }
          }
        }
        p {
          font-size: $f12;
          color: $secondary-color;
          text-align: right;
          margin: 1.5rem 0 4.5rem 0;
          @media screen and (max-width: $screen__s) {
            text-align: center;
          }
        }
      }
    }
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__xs) {
  .shop-the-look-detail {
    .shop-the-look-detail-content {
      .shop-the-look-detail-top {
        .shop-the-look-detail-title {
          flex-direction: column-reverse !important;
          h3 {
            padding-top: 4.5rem;
          }
          > div {
            justify-content: left !important;
          }
        }
      }
    }
  }
}
