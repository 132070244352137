.about-studio-duc {
  background-color: $color-gray-block;
  padding-top: 12rem;
  padding-bottom: 10rem;
  .block-with-right-video {
    @extend %block-width-1450;
    justify-content: space-between;
    .block-part {
      width: calc(50% - 2rem);
      &.block-text {
        img {
          width: 32rem;
          margin-bottom: 7rem;
        }
        h3 {
          line-height: 4.2rem;
          padding-bottom: 0;
        }
        p {
          padding: 4rem 0 3rem 0;
          font-size: $f21;
          line-height: 3.6rem;
          color: $secondary-color;
          text-transform: none;
        }
        button {
          max-width: 30rem;
        }
      }
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .about-studio-duc {
    .block-with-right-video {
      .block-part {
        &.block-text {
          margin-bottom: 3rem;
          width: 100%;
        }
        &.block-video {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .about-studio-duc {
    padding-top: 4rem;
    padding-bottom: 4rem;
    .block-with-right-video {
      .block-text.block-part {
        h3 {
          font-size: $f34;
          line-height: 3.6rem;
        }
        p {
          font-size: $f16;
          line-height: 2.4rem;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .about-studio-duc {
    .block-with-right-video {
      .block-part {
        &.block-text {
          img {
            width: 24rem;
            margin-bottom: 3rem;
          }
          p {
            padding-top: 3rem;
          }
        }
      }
    }
  }
}
