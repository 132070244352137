.register-success {
  &.order-success {
    .register-succes-buttons {
      .create-account {
        background-color: #ff6d2d !important;
        color: #fff !important;
        border: 1px solid #ff6d2d !important;
      }
    }
  }
}
