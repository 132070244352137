@import "./searchInput/searchInput";
@import "./searchResults/searchResults";
#search-container {
  position: fixed;
  width: 96%;
  box-shadow: 3px 6px 6px #00000040;
  margin: 0 auto;
  background-color: $white;
  min-height: 12rem;
  left: 2%;
  top: 7rem;
  transition: all 0.3s ease-in-out;
  border-radius: 0.5rem;
  padding: 0;

  @media screen and(max-width: 993px) {
    box-shadow: none;
  }

  &.hide {
    transform: translateY(-100%);
    top: -1rem;
  }
  &.show {
    transform: translateY(0);
  }

  @include media-breakpoint-down(md) {
    position: relative;
    min-height: 0;
    left: 0;
    top: 0;
    &.hide {
      transform: none;
      top: 0;
    }
  }
}
