.modal {
  &.show {
    .modal-dialog {
      &.modal-wrapper {
        &.assembly-modal {
          .modal-content {
            .modal-body {
              max-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.assembly-video-link {
  cursor: pointer;
}
