#copy-modal {
  &.modal.show {
    .modal-dialog {
      min-width: 90rem;
      .modal-body {
        width:60rem;
        max-width: 100%;
        h2 {text-align: left;}
        .form-check  {
          label {
            color: $secondary-color;
          }
        }
        .form-control{ 
          width: 37rem;
        }
        .col-md-12 {
          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            .enter-name {
              padding-left: 0;
            }
            .add-button {
              padding-right: 0;
              button {
                font-size: $f20;
              }
            }
            .col {
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }
  } 
}
/*Media Queries*/

@media (max-width: $screen__s) {
  #copy-modal {
    &.modal.show {
      .modal-dialog {
        .modal-body {
          padding: 6rem ;
          h2 {
            font-size: $f34;
            line-height: 3.6rem;
            text-align: center;
          }
          .form-control{ 
            width: 100%;
          }
          .col-md-12 {
            .row {
              flex-direction: column;
              .enter-name, .add-button {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}