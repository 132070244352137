.register-success {
  h3 {
    padding-bottom: 5rem;
  }
  h4 {
    text-transform: none;
    padding-bottom: 3rem;
    border-bottom: 0.2rem solid $color-gray-bottom-border;
  }
  .register-succes-buttons {
    margin: 4rem 0 10rem 0;
    flex-direction: row;
    display: flex;
    // justify-content: space-between;
    a {
      max-width: 35rem;

      button {
        max-width: 33rem;
        &.shop-the-look-btn {
          border: 0.15rem solid $primary-color !important;
        }
      }
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .register-success {
    h3 {
      font-size: $f34;
      line-height: 3.6rem;
    }
    h4 {
      font-size: $f16;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .register-success {
    .register-succes-buttons {
      margin: 4rem 0 10rem 0;
      flex-direction: column;
      a {
        &:first-child {
          margin-right: 0;
          margin-bottom: 3rem;
        }
      }
    }
  }
}
