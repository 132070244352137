.dropdown {
  .toggle-menu-item {
    .menu-item {
      border-bottom: 0.1rem solid $color-gray-light;
      padding: 1.1rem 0;
      text-align: center;
      position: relative;
      @extend %gotham-medium;

      .toggle-menu-icon {
        right: 10%;
        position: absolute;
        font-size: $f20;
        color: $color-gray-light;
      }
    }
    .Kids {
      color: #ff9015;
    }
    .Baby {
      color: #ff9015;
    }
    .Storage {
      color: #ff9015;
    }
    .Study {
      color: #ff9015;
    }
    .Play {
      color: #ff9015;
    }
    .toggle-dropdown {
      background-color: #eef1f0;

      li {
        text-align: center;
        padding-bottom: 2rem;
        a {
          text-transform: lowercase !important;
          font-size: $f12;
          padding-top: 0;
          padding-bottom: 0;
        }
        &:last-of-type {
          padding-bottom: 1rem;
        }
      }
    }
  }
  &:first-of-type {
    .toggle-menu-item {
      .menu-item {
        border-top: 0.1rem solid $color-gray-light;
      }
    }
  }
}
