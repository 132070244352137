.crossell-container {
  margin-top: 10rem;
  margin-bottom: 7rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  text-align: center;
  h2 {
    color: $color-black;
    @extend %gotham-medium;
    font-size: $f48;
    line-height: 6.2rem;
    padding-bottom: 0;
    margin-bottom: 4rem;
  }
  .slick-slide {
    padding: 0 1.5rem;
  }
  .product-item-content {
    .product-item-image {
      padding: 0 3rem;
    }
  }
}
@media only screen and (max-width: $screen__xs) {
  .crossell-container {
    h2 {
      font-size: $f34;
      line-height: 3.8rem;
      padding: 0 5rem;
    }
  }
}
