.footer-top-content {
  border-top: 0.3rem solid $color-gray-bottom-border;
  border-bottom: 0.3rem solid $color-gray-bottom-border;
  .row {
    @extend %block-width-950;
    .footer-top-part {
      display: inline-flex;
      padding: 1.5rem 0 !important;
      justify-content: center;
      align-items: center;
      &:nth-child(2) {
        border-right: 0.3rem solid $color-gray-bottom-border;
        border-left: 0.3rem solid $color-gray-bottom-border;
      }
      a {
        color: $color-gray-dark;
        font-size: $f21;
        text-decoration: none;
        text-align: center;
        @extend %gotham-medium;
        svg {
          margin-right: 1rem;
        }
        i {
          font-size: 2.7rem;
          margin-right: 1rem;
          color: $secondary-color;
        }
      }
      &:hover {
        svg {
          path {
            &.about {
              fill: $primary-color;
            }
            &.aboutb {
              stroke: $primary-color;
            }
          }
          .ash {
            fill: $primary-color;
          }
          .far {
            stroke: $primary-color;
          }
        }
        a {
          color: $primary-color;
          i {
            &::before {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .footer-top-content {
    display: none;
  }
}
