.sidebar-item {
  color: $thirdy-color;
  a {
    color: $thirdy-color;
    text-decoration: none;
    padding: 1rem 0rem 1rem 6rem;
  }
  .sidebar-item-toggle {
    background-color: $white;
    padding: 0.5rem 0rem 0.5rem 5rem;
    font-size: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    a {
      padding: 0;
      font-size: $f20;
      .filter-icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
      }
    }
    &.open {
      background-color: $color-gray-filter;
      border-radius: 0.5rem;
    }
  }
  .sidebar-item-children-container {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.closed {
      max-height: 0;
    }
    &.open {
      margin-bottom: 1rem;
      max-height: 50rem;
    }
    .sidebar-item-link {
      font-size: 1.4rem;
    }
  }
}
