.tabs-container {
  padding: 0;
  max-width: 100% !important;
  .nav-tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border: none;
    margin-bottom: 5rem;

    li.nav-item {
      width: calc(1 / 4 * 100% - (1 - 1 / 4) * 3%);
      border: none;
      margin: 0;
      color: $thirdy-color;
      &::before {
        content: none;
      }
      .nav-link {
        margin: 0;
        font-size: $f20;
        height: 6rem;
        color: $primary-color;
        text-transform: none;
        max-height: 6rem;
        text-decoration: none;
        padding: 0 2rem;
        @extend %gotham-medium;
        line-height: 6rem;
        border-radius: 0.5rem;
        border: 0.1rem solid $thirdy-color;
        color: $thirdy-color;
        &.selected {
          color: $primary-color;
          border-width: 0.2rem 0.2rem 0.5rem 0.2rem !important;
          display: block;
        }
      }
    }
  }
  .tab-inner-content {
    .tab-inner-elements {
      .tab-content {
        .tab-pane {
          &.active {
            .title-tab {
              color: $secondary-color;
              margin-bottom: 5rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $screen__xs) {
  .tabs-container.container {
    ul.nav-tabs {
      li.nav-item {
        width: 100%;
        padding-bottom: 2rem;
      }
    }
  }
}
