.share-btn-wrapper {
  width: 100%;
  display: inline-block;
  margin-bottom: 8rem;
  .share-btn {
    max-width: 32rem;
    width: 100%;
    float: right;
  }
}
