.assembly-inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  .assembly-inner-wrapper {
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2%);
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    .accordion-section {
      .accordion-title {
        h3 {
          @extend %gotham-book;
        }
      }
      .accordion-content {
        margin: 0;
        .accordion-text {
          @extend %gotham-medium;
          color: $secondary-color;
          text-align: left;
          max-height: 50rem;
          background: rgba($color-gray-block, 0.5);
          overflow-y: auto;
          margin: 2rem 0;
          padding: 0 3rem 3rem 3rem;
          ul {
            padding: 0;
            li {
              font-size: $f20;
              padding: 3rem 0;
              border-bottom: 0.15rem solid $color-gray-filter;
            }
          }
        }
      }
    }
  }
}
// MEDIA
@media screen and (max-width: $screen__m) {
  .assembly-page {
    .breadcrumbs {
      margin-bottom: 3rem;
    }
  }
  .assembly-inner-container {
    flex-direction: column;
    margin: 0 -3rem;
    .assembly-inner-wrapper {
      width: 100%;
      display: inline-flex;
      .assembly-tab-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid $color-gray-bottom-border;
        padding: 2rem 0;
        .content-inner {
          color: $secondary-color;
          position: relative;
          &.product-name {
            flex: 0 0 100%;
            font-size: $f20;
            margin-bottom: 1.5rem;
          }
          &.product-video,
          &.product-instructions {
            flex: 0 0 47%;
            > div {
              a {
                color: $secondary-color;
                text-decoration: none;
                padding: 0;
              }
            }
          }
          svg {
            position: absolute;
            top: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
          }
          .item {
            padding-left: 2.5rem;
          }
        }
      }
    }
  }
  .accordion-section {
    .accordion-content {
      .accordion-text {
        h5 {
          font-size: $f16 !important;
          line-height: 3rem;
        }
        ul {
          li {
            font-size: $f16;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .accordion-section {
    .accordion-title {
      h3 {
        padding: 1rem;
      }
    }
    .accordion-content {
      .accordion-text {
        ul {
          li {
            padding: 1.5rem 0;
            font-size: $f14;
          }
        }
      }
    }
  }
}
