.cms-content {
  padding-bottom: 10rem;
  h3 {
    padding-bottom: 4rem;
    font-size: 3.4rem;
    line-height: 3.6rem;
  }
  p {
    color: $secondary-color;
    margin-bottom: 2rem;
  }
  h4 {
    color: $secondary-color;
    @extend %gotham-medium;
    margin: 2rem 0;
    font-size: $f20;
  }
  ul {
    li {
      color: $secondary-color;
      font-size: $f14;
      line-height: 2rem;
    }
  }
}
