.checkbox-option-container {
  .check-button {
    padding-left: 3rem;
    margin-bottom: 0;
    .form-check {
      label {
        &.form-check-label {
        }
      }
    }
  }
}
