#trade-register-B2C.product-register {
  #title-holder {
    .title-decription-block {
      h2 {
        text-transform: uppercase;
      }
      .description {
        p {
          text-transform: lowercase;
          width: 100%;
          font-size: $f12;
          @extend %gotham-bold;
          line-height: 2rem;
          span {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .grey-text {
    .span-big {
      font-size: $f20;
      display: block;
      margin-bottom: 1rem;
    }
    .default-input-field-container {
      input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
    &.mailing-info {
      span {
        margin-bottom: 1.5rem;
      }
      .state-selection-container {
        margin-bottom: 2.5rem;
        .select2 {
          .validation-error {
            .react-select__control {
              border: 0.1rem solid red;
            }
          }
        }
      }
    }
    &.product-details {
      margin-bottom: 6.5rem;
    }
  }
  .switch-and-required {
    flex-direction: row-reverse;
    div.text-right.required-text-block {
      margin-bottom: 1rem;
      width: auto;
    }
  }
  .submit-btn-container {
    width: 100%;
    margin-top: 1rem;
    text-align: right;
    padding-left: 11.5rem;
    padding-right: 11.5rem;
    .account-submit-btn {
      max-width: 33rem;
    }
  }
  .modal.show {
    .modal-dialog {
      min-width: 90rem;
    }
  }
  .registration-submitted-modal {
    .modal-content {
      .modal-body {
        max-width: 90%;
        padding: 6rem 1rem 8rem 1rem;
        p {
          margin-bottom: 5rem;
          color: $secondary-color;
        }
        button {
          max-width: 33rem;
        }
      }
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  #trade-register-B2C.product-register {
    .grey-text {
      &.contact-info {
        margin-bottom: 6.5rem;
      }
    }
    .submit-btn-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #trade-register-B2C.product-register {
    .submit-btn-container {
      text-align: left;
      .account-submit-btn {
        max-width: 100%;
      }
    }
    .registration-submitted-modal {
      .modal-content {
        .modal-body {
          h2 {
            font-size: 3.4rem;
            line-height: 3.6rem;
          }
        }
      }
    }
  }
}
