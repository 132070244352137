.juicerWrapper {
  margin-top: 2rem;
  background-color: $white;
  .image-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $white;
    .image-icon {
      display: none;
    }
    div > div:hover > div {
      cursor: pointer;
      box-shadow: 0 1.2rem 1.6rem -0.7rem rgba($color-black-shadow, 0.6);
      // z-index: 999;
      div {
        box-shadow: none;
      }
    }
    div {
      div {
        div {
          margin: 0 1.5rem 1.5rem 1.5rem;
          box-shadow: 0 0.5rem 1.6rem -0.7rem rgba($color-black-shadow, 0.6);
          div:last-child {
            border: none;
            height: 11.5%;
            width: 100%;
            margin: 0;
            bottom: 0;
            top: unset;
            background-color: rgba(#ff6d2d, 0.85);
            padding: 2rem 0;
            background-repeat: no-repeat;
            background-position: center;
            justify-content: center;
            overflow: hidden;
            z-index: 9;
            &:after {
              content: "View details";
              max-width: 100%;
              text-transform: none;
              background-repeat: no-repeat;
              font-size: $f16;
            }
            &:before {
              content: "";
              background-image: url("../../../assets/icon/instagramWhite.svg");
              display: inline;
              background-position: center;
              width: 4rem;
              height: 3.5rem;
              background-repeat: no-repeat;
            }
            div:last-child {
              display: none;
            }
            div:first-child {
              display: none;
            }
          }
        }
      }
    }
  }

  .juicer-header {
    color: $thirdy-color;
    .responsive-font-size {
      padding-top: 5rem;
      padding-bottom: 6.4rem;
    }
    h2 {
      color: $thirdy-color;
      text-transform: none;
      padding-bottom: 3rem;
    }
    .title-bottom {
      font-size: $f21;
      text-transform: none;
      color: $secondary-color;
      span {
        color: $thirdy-color;
        @extend %gotham-bold;
      }
    }
  }
  .juicer-navigation {
    padding: 6rem 0;
    display: flex;
    @extend %block-width-1450;
    justify-content: center;
    svg {
      cursor: poiner;
      margin: 0 1.5rem;
    }
  }
}

.ReactModal__Overlay {
  background: rgba(255, 255, 255, 0.9) !important;
  z-index: 9;
}

.ReactModal__Content {
  height: 100%;
  > div {
    width: 93rem;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0.4rem dotted;
    padding: 2.5rem;
    background-color: $white;
    > div {
      max-height: 54rem;
      height: 54rem;

      > button {
        background-color: transparent;
        filter: contrast(0.6);
        &:hover {
          opacity: 1 !important;
          filter: contrast(0.3);
        }
        outline: none !important;

        i {
          width: 5rem;
          height: 5rem;
        }
        color: red;
      }
    }
  }
  div {
    button:nth-child(3) {
      height: 2rem;
      width: 2rem;
      i {
        position: absolute;
        right: -4rem;
        top: -4rem;
        height: 3rem;
        width: 3rem;
        background-image: url("../../../assets/icon/closeBtn.svg");
      }
    }
    div:nth-child(1) {
      padding: 0;
      background-size: cover;
      background-position: none;
    }
    div:nth-child(2) {
      margin: 0;
      flex-direction: column;
      flex: 0 1 40%;
      padding: 0 0 0 2.5rem;
      div:nth-child(1) {
        margin-bottom: 1rem;
        div {
          padding: 0;
          > div:nth-child(1) {
            width: 7.5rem;
            height: 7.5rem;
          }
        }
        > div {
          font-size: $f30;
          color: $secondary-color;
        }
      }
      div:nth-child(2) {
        margin: 0;
        padding: 0;
        div {
          p {
            display: flex;
            flex-direction: column-reverse;
            text-align: left;
            font-weight: 300;
            font-size: 1.6rem;
            color: $secondary-color;
            text-transform: none;
            font-size: $f14;
            line-height: 2rem;
            a {
              padding-bottom: 1rem;
              display: flex;
              font-size: $f16;
              flex-direction: row;
            }
          }
        }
      }
      div:nth-child(3) {
        padding: 0;
        align-items: center;
        margin-top: 0;
        a {
          position: relative;
          background: none;
          @extend %gotham-medium;
          color: $primary-color;
          font-size: $f21;
          &:before {
            content: "";
            background-image: url("../../../assets/icon/instagramOrange.svg");
            display: inline-block;
            background-position: center;
            width: 4rem;
            height: 3.5rem;
            background-repeat: no-repeat;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
  .ReactModal__Content {
    > div {
      > div {
        > button {
          width: auto;
          opacity: 1 !important;
          &:hover {
            filter: contrast(0.3);
          }
          &:first-child {
            left: -20%;
          }
          &:last-child {
            right: -85%;
          }
        }
      }
    }
  }

@media screen and (max-width: $screen__l) {
  .ReactModal__Content {
    margin-top: 4rem !important;
    > div {
      > div {
        max-height: 45rem;
        height: 45rem;
      }
    }
  }
}
@media screen and (max-width: $screen__ml) {
  .ReactModal__Content {
    height: 100%;
    > div {
      > div {
        background-color: transparent;
      }
    }
    div {
      button:nth-child(3) {
        height: 2rem;
        width: 2rem;
        i {
          position: absolute;
          right: -4rem;
          top: -4rem;
          height: 3rem;
          width: 3rem;
          background-image: url("../../../assets/icon/closeBtn.svg");
        }
      }
      div:nth-child(1) {
        padding: 0;
        background-size: contain;
        background-position: none;
      }
      div:nth-child(2) {
        margin: 0;
        flex-direction: column;
        flex: 0 1 40%;
        padding: 0 0 0 2.5rem;
        div:nth-child(1) {
          margin-bottom: 1rem;
          div {
            padding: 0;
          }
        }
        div:nth-child(2) {
          margin: 0;
          padding: 0;
          div {
            p {
              text-align: left;
              font-weight: 300;
              font-size: 1.6rem;
            }
          }
        }
        div:nth-child(3) {
          text-align: left;
          a {
            background: none;
            color: $primary-color;
            font-size: $f21;
            padding: 0;
            text-align: left;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1169px) {
  .juicerWrapper {
    .image-wrapper {
      > div {
        > div {
          &:nth-child(4) {
            display: none;
          }
          &:nth-child(5) {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .ReactModal__Content {
    margin-top: 10rem !important;
    > div {
      width: 80%;
    }
  }
}
@media screen and (max-width: $screen__s) {
  .juicerWrapper {
    .juicer-header {
      .title-top {
        font-size: $f34;
      }
    }
    .image-wrapper {
      > div {
        > div {
          > div {
            margin: 1rem;
          }
        }
      }
    }
    .juicer-navigation {
      padding: 3rem 0;
      svg {
        margin: 0 1.5rem;
      }
    }
  }
  .ReactModal__Content {
    margin-top: 3rem !important;
    > div {
      height: 65rem;
      > div {
        max-height: 35rem;
        height: 35rem;
      }
    }
    div {
      button:nth-child(3) {
        right: -3rem;
        top: -3rem;
        height: 3rem;

        width: 3rem;
        i {
          position: absolute;
          right: 0rem;
          top: 0rem;
          height: 3rem;
          width: 3rem;
          background-image: url("../../../assets/icon/closeBtn.svg");
        }
      }
      div {
        margin-bottom: 2rem;
        &:nth-child(2) {
          flex: 0 1 30%;
          padding: 0 !important;
          > div {
            &:nth-child(3) {
              a {
                padding: 0;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .ReactModal__Content {
    > div {
      > div {
        > button {
          &:last-child {
            right: -19%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .juicerWrapper {
    .image-wrapper {
      > div {
        > div {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .juicerWrapper {
    .image-wrapper {
      > div {
        > div {
          > div {
            height: 40rem;
          }
        }
      }
    }
    .juicer-navigation {
      display: none;
    }
  }
  .ReactModal__Content {
    margin-top: 5rem !important;
    > div {
      height: 42rem;
      > div {
        max-height: 17rem;
        height: 17rem;
        > button {
          &:first-child {
            top: 50%;
            left: -25%;
          }
          &:last-child {
            top: 50%;
            right: -25%;
          }
        }
      }
    }
    div {
      button:nth-child(3) {
        i {
          right: 1rem;
          top: -1rem;
        }
      }
      div {
        &:nth-child(2) {
          flex: 0 1 80%;
          > div {
            &:nth-child(3) {
              a {
                margin-top: 3rem;
              }
            }
          }
        }
      }
      div:nth-child(2) {
        padding: 0 0 0 2.5rem;
        div:nth-child(1) {
          margin-bottom: 0.5rem;
          div {
            > div:nth-child(1) {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
          > div {
            font-size: $f14;
          }
        }
      }
    }
  }
}
