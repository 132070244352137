.about-us-instagram {
  h2 {
    color: $primary-color;
    font-size: $f64;
    line-height: 7.2rem;
  }
  p {
    color: #5c5c5c;
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .about-us-instagram {
    padding-left: 2rem;
    padding-right: 2rem;
    .juicerWrapper {
      margin-top: 0;
      .responsive-font-size {
        padding: 5.5rem 0 3rem 0;
      }
      .juicer-navigation {
        padding: 4rem 0;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .about-us-instagram {
    .juicerWrapper {
      .responsive-font-size {
        .title-top {
          padding-bottom: 0;
        }
        .title-bottom {
          max-width: 40%;
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .about-us-instagram {
    .juicerWrapper {
      .responsive-font-size {
        .title-bottom {
          max-width: 75%;
        }
      }
    }
  }
}
