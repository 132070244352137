.compare-list-container {
  z-index: 9;
  .compare {
    .item {
      display: flex;
      flex-direction: column;
    }
    .attribute-box {
      margin-top: auto;
    }
    .remove-compare {
      color: $primary-color;
    }
    .options-btn {
      button {
        border-radius: 5px;
        background-color: $color-orange-footer;
        color: #fff;
        border: none;
        &:focus {
          color: #fff !important;
        }
      }
    }
  }

  .container-btn {
    background-color: $primary-color;
    h5 {
      color: #fff;
    }
    .compare {
      .circle-btn {
        transition: top 0.2s ease-in-out;
        background-color: $primary-color;
        left: 50.9%;
        top: 0;
        height: 4rem;
        width: 5rem;
        i.fa {
          color: #fff;
          z-index: 9;
        }
        &::after {
          background-color: $primary-color;
          content: "";
          height: 2rem;
          width: 100vw;
          position: absolute;
          right: 50%;
          left: 20%;
          -ms-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
          bottom: -2rem;
          border-bottom: 1px solid #fff;
          z-index: -1;
        }
      }
    }
  }
}
.is-bottom {
  .compare-list-container {
    &.open {
      .container-btn {
        .compare {
          .circle-btn {
            top: -1rem;
            &::after {
              bottom: -2rem;
              border: none;
            }
          }
        }
      }
      :not(.new-transition-exit-done) {
        .container-btn {
          .compare {
            .circle-btn {
              top: 0rem;
              &::after {
                bottom: -2rem;
              }
            }
          }
        }
      }
    }
    :not(.new-transition-exit-done) {
      .container-btn {
        .compare {
          .circle-btn {
            top: -5rem;
          }
        }
      }
    }
    &.new-transition-exit-done {
      .container-btn {
        .compare {
          .circle-btn {
            top: -5rem;
          }
        }
      }
    }
    &.new-transition-exit-active {
      .container-btn {
        .compare {
          .circle-btn {
            top: -1rem;
            &::after {
              bottom: -2rem;
            }
          }
        }
      }
    }
  }
}

.not-bottom {
  .compare-list-container {
    .container-btn {
      .compare {
        .circle-btn {
          top: 2rem;
          &::after {
            bottom: -2rem;
            border: none;
          }
        }
      }
    }
    &.open {
      .container-btn {
        .compare {
          .circle-btn {
            top: 0;
            &::after {
              bottom: -2rem;
            }
          }
        }
      }
      :not(.new-transition-exit-done) {
        .container-btn {
          .compare {
            .circle-btn {
              top: 0;
              &::after {
                bottom: -2rem;
              }
            }
          }
        }
      }
    }
    :not(.new-transition-exit-done) {
      .container-btn {
        .compare {
          .circle-btn {
            top: 0;
          }
        }
      }
    }
    &.new-transition-exit-done {
      .container-btn {
        .compare {
          .circle-btn {
            top: 0;
          }
        }
      }
    }
    &.new-transition-exit-active {
      .container-btn {
        .compare {
          .circle-btn {
            top: 0;
            &::after {
              bottom: -4rem;
            }
          }
        }
      }
    }
  }
}
