.forms-container {
  .forms-content {
    max-width: 142rem;
    margin: 0 auto;
    flex-direction: column;
    p {
      font-size: $f20;
      line-height: 3.6rem;
    }
    .forms-title {
      max-width: 50%;
    }
    .forms-inner-content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      margin: 6rem 0 10rem 0;
      .forms-items {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2%);
        background: $color-gray-block;
        margin: 1.5rem 0;
        display: inline-flex;
        align-items: center;
        padding: 3rem 3.5rem;
        h3 {
          flex: 0 0 80%;
          padding: 0;
          @extend %gotham-book;
        }
        span,
        a {
          text-decoration: none;
          flex: 0 0 auto;
          color: $secondary-color;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .forms-container {
    .forms-content {
      max-width: 100%;
      flex-direction: column;
      width: 100%;
      h3.main-title {
        margin-top: 3.5rem;
        font-size: 3.4rem;
        line-height: 3.6rem;
      }
      p {
        font-size: $f20;
      }
      .forms-title {
        max-width: 100%;
      }
      .forms-inner-content {
        margin: 5rem -1.5rem 4rem -1.5rem;
        .forms-items {
          width: 100%;
          h3 {
            flex: 0 0 70%;
            padding: 0;
          }
        }
      }
    }
  }
}
