@import "./assembly/assembly";
@import "./related/related";
@import "./reviews/reviews";
@import "./upsells/upsells";
@import "./info/info";
@import "./details/details";

.product-page-container {
  .container {
    @extend %page-content-width;
  }
  .slider-navigation {
    svg {
      cursor: pointer;
      margin: 0 1.5rem;
    }
  }

  .product-information {
    padding-bottom: 6rem;
  }

  .slick-list {
    width: 100%;
    .slick-track {
      .product-item-content {
        margin-top: 0;
        .product-wishlist {
          display: none;
        }
        .product-item-image {
          .product-image {
            min-height: 14rem;
          }
        }
      }
    }
  }
  .slider-navigation {
    span {
      cursor: pointer;
      &.disabled {
        filter: grayscale(100%);
        cursor: auto;
      }
    }
  }
  .hotspotContentContainer .view {
    width: fit-content;
    margin: 0 auto;
  }
  .hotspotContentContainer .mask {
    height: 3.5rem;
  }
  .details-container {
    .hotspotContentContainer .img-slider {
      object-fit: contain;
      height: auto;
      width: auto;
    }
  }
  .slider-custom-dot {
    background: #fff;
    border: 2px solid #ff6d2d;
  }
  .slick-active .slider-custom-dot {
    background: #ff6d2d;
    border: 2px solid #ff6d2d;
  }
  .details-image-container {
    .slick-initialized {
      text-align: center;
    }
  }

  @media only screen and (max-width: 992px) {
    .m-md-0 {
      margin: 0 !important;
    }

    .mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
