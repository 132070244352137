#pdp-add-product-to-wishlist {
  .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    .modal-body {
      @media screen and (max-width: 375px), screen and (max-height: 640px) {
        padding: 1rem;
        h3 {
          padding-bottom: 1rem;
        }
      }
      button {
        i {
          right: 1rem;
        }
      }
      .col-md-12 {
        .row {
          display: flex;
          flex-direction: column;
        }
      }
      .session-messages-inner {
        .success {
          span {
            color: $color-black;
          }
        }
        .danger {
          span {
            color: $white;
          }
        }
      }
      .wishlist-items-container {
        max-height: 35rem;
        overflow: auto;
        margin-bottom: 2rem;
        @media screen and (max-width: 375px), screen and (max-height: 640px) {
          max-height: 30rem;
        }
        @media screen and (max-width: 320px), screen and (max-height: 568px) {
          max-height: 25rem;
        }
        .check-button.form-check {
          @media screen and (max-width: 320px), screen and (max-height: 568px) {
            margin: 1.5rem !important;
          }
        }
      }
      button.btn.orange-button {
        @media screen and (max-width: 320px), screen and (max-height: 568px) {
          padding: 1.2rem;
          font-size: 1.4rem;
        }
      }
    }
  }
}
